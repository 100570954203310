import React from "react";
import { Route, Routes } from "react-router-dom";

// CMS Pages
import Home from "./Pages/Home";
import About from "./Pages/CmsPages/About";
import QandA from "./Pages/CmsPages/FAQ";
import Pricing from "./Pages/CmsPages/Pricing";
import Testimonial from "./Pages/CmsPages/Testimonials";
import Contact from "./Pages/CmsPages/ContactUs";
import Blog from "./Pages/CmsPages/Blog";
import BlogDetails from "./Pages/BlogDetails";
import Privacypolicy from "./Pages/CmsPages/PrivacyPolicy";
import TermsOfUse from "./Pages/CmsPages/TermsOfUse";
import Sponsor from "./Pages/CmsPages/Sponsor";
import Partner from "./Pages/CmsPages/Partner";

// Authentication
import SignUp from "./Pages/Authentication/SignUp";
import { FamilySignUp } from "./Pages/Authentication/FamilySignUp";
import TeacherSignUp from "./Pages/Authentication/TeacherSignUp";
import ForgotPassword from "./Pages/Authentication/ForgotPassword";
import ResetPassword from "./Pages/Authentication/ResetPassword";
import ResetMyAccount from "./Pages/Authentication/ResetMyAccount";
import Renew from "./Pages/Authentication/Renew";
import RenewSuccess from "./Pages/Authentication/RenewSuccess";
import RenewFailure from "./Pages/Authentication/RenewFailure";
import AccountExpire from "./Pages/Authentication/AccountExpire";
import { AuthProvider } from "./Pages/Authentication/auth";
import RequiredAuth from "./Pages/Authentication/RequiredAuth";

import MySetting from "./Pages/Children/MySetting";
import MyProgress from "./Pages/Children/MyProgress";

import OverviewAssignment from "./Pages/Assignment/OverviewAssignment";
import IndividualAssignment from "./Pages/Assignment/IndividualAssignment";
import MyAccount from "./Pages/Parent/MyAccount";
import MyChildren from "./Pages/Parent/MyChildren";

// Progress & Report
import Progress from "./Pages/Progress/Progress";
import RecentActivityProgress from "./Pages/Progress/RecentActivityProgress";
import IndividualProgress from "./Pages/Progress/IndividualProgress";
import AssignmentProgress from "./Pages/Progress/AssignmentProgress";
import Award from "./Pages/Progress/Award";
import LessonReport from "./Pages/Progress/LessonReport";
import ProgressReport from "./Pages/Progress/ProgressReport";
import PrintableProgressReport from "./Pages/Progress/PrintableProgressReport";
import Assignments from "./Pages/Progress/Assignments";
import Certificate from "./Pages/Progress/Certificate";

import Newsletter from "./Pages/Newsletter";
import NewsDetails from "./Pages/NewsDetails";
import MonthlyContest from "./Pages/MonthlyContest";
import ContestWinners from "./Pages/ContestWinners";

import Grade from "./Pages/Grade";
import GradeLesson from "./Pages/GradeLesson";
import GradeLessonSearch from "./Pages/GradeLessonSearch"
import MyStudent from "./Pages/Teacher/MyStudent";
import Questions from "./Pages/Questions";
import AssignmentQuestions from "./Pages/AssignmentQuestions";

import Awards from "./Pages/Awards";
import AssignmentDetail from "./Pages/Assignment/AssignmentDetail"
import NotFound from "./Pages/NotFound"
import EmailVerification from "./Pages/Authentication/EmailVerification";
import AssignmentProgressReport from "./Pages/Progress/AssignmentProgressReport";
import  Unsubscribe  from "./Pages/Unsubscribe";

// Worksheet
import Worksheet from "./Pages/Worksheet/Worksheet";
import ListWorksheet from "./Pages/Worksheet/ListWorksheet";
import ViewWorksheet from "./Pages/Worksheet/ViewWorksheet";
import UploadWorksheet from "./Pages/Worksheet/UploadWorksheet";

const AppRoutes = () => {
  return (
    <AuthProvider>
      <Routes>
        {/* CMS pages */}
        <Route exact path="/" element={<Home />} />
        <Route exact path="/about" element={<About />}></Route>
        <Route exact path="/FAQ" element={<QandA />}></Route>
        <Route exact path="/pricing" element={<Pricing />}></Route>
        <Route exact path="/blog" element={<Blog />}></Route>
        {/* <Route exact path="/sponsor" element={<Sponsor />}></Route>
        <Route exact path="/partner" element={<Partner />}></Route> */}
        <Route exact path="/awards" element={<Awards />}></Route>
        <Route exact path="/blogdetail/:postSlug" element={<BlogDetails />} />
        <Route exact path="/terms-of-use" element={<TermsOfUse />} />
        <Route exact path="/privacy-policy" element={<Privacypolicy />} />
        <Route exact path="/contactUs" element={<Contact />}></Route>
        <Route exact path="/testimonial" element={<Testimonial />}></Route>

        {/*Authentication Routes */}
        <Route exact path="/signup" element={<SignUp />}></Route>
        <Route exact path="/email-verification/:token" element={<EmailVerification />}></Route>
        <Route exact path="/family-signup" element={<FamilySignUp />}></Route>
        <Route exact path="/teacher-signup" element={<TeacherSignUp />}></Route>
        <Route exact path="/forgot-password" element={<ForgotPassword />}></Route>
        <Route exact path="/reset-password" element={<ResetPassword authorized={true} />}></Route>
        {/* <Route exact path="/reset-myaccount" element={<ResetMyAccount />}></Route> */}
        <Route exact path="/renew" element={<Renew />}></Route>
        <Route exact path="/payment-success" element={<RequiredAuth><RenewSuccess /></RequiredAuth>}></Route>
        <Route exact path="/payment-cancel/" element={<RequiredAuth><RenewFailure /></RequiredAuth>}></Route>
        <Route exact path="/account-expire" element={<AccountExpire />}></Route>

        {/* Child User Routes */}
        <Route exact path="/my-setting" element={<RequiredAuth><MySetting /></RequiredAuth>}></Route>
        <Route exact path="/my-progress" element={<RequiredAuth><MyProgress /></RequiredAuth>}></Route>

        {/* Teacher User Routes */}
        <Route exact path="/my-student" element={<RequiredAuth><MyStudent /></RequiredAuth>}></Route>

        {/* Parent User Routes */}
        <Route exact path="/overview-assignment" element={<RequiredAuth><OverviewAssignment /></RequiredAuth>}></Route>
        <Route exact path="/individual-assignment"element={<RequiredAuth><IndividualAssignment /></RequiredAuth>}></Route>
        <Route exact path="/my-account" element={<RequiredAuth><MyAccount /></RequiredAuth>}></Route>
        <Route exact path="/my-children" element={<RequiredAuth><MyChildren /></RequiredAuth>}></Route>

        {/* Progress Route */}
        <Route exact path="/progress" element={<RequiredAuth><Progress /></RequiredAuth>}></Route>
        <Route exact path="/progress/recent-activity-progress" element={<RequiredAuth><RecentActivityProgress /></RequiredAuth>}></Route>
        <Route exact path="/progress/individual-progress" element={<RequiredAuth><IndividualProgress /></RequiredAuth>}></Route>
        <Route exact path="/progress/recent-activity-progress/lesson-report/:id" element={<RequiredAuth><LessonReport/></RequiredAuth>}></Route>
        <Route exact path="/my-progress/assignment-progress-report/:id" element={<RequiredAuth><AssignmentProgressReport/></RequiredAuth>}></Route>
        <Route exact path="/progress/individual-progress/progress-report/:id" element={<RequiredAuth><ProgressReport/></RequiredAuth>}></Route>
        <Route exact path="/progress/assignment-progress" element={<RequiredAuth><AssignmentProgress /></RequiredAuth>}></Route>
        <Route exact path="/progress/assignment-progress/assignments/:id" element={<RequiredAuth><Assignments /></RequiredAuth>}></Route>
        <Route exact path="/progress/printable-progress-report" element={<RequiredAuth><PrintableProgressReport /></RequiredAuth>}></Route>
        {/* <Route exact path="/award" element={<Award />}></Route> */}
        <Route exact path="/certificate" element={<RequiredAuth><Certificate /></RequiredAuth>}></Route>

        {/* Worksheet Route */}
        <Route exact path="/worksheet" element={<RequiredAuth><Worksheet /></RequiredAuth>}></Route>
        <Route exact path="/worksheet/list-worksheet" element={<RequiredAuth><ListWorksheet /></RequiredAuth>}></Route>
        <Route exact path="/worksheet/view-worksheet" element={<RequiredAuth><ViewWorksheet /></RequiredAuth>}></Route>
        <Route exact path="/worksheet/upload-worksheet" element={<RequiredAuth><UploadWorksheet /></RequiredAuth>}></Route>

        {/* <Route exact path="/newsLetter" element={<Newsletter/>} />
        <Route exact path='/newsdetails' element={<NewsDetails/>}></Route> 
        <Route exact path="/monthlyContest" element={<MonthlyContest/>}></Route>
        <Route exact path="/contestWinners" element={<ContestWinners/>}></Route> */}

        <Route exact path="/unsubscribe" element={<Unsubscribe />}></Route>

        <Route exact path="/grade/:grade" element={<Grade />}></Route>
        <Route exact path={"/grade-lession"} element={<GradeLesson />}></Route>
        <Route exact path={"/:grade/:lesson"} element={<GradeLesson />}></Route>
        <Route exact path=":grade/:lesson/:questions" element={<Questions />}></Route>
        <Route exact path="/grade-lesson-search/:questions" element={<Questions />}></Route>
        <Route exact path="/assignment-question/:id/:slug" element={<RequiredAuth><AssignmentQuestions /></RequiredAuth>}></Route>
        <Route exact path="/grade-lesson-search" element={<GradeLessonSearch />}></Route>

        <Route exact path="/assignment-detail/:id" element={<RequiredAuth><AssignmentDetail /></RequiredAuth>}></Route>

        <Route path='*' element={<NotFound />}/>

      </Routes>
    </AuthProvider>
  );
};

export default AppRoutes;
