import React from 'react'
import { GradeCard } from './GradeCard'

export const HomeSection = (props) => {

  const {grade} = props.gradeData
  const {home} = props.gradeData
  return (
    <section>
        {
            home.status == 1 && (
                <div className="hero-banner-wrap">
                    <img src={home.pageBanner}/>
                </div>
            )
        }
        <div className="container">
            <ul className="hero-menu">
                {
                    grade.map((elem,index)=>(
                        <React.Fragment key={index}>
                            {elem.status == 1 && <GradeCard elem={elem}/>} 
                        </React.Fragment>
                    ))
                }
            </ul>
        </div>
  </section>
  )
}
