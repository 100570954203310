import React,{useContext,useEffect,useState} from 'react'
import { Form } from 'react-bootstrap'
import Input from '../Input'
import {UserContext} from "../../App"

const MyStetistics = (props) => {
    const { state, dispatch } = useContext(UserContext);

    const [capicity,setCapicity] = useState(0)
    const [children,setChildren] = useState(0)

    useEffect(()=>{
        setCapicity(props?.myStatestics?.capicity)
        setChildren(props?.myStatestics?.children)
    },[])
    return (
        <div className="account-details">
            <Form autoComplete="false" className="row">
                <Form.Group className="form-group full-fuied col-md-4" controlId="formBasicstudent">
                    <Form.Label>Number of {state.type==3?"Children":"Students"}</Form.Label>
                    <Input
                        type="text"
                        name="childrenCnt"
                        disabled={true}
                        value={`${children} of ${capicity} ${state.type == 3 ? "added" : "assigned to a class"} `}
                    />
                </Form.Group>
            </Form>
        </div>
    )
}

export default MyStetistics