import BannerComponent from "../../component/BannerComponent";
import React, { useEffect, useState } from "react";
import { callApi } from "../../ApiCalls";
import Spinner from "../../component/Loader/Spinner"

const PrivacyPolicy = () => {
  const [resData, setResData] = useState([]);
  useEffect(() => {
    async function getData() {
       const privacyAndPolicy = await callApi("get-page/privacy-policy")
       setResData(privacyAndPolicy)
    }
    getData();
  }, []);
  
  return (
    <>
      {
        resData.data ? (
          <div className="privacy-policy-wrap">
            <BannerComponent bannerData={resData.data.pageData} />
            <div className="table-of-content">
              <div className="container">
                <p dangerouslySetInnerHTML={ {__html: resData.data.pageData.pageTemplate} } />
              </div>
            </div>
          </div>
        ) : <Spinner/>
      }
    </>
  );
};

export default PrivacyPolicy;
