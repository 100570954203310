import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import * as Yup from "yup";
import { useFormik } from "formik";
import Signup from "../../assets/images/signup-img.png";
import { Link } from "react-router-dom";
import BannerComponent from "../../component/BannerComponent";
import { callApi, postRequest } from "../../ApiCalls";
import swal from "sweetalert";
import Spinner from 'react-bootstrap/Spinner';
import { InputGroup } from "react-bootstrap";
import { IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const TeacherSignUp = () => {

  const [isDisable, setIsDisable] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [countyData, setCountryData] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [countyVal, setCountryVal] = useState(233);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleClickshowConfirmPassword = () => setShowConfirmPassword((show) => !show);
  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };

  const userSchema = Yup.object({
    first_name: Yup.string().max(40,"First name must be at least 40 characters").required("First name is required").matches(/^[A-Za-z0-9 ]+$/, "Only Alphabets, Number and Space are allowed for this field"),
    last_name: Yup.string().max(40,"Last name must be at least 40 characters").required("Last name is required").matches(/^[A-Za-z0-9 ]+$/, "Only Alphabets, Number and Space are allowed for this field"),
    phone: Yup.string().max(10,"Phone must be maximum of 10 digits").matches(/^\d+$/, 'Phone number field should have digits only'),
    class_code: Yup.string().max(10,"Class name must be maximum 10 digits").required("Class Code is required"),
    // school: Yup.string().required("School name is required"),
    email: Yup.string().email("Email must be a valid email").required("Email is required"),
    // district: Yup.string().required("District name is required"),
    country_id: Yup.string().required("Country is required"),
    state_id: Yup.string().required("State is required"),
    // no_child: Yup.string().required("Number of student is required"),
    password: Yup
    .string()
    .min(8,"Password must be at least 8 characters").max(20,"Password must be at most 20 characters")
    .required('Password is required')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])/,
      "One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
    cpassword: Yup.string()
      .required("Confirm Password is required")
      .oneOf(
        [Yup.ref("password"), null],
        "Password and confirm password must match"
      ),
  });
  const initialValues = {
    first_name: "",
    last_name: "",
    phone: "",
    class_code: "",
    // school: "",
    email: "",
    district: "",
    country_id: 233 ? 233 : "",
    state_id: "",
    no_child: "",
    password: "",
    cpassword: "",
  };
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: userSchema,
      onSubmit: async (values, { resetForm }) => {
        setSpinner(true)
        setIsDisable(false)
        values["country_id"] = parseInt(values.country_id);
        values["state_id"] = parseInt(values.state_id);
        values["no_child"] = parseInt(values.no_child);
        values["user_role"] = 5
        const response = await postRequest("register", values);
        if (response.statusCode == 200) {
          swal({
            title: "Registered Successfully",
            text: "A verification email has been sent to your registered email address. Please, verify your email.",
            icon: "success",
            button: "ok",
          })
          .then(() => {
            window.location = "/";
          });
            resetForm();
        } else {
          setSpinner(false)
          setIsDisable(true)
        }
      },
    });

  const getStateFromCountry = async (e) => {
    const { name, value } = e.target;
    const response = await callApi("get-states/" + value);
    setStateData(response);
  };

  useEffect(() => {
    async function getData() {
      const response = await callApi("get-countries");
      setCountryData(response);
      const stateData = await callApi("get-states/" + countyVal);
      setStateData(stateData);
    }
    getData();
  }, []);
  return (
    <>
      {countyData.data && (
        <>
          <BannerComponent title="Teacher SignUp" />
          <div className={`sign-up-form-wraper ${spinner && "box-loader"}`}>
            <div className="container">
              <div className="sign-in-form-wraper">
                <div className="sign-in-image">
                  <img src={Signup} />
                </div>
                <div className="common-form">
                  <h2>Your Membership</h2>
                  <Form autoComplete="false" autoFocus={false} onSubmit={handleSubmit}>
                    <Form.Group
                      className="form-group half-fuied-main">
                      <div className="half-fuied">
                        <Form.Label>First Name<span>*</span></Form.Label>
                        <Form.Control
                          type="text"
                          name="first_name"
                          placeholder="Enter First Name"
                          onChange={handleChange}
                          value={values.first_name}
                          autoComplete="off"
                        />
                        {errors.first_name && touched.first_name && (
                          <Form.Text className="text-danger">
                            {errors.first_name}
                          </Form.Text>
                        )}
                      </div>
                      <div className="half-fuied">
                        <Form.Label>Last Name<span>*</span></Form.Label>
                        <Form.Control
                          type="text"
                          name="last_name"
                          placeholder="Enter Last Name"
                          onChange={handleChange}
                          value={values.last_name}
                          autoComplete="off"
                        />
                        {errors.last_name && touched.last_name && (
                          <Form.Text className="text-danger">
                            {errors.last_name}
                          </Form.Text>
                        )}
                      </div>
                    </Form.Group>
                    <Form.Group
                      className="form-group half-fuied-main">
                      <div className="half-fuied">
                        <Form.Label>Phone</Form.Label>
                        <Form.Control
                          type="phone"
                          name="phone"
                          placeholder="Enter Your Number"
                          onChange={handleChange}
                          value={values.phone}
                        />
                        {errors.phone && touched.phone && (
                          <Form.Text className="text-danger">
                            {errors.phone}
                          </Form.Text>
                        )}
                      </div>
                      <div className="half-fuied">
                        <Form.Label>Class Code<span>*</span></Form.Label>
                        <Form.Control
                          type="text"
                          name="class_code"
                          placeholder="Enter Class Code"
                          onChange={handleChange}
                          value={values.class_code}
                          autoComplete="off"
                        />
                        {errors.class_code && touched.class_code && (
                          <Form.Text className="text-danger">
                            {errors.class_code}
                          </Form.Text>
                        )}
                      </div>
                    </Form.Group>

                    <Form.Group
                      className="form-group half-fuied-main">
                        <div className="half-fuied">
                        <Form.Label>District Name</Form.Label>
                        <Form.Control
                          type="text"
                          name="district"
                          placeholder="Enter Your District Name"
                          onChange={handleChange}
                          value={values.district}
                        />
                      </div>
                      <div className="half-fuied">
                        <Form.Label>Country<span>*</span></Form.Label>
                        <Form.Select
                          onChange={(e) => {
                            handleChange(e);
                            setCountryVal(e.target.value);
                            getStateFromCountry(e);
                          }}
                          name="country_id"
                          value={countyVal}
                        >
                          {/* <option>Select Country</option> */}
                          {countyData.data.map((elem, index) => (
                            <option key={index} value={elem.id}>
                              {elem.name}
                            </option>
                          ))}
                        </Form.Select>
                        {errors.country_id && touched.country_id && (
                          <Form.Text className="text-danger">
                            {errors.country_id}
                          </Form.Text>
                        )}
                      </div>
                    </Form.Group>
                    <Form.Group
                      className="form-group half-fuied-main">
                      <div className="half-fuied">
                        <Form.Label>State<span>*</span></Form.Label>
                        <Form.Select onChange={handleChange} name="state_id">
                          <option>Select State</option>
                          {stateData.data &&
                            stateData.data.map((elem, index) => (
                              <option key={index} value={elem.id}>
                                {elem.name}
                              </option>
                            ))}
                        </Form.Select>
                        {errors.state_id && touched.state_id && (
                          <Form.Text className="text-danger">
                            {errors.state_id}
                          </Form.Text>
                        )}
                      </div>
                    </Form.Group>

                    <Form.Group
                      className="form-group full-fuied">
                      <div className="half-fuied">
                      <Form.Label>Email<span>*</span></Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        placeholder="Enter Email"
                        onChange={handleChange}
                        value={values.email}
                        autoComplete="off"
                      />
                      {errors.email && touched.email && (
                        <Form.Text className="text-danger">
                          {errors.email}
                        </Form.Text>
                      )}
                      </div>
                    </Form.Group>

                    <Form.Group
                      className="form-group half-fuied-main">
                      <div className="half-fuied">
                        <Form.Label>Password<span>*</span></Form.Label>
                        <Form.Control
                          type={showPassword ? 'text' : 'password'}
                          name="password"
                          placeholder="Enter Password"
                          onChange={handleChange}
                          value={values.password}
                          autoComplete="off"
                        />
                        <InputGroup.Text id="basic-addon2">
                          <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end" >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputGroup.Text>
                        {errors.password && touched.password && (
                          <Form.Text className="text-danger">
                            {errors.password}
                          </Form.Text>
                        )}
                      </div>
                      <div className="half-fuied">
                        <Form.Label>Confirm Password<span>*</span></Form.Label>
                        <Form.Control
                          type={showConfirmPassword ? 'text' : 'password'}
                          name="cpassword"
                          placeholder="Enter Confirm Password"
                          onChange={handleChange}
                          value={values.cpassword}
                          autoComplete="off"
                        />
                        <InputGroup.Text id="basic-addon2">
                          <IconButton aria-label="toggle password visibility" onClick={handleClickshowConfirmPassword} onMouseDown={handleMouseDownConfirmPassword} edge="end" >
                            {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputGroup.Text>
                        {errors.cpassword && touched.cpassword && (
                          <Form.Text className="text-danger">
                            {errors.cpassword}
                          </Form.Text>
                        )}
                      </div>
                    </Form.Group>

                    <Form.Group
                      className="form-group full-fuied checkbox">
                      <Form.Check
                        checked={isDisable ? true : false}
                        onChange={()=>{}}
                        type="checkbox"
                        className="custom-check"
                        onClick={() => setIsDisable(isDisable ? false : true)}
                      />
                      <div className="chech-box-form" onClick={() => setIsDisable(isDisable ? false : true)}>
                        I am over 18 and I have read and agree to iSoar Math{" "}
                        <Link target="_blank" to="/privacy-policy">Privacy Policy</Link> and{" "}
                        <Link target="_blank" to="/terms-of-use">Terms</Link>.
                      </div>
                    </Form.Group>

                    <Button variant="primary" disabled={isDisable ? false : true} type="submit" className="yellow-button" >
                      Join For Free
                    </Button>
                  </Form>
                </div>
              </div>
              <span className="error ">
                Sign up and start your free 15 day trial. No Credit Card
                Required.
              </span>

              {
                spinner &&
                  <div className="loader">
                    <Spinner animation="border" role="status" >
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </div>
              }
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default TeacherSignUp;
