import React, { useState } from "react";
import { useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { postRequest } from "../../../ApiCalls";
import TemplateFooter from "../../TemplateFooter";
import StudentImg from "../../../assets/images/student.svg";
import CheckImg from "../../../assets/images/check.svg";
import { UserContext } from "../../../App";
import { useContext } from "react";

const QuestionImagePositionAbcd = (props) => {
  const question = props?.question;

  const { state, dispatch } = useContext(UserContext);
  var textBoxLength = question?.questionData?.answer_options.length;
  const [isAnswerWrong, setIsAnswerWrong] = useState(false);
  const [isHideFooter, setIsHideFooter] = useState(false);
  const [usersAnswer, setUserAnswer] = useState("");
  const [inputData, setInputData] = useState({});

  const handleChange = (e, regex) => {
    const { name, value } = e.target;
    setInputData((preVal) => {
      return {
        ...preVal,
        [name]: value.trim(),
      };
    });
  };
  // let a = `answer${0}`
  // console.log(a)

  // console.log(inputData[a],"input Data")

  const handleSubmit = async (e) => {
    e.preventDefault();
    const correctAnswer = question?.questionData?.correct_answer;
    
    const ordered = Object.keys(inputData).sort().reduce(
      (obj, key) => { 
        obj[key] = inputData[key]; 
        return obj;
      }, 
      {}
    );

    var data = Object.values(ordered);

    if (data.length == 0) {
      toast.warning("Please, Enter answer");
      return 0;
    }
    if (JSON.stringify(data) === JSON.stringify(correctAnswer)) {
      props?.handleSubmit(true, data);
    } else {
      setIsAnswerWrong(true);
      setIsHideFooter(true);
      var dataa = {
        question_id: question.id,
        users_ans: data.toString(),
      };
      var res = await postRequest("show-correct-answer", dataa);
      setUserAnswer(data.toString());
    }
    e.target.reset();
    setInputData({});
  };
  const nextQuestion = () => {
    setIsAnswerWrong(false);
    setIsHideFooter(false);
    props?.handleSubmit(false, usersAnswer);
    setUserAnswer("");
  };

  useEffect(() => {
    setIsHideFooter(props?.isHideFooter);
    setUserAnswer(props?.usersAnswer);
  }, []);
  
  return (
    <div className="question-image-position-abcd">
      <Form autoComplete="false" onSubmit={handleSubmit} >
        <div className="img-box">
          <img
            src={question?.questionData?.select_question_image?.images.replace('original', 'thumb')}
            alt="Question Image"
          />
        </div>
        <div className="options-wrap">
          {question?.questionData?.answer_options.map((elem, index) => (
            <div className="input-sequence" key={`${question?.id}_${index}`}>
              <h5 className="mb-2">{elem.position_name}</h5>
              <input                
                type="text"
                className="form-control"
                required
                name={`answer${index}`}
                onChange={(e) => handleChange(e, /[0-9]|\./)}
                autoComplete="false"
              />
            </div>
          ))}
        </div>
        {!isHideFooter && (
          <div className="questions-footer">
            <TemplateFooter question={question} />
            <div className="question-btn">
              <Button className="yellow-button" type="submit">
                Submit
              </Button>
            </div>
          </div>
        )}
        {usersAnswer && (
          <div className='answer-info'>
            <div className='box'>
                <span className='student'>{state.type == 3 || state.type == 4 ? "Children" : "Student"} Answer <img src={StudentImg} className="img-fluid"/></span>
                <h5>{usersAnswer.replaceAll(",", " ")}</h5>
            </div>
            <div className='box'>
                <span className='correct'>Correct Answer <img src={CheckImg} className="img-fluid"/></span>
                {question?.questionData?.correct_answer.map((elem, index) => (
                  // <img  src={elem.images} key={index}/>
                  <h5 key={index}>{elem}</h5>
                ))}
            </div>
        </div>
        )}
        <div className='explanation-info'>
        {usersAnswer && isAnswerWrong && (
          <div className='details'>
            <span>Explanation</span>
            {question?.hintType == 0 ? (
              <p>{question.questionData.answer_hint}</p>
            ) : (
              <img src={question.questionData.answer_hint} className="img-fluid question-img" />
            )}
          </div>
        )}
        {isAnswerWrong && (
          <div className="question-btn">
            <Link className="yellow-button" onClick={nextQuestion}>
              Next
            </Link>
          </div>
        )}
        </div>
      </Form>
    </div>
  );
};

export default QuestionImagePositionAbcd;
