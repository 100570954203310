import React, { useEffect, useState ,useContext} from "react";
import Chart from "../../component/Charts/GoogleChart";
import { callApi, dateConverter } from "../../ApiCalls";
import BannerComponent from "../../component/BannerComponent";
import Spinner from "../../component/Loader/Spinner";
import {UserContext} from "../../App";
import countdown from '../../assets/images/count-down.png';
import Table from "../../component/Table/Table";
import { Link, useNavigate } from "react-router-dom";
import { ModalComponent } from "../../component/ModalComponent";


const MyProgress = () => {

  const { state, dispatch } = useContext(UserContext);

  const [myProgress, setMyProgress] = useState([]);
  const [loader, setLoader] = useState(false)
  const [title,setTitle] = useState("")
  const [infoData,setInfoData] = useState("")
  const [show,setShow] = useState(false)
  const navigate = useNavigate();

  useEffect(() => {
    async function getData() {
      const myProgress = await callApi("my-progress");
      setMyProgress(myProgress.data);
      setLoader(true);
    }
    getData();
  }, []);
    const assignmentProgressReport = (id,type) => {
      navigate({
        pathname : `/my-progress/assignment-progress-report/${id}`,
        search: `?type=${type}`
      });
    }
    const handleModal = (data) => {
      setInfoData(data)
      setTitle("Certificate")
      setShow(true)
    }
  const handleClose = () => setShow(false)

  var columns = [
    // {
    //   dataField: "lessonImage",
    //   text: "Lesson Icon",
    //   formatter : (cell,row) => {
    //     return <div className="text-center"><img className="lesson-icon" src={row.lessonImage}/></div>
    //   },
    //   sort: true
    // },
    {
      dataField: "lessonName",
      text: "Lesson",
    },
    {
      dataField: "scorePercent",
      text: "Score",
      formatter : (cell,row) => {
          return "("+row.scorePercent + ")" + row.scoreDisplay
      }
    },
    {
      dataField: "completionDate",
      text: "Date",
      formatter : (cell,row) => {
          return dateConverter(row.completionDate)
      }
    },
    {
      dataField: "view",
      text: "View",
      formatter : (cell,row) => {
        return (
          <>
            <div className='more-link' onClick={()=>assignmentProgressReport(row.assignmentId,"all")}>All Ques. & Ans.</div>
            <div className='more-link' onClick={()=>assignmentProgressReport(row.assignmentId,"incorrect")}>Only Incorrect</div>
          </>
        );
      }
    },
    {
      dataField: "view",
      text: "Certificate",
      hidden: state.type == 6 ? false : true,
      formatter : (cell,row) => {
        return (
          <>
            {
              row.isCertificate == 1 ? 
                <div className='more-link' onClick={()=>handleModal(row.path)}>View</div>
              : 
                <div>-</div>
            }
          </>
        );
      }
    }
  ];

  return (
    <>
      {loader ? (
        <>
          <BannerComponent title="My Progress" />
          <div className="container">
            <div className="student-progress">
              {
                myProgress.ProgressStatistics !== null && (
                  <div className="piechart">
                    <h4 className="sub-title">Progress Statistics</h4>
                      <div className="statistics-wrap">
                      <div className="box-wrap">
                        <label>Percent Correct : </label>
                        <Chart chartData={myProgress.ProgressStatistics.percentCorrect} type="donut" chartType="PieChart" />
                      </div>
                      <div className="box-wrap">
                        <label>Questions Answered : </label>
                        <div className="img-box">
                          <img src={countdown} className='img-fluid' />
                          <h5 className="answere">{myProgress.ProgressStatistics.questionAnswerd}</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }
              {/* <strong>Lesson Results</strong> */}
              <Table rowData={myProgress.LessonResults.lessons} columns={columns} type="Progress" keyField="assignmentId"/>
            </div>
            {
              show && 
                <ModalComponent show={show} handleClose={handleClose} type="view" infoData={infoData} className="certificate" title={title}/>
            }
          </div>
        </>
      ) : (
        <Spinner />
      )}
    </>
  );
};

export default MyProgress;
