import React,{useEffect,useState} from "react";
import ReactStars from "react-stars";
import LessonImage from "../../assets/images/lesson-complete.png";
import GblObject from "../../GblData";

const LessonComplete = (props) => {
  const queLength = props?.lessonTime ? props?.totalAttempt + 1 : props?.queLength;
  const correctAns = props?.correctAns === !0 ? props?.correctAns+1 : props?.correctAns;

  const [ratting,setRatting] = useState(0)

  useEffect (()=>{
    var ratting = ((correctAns * 5) / queLength)
    // var Score = ((correctAns * 5) / queLength)
    setRatting(ratting)
  },[])
  return (
    <div className="lesson-complete">
      <div className="lesson-img">
        <img src={LessonImage} />
      </div>
      {
        GblObject?.lessons_score == 0 ? (
          <h3>Your Score: <span>{correctAns} out of {queLength}</span></h3>
        ) : (
          <h3>Your Score: <span>{Math.round(ratting*20)}%</span></h3>
        )
      }
      <div className="rating-star">
        <ReactStars value={ratting} edit={false} isHalf={true} size={40} />
      </div>
    </div>
  );
};

export default LessonComplete;
