import React,{useEffect,useContext,useState} from "react"
import BannerComponent from "../../component/BannerComponent";
import { UserContext } from '../../App'
import { callApi, dateConverter } from "../../ApiCalls";
import {Tabs,Tab,Box,Typography} from "@mui/material";
import Spinner from "../../component/Loader/Spinner"
import BlueEdit from "../../assets/images/blue-edit.png";
import { useNavigate } from "react-router";
import Table from "../../component/Table/Table";

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
        >
        {value === index && (
            <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
            </Box>
        )}
        </div>
    );
    
}
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
        "tab-value" : index,
    };
}

const RecentActivityProgress = () => {

    const { state, dispatch } = useContext(UserContext);
    const [recentAactivity,setRecentAactivity] = useState([])
    const navigatePage = useNavigate();

    const [value, setValue] = useState(0);
    const [classData,setClassData] = useState([])
    const [activeTab,setActiveTab] = useState(0)

    const navigate = window.location.pathname.split('/')[1];

    var columns = [
        {
            dataField: "date",
            text: "Date",
            formatter : (cell,row) => {
                return dateConverter(cell)
            }
        },
        {
            dataField: "name",
            text: "Name",
            sort: true
        },
        {
            dataField: "lesson",
            text: "Lesson"
        },
        {
            dataField: "score",
            text: "Score",
            formatter : (cell,row) => {
                return (
                <>
                    <span className='percentage'>({row.percentage}%){" "}</span> {row.correctAnsOfQue} out of {row.totalAssignQuestion}
                </>
                )
            }
        },
        {
            dataField: "info",
            text: "More Info",
            formatter: (cell, row, rowIndex) => {
                return (
                    <div className='more-link' onClick={()=>navigatePage(`/progress/recent-activity-progress/lesson-report/${row.assignmentId}`)}><img src={BlueEdit}/>{" "}More info</div>
                );
            }
        }
    ];

    const handleChange = async(event, newValue) => {
        setValue(newValue);
        var classId = event.target.getAttribute("tab-value")
        const response = await callApi(`get-recent-activity/${classId}`);
        setRecentAactivity(response.data)
        setActiveTab(classId)
    };

    useEffect(()=>{
        async function getStudentData() {
            var classData = await callApi("get-class");
            setClassData(classData)
            if(classData.data.length > 0){
                setActiveTab(classData.data[0].id)
                const response = await callApi(`get-recent-activity/${classData.data[0].id}`);
                setRecentAactivity(response.data)
            }
        }
        async function getChildData() {
          var recentAactivity = await callApi("get-recent-activity")
          setRecentAactivity(recentAactivity.data)
        }
        if(state.type == 5)
            getStudentData()
        else 
            getChildData()
    },[])
    
    return(
        <>
        {
            recentAactivity ? (
            <>
            <BannerComponent title="Recent Activity" navigate={navigate}/>
            <div className="upcomingWebinars-main">
                <div className="container">
                    <div className="upcomingWebinars-main-wraper">
                        {state.type == 5 && classData.data && classData.data.length > 0 ? (
                            <>                          
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className="tab-items">
                                    <Tabs value={value} onChange={handleChange}>
                                    {
                                        classData.data.map((elem,index)=>(
                                            <Tab label={`${elem.className}`} key={index} {...a11yProps(elem.id)} />
                                        ))
                                    }
                                    </Tabs>
                                </Box> 
                                <div className="tab-content">
                                    <TabPanel value={value} index={value} >                                                                            
                                        <Table rowData={recentAactivity} columns={columns} type="Recent activity"/>
                                    </TabPanel>
                                </div>
                            </>
                            ) :
                                <Table rowData={recentAactivity} columns={columns} type="Recent activity"/>
                        }
                    </div>
                </div>
            </div>
            </>
            ) : <Spinner/>
        }
        </>
    )
}


export default RecentActivityProgress;