import React, { useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import PaymentIcon from "../../assets/images/payment-icon.svg";

const RenewFailure = () => {

  const navigate = useNavigate();

  useEffect(()=>{
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });

    if(params.canceled == null )
        navigate("/")
  },[])
  
  return (
    <section className="renew-failure-wrap">
        <div className="container">
            <div className='box'>
              <div className='img-wrap'>
                  <img src={PaymentIcon} className="img-fluid" />
              </div>
              <p>Hi there, unfortunately we were unable to charge your payment. Please, contact with admin.</p>
            </div>
        </div>
    </section>
  )
}

export default RenewFailure