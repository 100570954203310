import React, { useEffect, useState } from "react";
import { callApi } from "../ApiCalls";
import { Link, useNavigate } from "react-router-dom";
import {Row,Col,Card} from 'react-bootstrap';
import { ModalComponent } from "../component/ModalComponent";
import ComimgSoon from "../component/ComimgSoon";
import Spinner from "../component/Loader/Spinner";

const Awards = () => {

  const [awards, setAwards] = useState([]);
	const [show, setShow] = useState(false);
  const [infoData,setInfoData] = useState()
  const [isLoder,setIsLoader] = useState(false)

	const handleClose = () => {
		setShow(false);
	};
	const handleModal = (data) => {
		setShow(true);
    setInfoData(data)
	};
  useEffect(() => {
    async function getData() {
      var awards = await callApi("award-list");
      setAwards(awards);
      setIsLoader(true)
    }
    getData();
  }, []);
  return (
    <>
    {
      isLoder ? (
      <section className="award-wrapper">
        <div className="container">
          {/* <h4 className='text-center pt-5'>Coming soon</h4> */}
          <Row className="mb-5">
            { (awards.data && awards.data.length > 0) ? awards.data.map((elem, index) => (
              <Col lg={4} md={6} xs={12} key={index}>
                <Link onClick={() => {handleModal(elem.certificateImage)}} className="awards-list">
                  <Card>
                    <div className="img-wrap">
                      <Card.Img variant="top" src={elem.awardsImage} />
                    </div>
                    <Card.Body>
                      <Card.Title>
                        {elem.awardTitle}
                      </Card.Title>
                    </Card.Body>
                  </Card>
                </Link>
              </Col>
            ))
            : (
              <center>No Award Found</center>
            )
          }
          </Row>
        </div>
      </section>
      ) :(<Spinner/>)
    }
    {
				show && <ModalComponent infoData={infoData} show={show} handleClose={handleClose} type={"Awards"} className="awards" title="Certificate" />
		}
    </>
  );
};

export default Awards;
