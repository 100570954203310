import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Row, Col, Button, Form } from 'react-bootstrap';
import { toast } from 'react-toastify'
import TemplateFooter from '../../TemplateFooter';
import { useEffect } from 'react';
import StudentImg from "../../../assets/images/student.svg";
import CheckImg from "../../../assets/images/check.svg";
import DummyImg from "../../../assets/images/dummy-image.jpg";
import loaderGIF from "../../../assets/images/loader.gif";
import { useContext } from 'react';
import { UserContext } from '../../../App';

const MultiImageOptions = (props) => {
    const question = props?.question

    const { state, dispatch } = useContext(UserContext);
    const [answer, setAnswer] = useState("")
    const [isDisabled, setIsDisabled] = useState(true)
    const [isAnswerWrong,setIsAnswerWrong] = useState(false)
    const [isHideFooter,setIsHideFooter] = useState(false);
    const [usersAnswer,setUserAnswer] = useState("")
    const [loading, setLoading] = useState(true);

    const correctAns = question.questionData.select_question_image.filter((elem) => {
        return elem.index == question?.questionData?.correct_answer   
    })
    const RightAns = Object.assign({}, ...correctAns);

    const studentAns = question.questionData.select_question_image.filter((elem) => {
        return elem.index == usersAnswer
    })
    const userAns = Object.assign({}, ...studentAns);
    const handleSubmit = async() => {
        const correctAnswer = question?.questionData?.correct_answer
        setIsDisabled(false)
        if (answer == "") {
            toast.warning("Please, select answer");
            setIsDisabled(true)
            return 0
        }
        if (answer === correctAnswer) {
            props?.handleSubmit(true, answer)
        } else {
            setIsAnswerWrong(true)
            setIsHideFooter(true)
            var data = {
                question_id : question.id,
                users_ans : answer
            }
            // var res = await postRequest("show-correct-answer",data)
            setUserAnswer(answer)
            // props?.handleSubmit(false, answer)
        }
        setAnswer("")
        setIsDisabled(true)
    }
    const nextQuestion = () => {
        setIsAnswerWrong(false)
        setIsHideFooter(false)
        props?.handleSubmit(false, usersAnswer)
        setUserAnswer("")
    }

    const imageLoaded = () => {
        setLoading(false)
    }

    useEffect(()=>{
        setIsHideFooter(props?.isHideFooter)
        setUserAnswer(props?.usersAnswer)
    },[])
    return (
        <>
            <div className='multi-image-options'>
                <Row className='justify-content-center'>

                    {question.questionData.select_question_image.map((elem, index) => (
                        <Col lg={6} md={6} xs={12} key={index} className="text-center">
                            <Link className={answer==elem.index?"question-info active":'question-info'}  key={index} onClick={() => {setAnswer(elem.index)}}>
                                <div className='loader'>
                                    <img src={loaderGIF} className={`${loading ? "box-loader" : "d-none"}`}  alt="Loader Image"/>
                                </div>
                                <img src={elem.images ? elem.images.replace('original', 'thumb') : DummyImg} alt="Question Image" style={{display: loading ? "none" : "block"}} onLoad={imageLoaded}/>
                            </Link>
                        </Col>
                    ))}
                </Row>

                {
                    !isHideFooter && 
                    <div className='questions-footer'>
                        <TemplateFooter question={question} />
                        <div className='question-btn'>
                            <Link className="yellow-button" onClick={() => isDisabled && handleSubmit()}>
                                Submit
                            </Link>
                        </div>
                    </div>
                }
                {
                    usersAnswer &&
                    <div className='answer-info'>
                        <div className='box'>
                            <span className='student'>{state.type == 3 || state.type == 4 ? "Children" : "Student"} Answer <img src={StudentImg} className="img-fluid"/></span>
                            <img src={userAns.images} className="img-fluid question-img" />
                        </div>
                        <div className='box'>
                            <span className='correct'>Correct Answer <img src={CheckImg} className="img-fluid"/></span>
                            <img src={RightAns.images} className="img-fluid question-img" />
                        </div>
                    </div>
                }
                <div className='explanation-info'>
                    {
                        usersAnswer && isAnswerWrong &&
                            <div className='details'>
                                <span>Explanation</span>
                                {
                                question?.hintType == 0 ? 
                                    <p>{question.questionData.answer_hint}</p>
                                :
                                    <img src={question.questionData.answer_hint} className="img-fluid question-img" />
                                }                            
                                </div>
                    }  
                    {
                        isAnswerWrong && 
                        <div className='question-btn'>
                            <Link className="yellow-button" onClick={nextQuestion}>
                                Next
                            </Link>
                        </div>
                    } 
                </div>
            </div>
        </>
    )
}

export default MultiImageOptions