import React,{useContext} from 'react'
import { Link,useNavigate } from 'react-router-dom'
import { UserContext } from '../../App';
import BannerComponent from '../../component/BannerComponent'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import WorksheetIcon from "../../assets/images/worksheet-list.svg";
import UploadIcon from "../../assets/images/worksheet-upload.svg";
import ViewIcon from "../../assets/images/worksheet-view.svg";

const Worksheet = () => {

    const { state, dispatch } = useContext(UserContext);
    const navigate = useNavigate();

    if(state.isPaid == 0)
        navigate("/");

    return (
        <>
            <BannerComponent title="Worksheets"/>
            <div  className="worksheet-wrap">
                <div className="container">
                    <div className="worksheet-box">
                        <Row className="justify-content-md-evenly flex-wrap">
                            <Col lg={3} md={5} xs={12}>
                                <Link to='/worksheet/list-worksheet' className="custom-link" variant="outline-primary">
                                    <img src={WorksheetIcon} />
                                    <h4>Worksheets</h4>
                                </Link>
                            </Col>
                            <Col lg={3} md={5} xs={12}>
                            {
                                state.type == 4 || state.type == 6 ?
                                    <Link to='/worksheet/upload-worksheet' className="custom-link" variant="outline-primary">
                                        <img src={UploadIcon} />
                                        <h4>Upload Worksheet</h4>
                                    </Link>
                                :
                                    <Link to='/worksheet/view-worksheet' className="custom-link" variant="outline-primary">
                                        <img src={ViewIcon} />
                                        <h4>View Worksheets</h4>
                                    </Link>
                            }
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Worksheet