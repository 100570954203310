import React,{useState,useEffect,useRef} from 'react'
import { postRequest } from '../ApiCalls'
import { useNavigate } from "react-router-dom";
import UnsubScribe from "../assets/images/unsubscribe-icon.svg";

const Unsubscribe = () => {

    const [message,setMessage] = useState("")
    const isUnmountedRef = useRef(false)
    const navigate = useNavigate();

    useEffect(()=>{
        
        if (isUnmountedRef.current == false){
            const params = new Proxy(new URLSearchParams(window.location.search), {
                get: (searchParams, prop) => searchParams.get(prop),
            });

            async function getData() {
                var res = await postRequest("unsubscribe",{email:params.email})
                setMessage(res.message)
            }
            if(params.email != null)
                getData()       
            else 
                navigate("/")
        } 
        return () => { isUnmountedRef.current = true }
    },[])

    return (
        <section className="unsubscribe-wrap">
            <div className="container">
                <div className='box'>
                <div className='img-wrap'>
                    <img src={UnsubScribe} className="img-fluid" />
                </div>
                <p>{message}</p>
                </div>
            </div>
        </section>
    )
}

export default Unsubscribe