import React, { useEffect, useState } from "react";
import BannerComponent from "../../component/BannerComponent";
import { callApi } from "../../ApiCalls";
import HomeSection from "../../component/Home/HomeSection";
import ContentInformation from "../../component/Home/ContentInformation";
import Spinner from "../../component/Loader/Spinner";

const Home = () => {
  const [resData, setResData] = useState([]);
  useEffect(() => {
    async function getData() {
      const gradeData = await callApi("get-only-grades");
      setResData(gradeData);
    }
    getData();
  }, []);

  return (
    <>
      {
        resData.data ? (
          <>
            <BannerComponent title="Teacher Home" />
            <HomeSection gradeData={resData.data}/>
            <div className="renewal-information">
              <div className="container">
                <ContentInformation/>
                {/* <Link to="/">View Help</Link> */}
              </div>
            </div>
          </>
        ) : <Spinner />
      }
    </>
  );
};

export default Home;
