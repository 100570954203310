import React from 'react'

const QuestionHint = (props) => {
  const infoData = props?.infoData;
  const hint = props?.hint;
  return (
    <>
      {hint == 0 ? infoData : <div className='text-center'><img src={infoData}/></div>}
    </>
  )
}

export default QuestionHint;
