import React, { useState, useEffect } from "react";
import BannerComponent from "../../component/BannerComponent";
import { Link } from "react-router-dom";
import axios from "axios";
import {Tabs,Tab,Box,Typography} from "@mui/material";
import { ModalComponent } from "../../component/ModalComponent";
import { callApi } from "../../ApiCalls";
import Spinner from "../../component/Loader/Spinner";
import { Button } from "react-bootstrap";
import Table from "../../component/Table/Table";
import Progress from "../../assets/images/progress.png";
import Redcross from "../../assets/images/red-cross.png";
import BlueEdit from "../../assets/images/blue-edit.png";
import swal from "sweetalert";
import environment from '../../environment';
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
    "tab-value" : index,
  };
}
const MyStudent = () => {

  const [value, setValue] = useState(0);
  const [classData,setClassData] = useState([])
  const [show, setShow] = useState(false);
  const [addClass, setAddClass] = useState(false);
  const [addStudent, setAddStudent] = useState(false);
  const [viewBadge, setViewBadge] = useState(false);
  const [infoData,setInfoData] = useState("")
  const [studentData, setStudentData] = useState([])
  const [gradeData,setGradeData] = useState([])
  const [isUpdate, setIsUpdate] = useState(false);
  const [userData, setUserData] = useState([])
  const [title,setTitle] = useState()
  const [activeTab,setActiveTab] = useState(0)
  const [res,setRes] = useState([])

  const handleClose = () => {
    setShow(false)
    setAddClass(false)
    setAddStudent(false)
    setViewBadge(false)
    setIsUpdate(false)
  }
  const addData = (data) => {
    getStudent()
  }
  const handleChange = async(event, newValue) => {
    setValue(newValue);
    var classId = event.target.getAttribute("tab-value")
    const response = await callApi(`get-student/${classId}`);
    setStudentData(response.data)
    setRes(response)
    setActiveTab(classId)
  };
  const handleShow = (data=null,type) => {
    setShow(true)
    if(type === "addClass") {
      setTitle("Add Class")
      setAddClass(true)
    } else if(type == "badge") {
      setInfoData(data)
      setViewBadge(true)
      setTitle("Badge")
    } else {
      setAddStudent(true)
      setTitle("Add Student")
    }
  }
  const childEdit = (data) => {
    setTitle("Edit Student")
    setShow(true)
    setIsUpdate(true)
    setAddStudent(true)
    setUserData(data)
  }
  const deleteRecord = async (userId) => {
    swal({
      title: "Are you sure, You want to delete ?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then(async(willDelete) => {
        if (willDelete) {
          var token = localStorage.getItem("token");
          const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          };
          await axios.delete(environment.REACT_APP_API_URL + `destroy-student/${userId}`, {
              headers: headers,
            }).then((res) => {
              if(res.data.statusCode == 200){
                var data = studentData.filter((student)=>{
                  return student.userId !== userId
                })
                setStudentData(data)
              }
            }).catch((err) => {
              console.log(err, "Error");
            });
          swal("Record has been deleted!", {
              icon: "success",
              timer: 2000,
          });
        } 
    });
  };

  const getStudent = async() => {
    const response = await callApi(`get-student/${activeTab}`);
    setStudentData(response.data)
    setRes(response)
  }
  const getClass = async() => {
    const classData = await callApi("get-class");
    setClassData(classData);
    setActiveTab(classData.data[0].id)
    const response = await callApi(`get-student/${classData.data[0].id}`);
    setStudentData(response.data)
    setRes(response)
  }
  useEffect(() => {
    async function getData() {
      const classData = await callApi("get-class");
      setClassData(classData)
      if(classData.data.length > 0){
        setActiveTab(classData.data[0].id)
        const response = await callApi(`get-student/${classData.data[0].id}`);
        setStudentData(response.data)
        setRes(response)
      }
      const res = await callApi("get-only-grades");
      setGradeData(res.data);
    }
    getData();
  }, []);

  var columns = [
    {
      dataField: "firstName",
      text: "First Name",
      sort: true
    },
    {
      dataField: "lastName",
      text: "Last Name",
    },
    {
      dataField: "gradeName",
      text: "Grade Level",
    },
    {
      dataField: "progress",
      text: "Progress",
      formatter : (cell,row) => {
        return <Link to="/progress"><img src={Progress}/></Link>
      }
    },
    {
      dataField: "edit",
      text: "Edit",
      formatter : (cell,row) => {
        return <Link onClick={()=>childEdit(row,"editStudent")}><img src={BlueEdit}/></Link>
      }
    },
    {
      dataField: "remove",
      text: "Remove",
      formatter : (cell,row) => {
        return <Link onClick={()=>deleteRecord(row.userId)}><img src={Redcross}/></Link>
      }
    },
    {
      dataField: "status",
      text: "Badge",
      formatter : (cell,row) => {
        return <div className="assign-detail" onClick={()=>handleShow(row.userId,"badge")}>View</div>
      }
    }
  ];

  return (
    <>
      {
        classData.data ? (
          <div className='account-main'>
            <BannerComponent title="My Students"/>
            <div className='account-tab'>
                <div className="container">
                  { classData.data && classData.data.length > 0 && (
                    <>                          
                      <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className="tab-items">
                        <Tabs value={value} onChange={handleChange}>
                          {
                            classData.data.map((elem,index)=>(
                              <Tab label={`${elem.className}`} {...a11yProps(elem.id)} />
                            ))
                          }
                        </Tabs>
                      </Box> 
                      <div className="tab-content">
                        <TabPanel value={value} index={value} >
                          <Table rowData={studentData} columns={columns} type="Student/s" />
                        </TabPanel>
                      </div>
                    </>
                  ) }
                  <div className="d-flex justify-content-between align-item-center flex-wrap add-child-button">
                    {
                      classData.classCapacity > classData.data.length && 
                        <Link className="yellow-button" onClick={()=>handleShow(null,"addClass")}>Create Class</Link>
                    }
                    {
                      classData.data && classData.data.length > 0 && 
                      !(res.capacity == res.totalChildren || res.data.length > res.capacity) ? 
                        <Button
                          variant="primary"
                          // disabled={(res.capacity == res.totalChildren || res.data.length > res.capacity) ? true : false}
                          className="yellow-button"
                          onClick={()=>handleShow(null,"addStudent")}
                          >Add Student
                        </Button>
                        :""
                    }
                  </div>
                </div>
            </div>
          </div>
        ) : <Spinner/>
      }
      {
        addClass && show &&
          <ModalComponent show={show} addData={getClass} handleClose={handleClose} detailType="addClass" type="addClass" className="addClass" title={title}/>
      }
      {
        addStudent && show &&
          <ModalComponent show={show} userData={userData} isUpdate={isUpdate} classId={activeTab} handleClose={handleClose} addData={addData} classData={classData.data} detailType="addStudent" type="addStudent" className="addStudent" title={title} gradeData={gradeData}/>
      }
      {
        viewBadge && show &&
          <ModalComponent show={show} handleClose={handleClose} infoData={infoData} type="badge" className="certificate" title={title}/>
      }
    </>
  );
};

export default MyStudent;
