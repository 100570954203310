import React,{useState, useEffect} from 'react'
import { callApi } from '../../ApiCalls'
import { dateConverter } from '../../ApiCalls'

const ContentInformation = () => {

  const [accountInfo,setAccountInfo] = useState({})
  useEffect(()=>{
    async function getAccountInfo() {
      var res = await callApi("my-account/get-account-details");
      setAccountInfo(res.data)
    }
    getAccountInfo()
  },[])
  return (
    <>
        <div className="renewal-information-wrap">
            <strong>Renewal Information</strong>
            <em>
                Your membership is active through {dateConverter(accountInfo.accountExpiration)}.
                <br />
                Thank you for choosing www.isoarmath.com!
            </em>
        </div>
    </>
  )
}

export default ContentInformation