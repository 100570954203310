import React,{useState} from 'react'
import Form from "react-bootstrap/Form";
import { useEffect } from 'react';
import { callApi } from '../../ApiCalls';

const GeneralSetting = (props) => {

    const [generalSetting, setGeneralSetting] = useState([])
    const [readAloud,setReadAloud] = useState(0)

    const handleGeneralSettingChange = async(e,type) =>{
        var {name,value} = e.target
        if(type == "readAloud") {
          name = type
          if(readAloud == 1)
            setReadAloud(0)
          else 
            setReadAloud(1)
          value = readAloud
        }
        setGeneralSetting((preval)=>{
          return {...preval,[name] : value}
        })
        var data = {
          [name] : value
        }
        await callApi("my-account/general-setting-update",data)
    }

    useEffect(()=>{
        setGeneralSetting(props?.generalSetting)
    },[])

    return (
        <div className="account-details">
            <Form autoComplete="false" className="row">
                <Form.Group className="form-group full-fuied col-md-3" controlId="formBasicstudent" >
                    <Form.Label>Hints</Form.Label>
                    <Form.Select value={generalSetting.hints} name="hints" onChange={handleGeneralSettingChange}>
                        {/* <option value="0">Allow 3 hints per lesson</option> */}
                        <option value="1">allow hints</option>
                        <option value="2">do not allow hints</option>
                    </Form.Select>
                </Form.Group>
                <Form.Group className="form-group full-fuied col-md-3" controlId="formBasicstudent">
                    <Form.Label>Animations</Form.Label>
                    <Form.Select value={generalSetting.animation} name="animation" onChange={handleGeneralSettingChange}>
                        <option value="0">allow</option>
                        <option value="1">do not allow</option>
                    </Form.Select>
                </Form.Group>
                <Form.Group className="form-group full-fuied col-md-6" controlId="formBasicstudent">
                    <Form.Label>Score that children see after lesson</Form.Label>
                    <Form.Select value={generalSetting.lessonsScore} name="lessonsScore" onChange={handleGeneralSettingChange}>
                        <option value="0">Only number correct/incorrect (ex: You got 6 out of 7 correct)</option>
                        <option value="1">Percent correct (ex: You scored 89%)</option>
                    </Form.Select>
                </Form.Group>
            </Form>
        </div>
    )
}

export default GeneralSetting