import React, { useEffect, useState, useContext } from "react";
import BannerComponent from "../component/BannerComponent";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { callApi } from "../ApiCalls";
import { UserContext } from "../App";
import { ModalComponent } from "../component/ModalComponent";
import Spinner from "../component/Loader/Spinner";
import { Button } from "@mui/material";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
  },
}));

const GradeLesson = () => {

  const { state, dispatch } = useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();

  const [resData, setResData] = useState([]);
  const [show, setShow] = useState(false);
  const [isAssignment, setIsAssignment] = useState(false);
  const [isInfoData, setIsInfoData] = useState(false);
  const [infoData, setInfoData] = useState()
  const [urlData, setUrlData] = useState([])
  const [lessonId, setLessonId] = useState(0)

  const handleClose = () => {
    setShow(false)
    setIsAssignment(false)
    setIsInfoData(false)
  }
  const handlemodal = (type, data) => {
    setShow(true)
    if (type == "assignment") {
      setIsAssignment(true)
      setLessonId(data)
    } else {
      setIsInfoData(true)
      setInfoData(data.description)
    }
  }
  const getData = async () => {
    var urlArr = location.pathname.split('/')
    const res = await callApi(`get-sub-categories/${urlArr[2]}`);
    setResData(res);
  }
  useEffect(() => {
    async function getData() {
      var urlArr = location.pathname.split('/')
      setUrlData(urlArr)
      if (state.type != null) {
        const res = await callApi(`get-sub-categories/${urlArr[2]}`);
        setResData(res);
      } else {
        const res = await callApi(`get-sub-category-for-guest/${urlArr[2]}`);
        setResData(res);
      }
    }
    getData();
  }, []);

  
  return (
    <>
      {
        resData.data ? (
          <div className="class-tab-main">
            <BannerComponent title="Lesson" />
            <div className="common-lessions-inner">
              <div className="container">
                <div className="common-lessions-inner-wrap">
                  <div className="common-lessions-inner-repeat mb-5">
                    {
                      (resData.data && resData.data.subCategory.length > 0) ? (
                        <>
                          <div className="title-main">
                            <h4>{resData.data.categoryName}</h4>
                          </div>
                          <ul>
                            {
                              resData.data.subCategory.map((elem, index) => (
                                elem.status == 1 && (
                                  <li key={index}>
                                    <>
                                      <div key={index}>
                                        {/* <HtmlTooltip
                                          placement="top-start"

                                          title={
                                            <React.Fragment>
                                              <Typography color="inherit">
                                                <img src={elem.image} />
                                              </Typography>
                                            </React.Fragment>
                                          }
                                        >
                                        </HtmlTooltip> */}
                                          <Link to={`/${urlData[1]}/${urlData[2]}/${elem.slug}`}>{elem.name}</Link>
                                      </div>
                                      {
                                        state.type != null && (
                                          <div className="tooltip-links">
                                            {
                                              (state.type == 3 || state.type == 5) && (
                                                <Link title={`${elem.isAssign == 1 ? "Lesson assigned" : "Assign this lesson"}`} className={`${elem.isAssign == 1 ? "assigned" : "assign"}-lesson`} onClick={() => elem.isAssign != 1 && handlemodal('assignment', elem)}>
                                                  <span></span>
                                                </Link>
                                              )
                                            }
                                            <Link onClick={() => handlemodal('info', elem)} title="Standards">S</Link>
                                          </div>
                                        )
                                      }
                                    </>
                                    </li>
                                  )
                              ))
                            }
                          </ul>
                        </>
                      ) : (
                        <center>No Data Found</center>
                      )
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : <Spinner />
      }
      {
        show && isAssignment ?
          <ModalComponent show={show} handleClose={handleClose} getData={getData} gradeData={lessonId} type={"assignment"} className="assign-assignment" title="Assign Assignment" />
          : show && isInfoData ?
            <ModalComponent show={show} handleClose={handleClose} infoData={infoData} type={"description"} className="description" title="Lesson Information" />
            : ""
      }
    </>
  );
};

export default GradeLesson;
