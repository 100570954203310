import React, { useEffect, useState } from "react";
import { callApi } from "../../ApiCalls";
import BannerComponent from "../../component/BannerComponent";
import {InfoComponentTwo} from "../../component/InfoComponentTwo";
import Spinner from "../../component/Loader/Spinner"

const About = () => {
  const [resData, setResData] = useState([]);
  useEffect(() => {
    async function getData() {
      const Aboutfile = await callApi("about-us");
      setResData(Aboutfile)
    }
    getData();
  }, []);
  return (
    <>
      { 
        resData.data ?
          <div className="about-main-page">
            <BannerComponent bannerData={resData.data.pageData}/>
              <span className="stars"></span>
                <div className="about-page-wraper">
                  <ul>
                    {resData.data && (
                      <>
                      {
                        resData.data.ourMissionSection.status == 1 && 
                          <div className="student-project">
                            <div className="main-title">
                                <h2>{resData.data.ourMissionSection.sectionContent.title}</h2>
                            </div>
                            <InfoComponentTwo contentData={resData.data.ourMissionSection} />
                          </div>
                      }
                      {
                        resData.data.ourStorySection.status == 1 && 
                          <div className="student-project about-common-image">
                            <div className="main-title">
                                <h2>{resData.data.ourStorySection.sectionContent.title}</h2>
                            </div>
                            <InfoComponentTwo contentData={resData.data.ourStorySection} />
                          </div>
                      }
                      {
                        resData.data.ourTeamSection.status == 1 && 
                          <div className="student-project about-common-image">
                            <div className="main-title">
                                <h2>{resData.data.ourTeamSection.sectionContent.title}</h2>
                            </div>
                            <InfoComponentTwo contentData={resData.data.ourTeamSection} />
                          </div>
                      }
                      {
                        resData.data.partnerWithUsSection.status == 1 &&   
                          <div className="student-project about-common-image">
                            <div className="main-title">
                                <h2>{resData.data.partnerWithUsSection.sectionContent.title}</h2>
                            </div>
                            <InfoComponentTwo contentData={resData.data.partnerWithUsSection} />
                          </div>
                      } 
                      </>
                    )}
                  </ul>
            </div>
          </div>
        : <Spinner />
      }
    </>
  );
};

export default About;
