import React,{useState,useEffect} from 'react'
import {FormControlLabel,Checkbox} from '@mui/material';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import TemplateFooter from '../../TemplateFooter';
import { postRequest } from '../../../ApiCalls';
import StudentImg from "../../../assets/images/student.svg";
import CheckImg from "../../../assets/images/check.svg";
import { UserContext } from '../../../App';
import { useContext } from 'react';

const QuestionImageMultiImage = (props) => {
    const question = props?.question

    const { state, dispatch } = useContext(UserContext);
    const [isAnswerWrong,setIsAnswerWrong] = useState(false)
    const [isHideFooter,setIsHideFooter] = useState(false);
    const [usersAnswer,setUserAnswer] = useState("")
    const [correctAnswer,setCorrectAnswer] = useState()
    const [answer,setAnswer] =useState([])
    const [isDisabled,setIsDisabled] = useState(true)


    const studentAns = usersAnswer && usersAnswer.split(",").map((element)=>{
        return question.questionData.select_answer_image.find(elem=>elem.index === element)
      })

    const correctAns = question?.questionData?.select_answer_image.filter((elem) => {
        if(question?.questionData?.correct_answer.indexOf(elem.index) !== -1) {
            return elem
        }
    })
    useEffect(()=>{
        setCorrectAnswer(correctAns);
    },[]);

    const onSelectImage = (e) => {
        if(e.target.checked) {
			setAnswer([...answer,e.target.value]);
		} else {
			const index=answer.indexOf(e.target.value);
			answer.splice(index,1);
		}
    }
    const handleSubmit = async() => {
        const correctAnswer = question?.questionData?.correct_answer
        setIsDisabled(false)
        if(answer == "") {
            toast.warning("Please, select answer");
            setIsDisabled(true)
            return 0
        }
        if(JSON.stringify(answer.sort()) === JSON.stringify(correctAnswer)){
            props?.handleSubmit(true,answer)
        } else {
            setIsAnswerWrong(true)
            setIsHideFooter(true)
            var data = {
                question_id : question.id,
                users_ans : answer.toString()
            }
            // var res = await postRequest("show-correct-answer",data)
            setUserAnswer(answer.toString())
            // props?.handleSubmit(false, answer)
        }
        setAnswer([])
        setIsDisabled(true)
    }
    const nextQuestion = () => {
        setIsAnswerWrong(false)
        setIsHideFooter(false)
        props?.handleSubmit(false, usersAnswer)
        setUserAnswer("")
    }

    useEffect(()=>{
        setIsHideFooter(props?.isHideFooter)
        setUserAnswer(props?.usersAnswer)
    },[])
    return (
        <div className='question-image-multi-image'>
            <div className='img-box'>
                <img src={question?.questionData?.question_image} alt="Question Image"/>
            </div>
            <div className='text-center py-4'>
                {
                    question.questionData.select_answer_image.map((elem, index) => (
                        <FormControlLabel
                            control={
                                <Checkbox onChange={onSelectImage} value={elem.index} key={index} checked={answer.includes(elem.index)?true:false}/>
                            }
                            label={
                                <div className='multi-img'>
                                    <img src={elem.images.replace('original', 'thumb')} key={index} />
                                </div>
                            }
                        />
                    ))
                }
            </div>
            {
                !isHideFooter &&
                <div className='questions-footer'>
                <TemplateFooter question={question}/>
                <div className='question-btn'>
                    <Link className="yellow-button" onClick={()=> isDisabled && handleSubmit()}>
                        Submit
                    </Link>
                </div>
                </div>
            } 
            {
            usersAnswer &&
            <div className='answer-info'>
                <div className='box'>
                    <span className='student'>{state.type == 3 || state.type == 4 ? "Children" : "Student"} Answer <img src={StudentImg} className="img-fluid"/></span>
                    <div className='img-sec'>
                    {
                    studentAns.map((elem,index)=>(
                        <>
                        <img  src={elem.images} key={index}/>
                        </>
                    ))
                    }
                    </div>
                </div>
                <div className='box'>
                    <span className='correct'>Correct Answer <img src={CheckImg} className="img-fluid"/></span>
                    <div className='img-sec'>
                    {
                    correctAnswer && correctAnswer.map((elem,index)=>(
                        <>
                        <img  src={elem.images} key={index}/>
                        </>
                    ))
                    }
                    </div>
                </div>
            </div>
            }
            <div className='explanation-info'>
            {
                    usersAnswer && isAnswerWrong &&
                    <div className='details'>
                        <span>Explanation</span>
                        {
                                question?.hintType == 0 ? 
                                    <p>{question.questionData.answer_hint}</p>
                                :
                                    <img src={question.questionData.answer_hint} className="img-fluid question-img" />
                        }                    
                        </div>
                }  
                {
                    isAnswerWrong && 
                    <div className='question-btn'>
                        <Link className="yellow-button" onClick={nextQuestion}>
                            Next
                        </Link>
                    </div>
                } 
            </div>
        </div>
    )
}

export default QuestionImageMultiImage