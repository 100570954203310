import React from 'react'
import BannerComponent from '../component/BannerComponent'
import ErrorImg from "../assets/images/not-found.png"

const NotFound = () => {
  return (
    <>
        <BannerComponent title="Error"/>
        <div className='container'>
          <div className='error-img text-center'>
            <img src={ErrorImg} alt="Error Image"/>
          </div>
        </div>
    </>
  )
}

export default NotFound