import BannerComponent from "../../component/BannerComponent";
import React, { useEffect, useState } from "react";
import { callApi } from "../../ApiCalls";
import Spinner from "../../component/Loader/Spinner"

const TermsOfUse = () => {
  const [resData, setResData] = useState([]);
  useEffect(() => {
    async function getData() {
      const termservicesData = await callApi("get-page/terms-of-use")
      setResData(termservicesData)
      }
    getData();
  }, []);
  return (
    <>
      {
        resData.data ? (
          <div className="terms-main">
            <BannerComponent bannerData={resData.data.pageData} />
            <div className="terms-details">
              <div className="container">
                <p dangerouslySetInnerHTML={ {__html: resData.data.pageData.pageTemplate} } />
              </div>
            </div>
          </div>
        ) : <Spinner/>
      }
    </>
  );
};

export default TermsOfUse;
