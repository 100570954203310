import React from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import * as Yup from "yup";
import { useFormik } from "formik";
import BannerComponent from "../../component/BannerComponent";
import forgotPassword from "../../../src/assets/images/reset-password.png";
import { callApi } from "../../ApiCalls";

const ForgotPassword = () => {
  const userSchema = Yup.object({
    email: Yup.string().email("Email must be a valid email").required("Email is required"),
  });
  const initialValues = {
    email: "",
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: userSchema,
      onSubmit: async (values, { resetForm }) => {
        const response = await callApi("forgot-password", values);
        if (response.statusCode == 200) {
          resetForm();
        }
      },
    });
  return (
    <>
      <BannerComponent title="Forgot Password" />
      <div className="sign-up-form-wraper">
        <div className="container">
          <div className="sign-in-form-wraper">
            <div className="sign-in-image">
              <img src={forgotPassword} />
            </div>
            <div className="common-form">
              <h2>Forgot Request</h2>
              <Form autoComplete="false" onSubmit={handleSubmit}>
                <Form.Group className="form-group full-fuied">
                  <div className="half-fuied">
                    <Form.Label>
                      Email<span>*</span>
                    </Form.Label>
                    <Form.Control type="email" name="email" placeholder="Enter Your Email Address" onChange={handleChange} value={values.email}/>
                    {
                      errors.email && touched.email && (
                        <Form.Text className="text-danger">
                          {errors.email}
                        </Form.Text>
                      )
                    }
                  </div>
                </Form.Group>
                <Button type="submit" className="yellow-button">
                  Submit
                </Button>
                <p>
                  <b>Educators:</b> Enter your e-mail address, and instructions
                  to reset your password will be sent to you.
                </p>
                <p>
                  <b>Students:</b> Please ask your teacher for your password.
                  Student passwords cannot be reset via e-mail.
                </p>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
