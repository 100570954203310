import React,{useContext} from 'react'
import { UserContext } from '../../App'
import ParentOverviewAssignment from "../Parent/OverviewAssignment"
import TeacherOverviewAssignment from "../Teacher/OverviewAssignment"

const OverviewAssignment = () => {

    const { state, dispatch } = useContext(UserContext);

    return (
        <>
            {
                state.type == 3 ? 
                    <ParentOverviewAssignment />
                :
                    <TeacherOverviewAssignment />
            }
        </>
    )
}

export default OverviewAssignment