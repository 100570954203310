import React,{useEffect,useState} from 'react'
import BannerComponent from '../../component/BannerComponent'
import { callApi, postRequest } from "../../ApiCalls";
import Spinner from "../../component/Loader/Spinner";
import {Row} from 'react-bootstrap';
import BadgeCard from '../../component/Card/BadgeCard';
import AssignmentCard from '../../component/Card/AssignmentCard';
import CategoryCard from '../../component/Card/CategoryCard';

const Home = () => {

  const [assignmentData,setAassignmentData] = useState([])
  const [categoryData,setCategoryData] = useState([])
  const [badgeData,setBadgeData] = useState([])

  useEffect(()=>{
    async function getData() {
      var userInfo = await callApi("my-account/get-account-details")
      var userId = localStorage.getItem("userId");
      var badgeData = await postRequest('assign-badge-list',{userId:userId});
      setBadgeData(badgeData.data)
      var assignmentData = await callApi("get-assignment-overview")
      setAassignmentData(assignmentData.data)
      var categoryData = await callApi(`get-category/${userInfo.data.gradeSlug}`);
      setCategoryData(categoryData)
    }
    getData()
  },[])
  return (
    <>
      {
        assignmentData && categoryData ? (
          <>
            <BannerComponent title="My Home"/>
            <section className='student-assignments-wrap'>
              <div className='container'>
                {
                  badgeData.length > 0 && 
                    <>
                      <div className="main-title">
                        <h2>My Badge</h2>
                      </div>
                      <Row className="flex-wrap">
                        {
                          badgeData.map((elem,index)=>(
                            <React.Fragment key={index}>
                              <BadgeCard data={elem}/>
                            </React.Fragment>
                          ))
                        }
                      </Row>
                    </>
                }
                {
                  assignmentData.length > 0 &&
                    <div className='box-wrap p-5'>
                      <div className="main-title">
                        <h2>My Assignments</h2>
                      </div>
                      <Row className='mb-5'>
                        {
                          assignmentData.map((elem,index)=>(
                            <React.Fragment key={index}>
                              <AssignmentCard assignmentData={elem}/>
                            </React.Fragment>
                          ))
                        }
                      </Row>
                    </div>
                }
                <div className='box-wrap'>
                  <div className="main-title">
                    <h2>Explore Topics</h2>
                  </div>
                  <div className='topic-lists'>
                    {
                     categoryData.data && categoryData.data.map((elem,index)=>(
                        <React.Fragment key={index}>
                          <CategoryCard categoryData={elem}/>
                        </React.Fragment>
                      ))
                    }                  
                  </div>
                </div>
              </div>
            </section>
          </>
        ) : (
          <Spinner/>
        )
      }
    </>
  )
}

export default Home