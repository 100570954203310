import React from 'react'
import { Chart } from "react-google-charts";

const GoogleChart = (props) => {
    const chartData = props?.chartData;
    const type = props?.type;
    const chartType = props?.chartType;
    const options = {
        title: "My Daily Activities",
        // pieHole: type? 0.4 : "",
    };
    return (
        <Chart
            chartType={chartType}
            data={chartData}
            // options={options}
            width={"100%"}
            height={"400px"}
        />
    )
}

export default GoogleChart