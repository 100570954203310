import React from 'react'
import Form from "react-bootstrap/Form";
import Input from '../Input';
import { dateConverter } from '../../ApiCalls';

const AccountInfo = (props) => {
    const {accountType,accountID,accountCreatedAt,accountLastRenewed,accountExpiration,timeZone} = props?.acDetails
    
    return (
        <div className="account-details">
            <Form autoComplete="false" className="row">
                <Form.Group className="form-group full-fuied col-lg-3 col-md-6"controlId="formBasicstudent">
                    <Form.Label>Account Type</Form.Label>
                    <Input
                        type={"text"}
                        name="accountType"
                        disabled={true}
                        value={accountType}
                    />
                </Form.Group>
                <Form.Group className="form-group full-fuied col-lg-3 col-md-6"controlId="formBasicstudent">
                    <Form.Label>Account ID</Form.Label>
                    <Input
                        type={"text"}
                        name="accountId"
                        disabled={true}
                        value={accountID}
                    />
                </Form.Group>
                <Form.Group className="form-group full-fuied col-lg-3 col-md-6" controlId="formBasicstudent">
                    <Form.Label>Account Created</Form.Label>
                    <Input
                        type={"text"}
                        name="accountCreated"
                        disabled={true}
                        value={dateConverter(accountCreatedAt)}
                    />
                    </Form.Group>
                <Form.Group className="form-group full-fuied col-lg-3 col-md-6" controlId="formBasicstudent">
                    <Form.Label>Account Last Renewed</Form.Label>
                    <Input
                        type={"text"}
                        name="accountRenewed"
                        disabled={true}
                        value={dateConverter(accountLastRenewed)}
                    />
                </Form.Group>
                <Form.Group className="form-group full-fuied col-lg-3 col-md-6" controlId="formBasicstudent">
                    <Form.Label>Account Expiration</Form.Label>
                    <Input
                        type={"text"}
                        name="accountExp"
                        disabled={true}
                        value={dateConverter(accountExpiration)}
                    />
                </Form.Group>
                {/* <Form.Group className="form-group full-fuied col-md-4" controlId="formBasicstudent">
                    <Form.Label>Time Zone</Form.Label>
                    <Form.Select name="no_child" className="selected">
                        <option >{timeZone}</option>
                        <option>(UTC + 5:30) Asia/Kolkata</option>
                        <option>(UTC + 5:30) Asia/Kolkata</option>
                        <option>(UTC + 5:30) Asia/Kolkata</option>
                    </Form.Select>
                </Form.Group> */}
            </Form>
        </div>
    )
}

export default AccountInfo