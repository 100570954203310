import React from 'react'
import Form from 'react-bootstrap/Form';

const Input = (props) => {
    const type = props?.type;
    const name = props?.name;
    const value = props?.value;
    const onChange = props?.onChange;
    const placeholder = props?.placeholder;
    const disabled = props?.disabled;
    const maxlength = props?.maxlength;

    return (
        <Form.Control type={type} name={name} value={value} onChange={onChange} placeholder={placeholder} disabled={disabled?true:false} maxLength={maxlength}/>
    )
}

export default Input