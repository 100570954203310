import React from 'react'
import MultiImageOptions from './Template/MultiImageOptions'
import ImageAndOptions from './Template/ImageAndOptions'
import StandardFillInput from './Template/StandardFillInput'
import QuestionImageMultiImage from './Template/QuestionImageMultiImage'
import MultiAnswerImageMultiCheckbox from './Template/MultiAnswerImageMultiCheckbox'
import SelectCheckBoxInput from './Template/SelectCheckBoxInput'
import QuestionImageAlphaNumericalSequence from './Template/QuestionImageAlphaNumericalSequence'
import MultiQuestionImageAnswerTextTrueFalse from './Template/MultiQuestionImageAnswerTextTrueFalse'
import MultiImageDragBox from './Template/MultiImageDragBox'
import MultiQuestionDragAnswerImage from './Template/MultiQuestionDragAnswerImage'
import QuestionOptionAbcd from './Template/QuestionOptionAbcd'
import QuestionAnswer from './Template/QuestionAnswer'
import QuestionSubQuestionsAnswers from './Template/QuestionSubQuestionsAnswers'
import QuestionImageOptionAbcd from './Template/QuestionImageOptionAbcd'
import QuestionImagePositionAbcd from "./Template/QuestionImagePositionAbcd"
import MultiQuestionTextDragAnswerImage from "./Template/MultiQuestionTextDragAnswerImage"
import MultiTextDragText from './Template/MultiTextDragText'
import MultiImageDragText from './Template/MultiImageDragText'
import MultiImageTwoDrag from './Template/MultiImageTwoDrag'
import MultiTextTwoDrag from './Template/MultiTextTwoDrag'
import MultiImageDragMultiSound from './Template/MultiImageDragMultiSound'
import QuestionSoundMultiImage from './Template/QuestionSoundMultiImage'
import MultiQuestionImageSoundTrueFalse from './Template/MultiQuestionImageSoundTrueFalse'
import ImageAndOptionsSound from './Template/ImageAndOptionsSound'

const MangeTemplate = (props) => {
    const questionData = props?.questionData
    const questionType = props?.questionData?.templateSlug
    const isHideFooter = props?.isHideFooter
    const usersAnswer = props?.usersAnswer

    return (
        <>
            {
                questionType == "multi-image-options" ? (
                    <MultiImageOptions question={questionData} handleSubmit={props?.handleSubmit} usersAnswer={usersAnswer} isHideFooter={isHideFooter}/>
                ) : questionType == "question-sound-multi-image" ? (
                    <QuestionSoundMultiImage question={questionData} handleSubmit={props?.handleSubmit} usersAnswer={usersAnswer} isHideFooter={isHideFooter}/>
                ) : questionType == "image-and-options" ? (
                    <ImageAndOptions question={questionData} handleSubmit={props?.handleSubmit} usersAnswer={usersAnswer} isHideFooter={isHideFooter}/>
                ) : questionType == "image-and-options-sound" ? (
                    <ImageAndOptionsSound question={questionData} handleSubmit={props?.handleSubmit} usersAnswer={usersAnswer} isHideFooter={isHideFooter}/>
                ) : questionType == "standard-fill-input" ? (
                    <StandardFillInput question={questionData} handleSubmit={props?.handleSubmit} usersAnswer={usersAnswer} isHideFooter={isHideFooter}/>
                ) : questionType == "question-image-multi-image" ? (
                    <QuestionImageMultiImage question={questionData} handleSubmit={props?.handleSubmit} usersAnswer={usersAnswer} isHideFooter={isHideFooter}/>
                ) : questionType == "multi-answer-image-multi-checkbox" ? (
                    <MultiAnswerImageMultiCheckbox question={questionData} handleSubmit={props?.handleSubmit} usersAnswer={usersAnswer} isHideFooter={isHideFooter}/>
                ) : questionType == "select-check-box-input" ? (
                    <SelectCheckBoxInput question={questionData} handleSubmit={props?.handleSubmit} usersAnswer={usersAnswer} isHideFooter={isHideFooter}/>
                ) : questionType == "question-image-alpha-numerical-sequence" ? (
                    <QuestionImageAlphaNumericalSequence question={questionData} handleSubmit={props?.handleSubmit} usersAnswer={usersAnswer} isHideFooter={isHideFooter}/>
                ) : questionType == "multi-question-image-answer-text-true-false" ? (
                    <MultiQuestionImageAnswerTextTrueFalse question={questionData} handleSubmit={props?.handleSubmit} usersAnswer={usersAnswer} isHideFooter={isHideFooter}/>
                ) : questionType == "multi-question-image-sound-true-false" ? (
                    <MultiQuestionImageSoundTrueFalse question={questionData} handleSubmit={props?.handleSubmit} usersAnswer={usersAnswer} isHideFooter={isHideFooter}/>
                ) : questionType == "multi-image-drag-box" ? (
                    <MultiImageDragBox question={questionData} handleSubmit={props?.handleSubmit} usersAnswer={usersAnswer} isHideFooter={isHideFooter}/>
                ) : questionType == "multi-question-drag-answer-image" ? (
                    <MultiQuestionDragAnswerImage question={questionData} checkAnswer={props?.checkAnswer} handleSubmit={props?.handleSubmit} usersAnswer={usersAnswer} isHideFooter={isHideFooter}/>
                ) : questionType == "multi-image-drag-multi-sound" ? (
                    <MultiImageDragMultiSound question={questionData} checkAnswer={props?.checkAnswer} handleSubmit={props?.handleSubmit} usersAnswer={usersAnswer} isHideFooter={isHideFooter}/>
                ) : questionType == "question-option-abcd" ? (
                    <QuestionOptionAbcd question={questionData} handleSubmit={props?.handleSubmit} usersAnswer={usersAnswer} isHideFooter={isHideFooter}/>
                ) : questionType == "question-answer" ? (
                    <QuestionAnswer question={questionData} handleSubmit={props?.handleSubmit} usersAnswer={usersAnswer} isHideFooter={isHideFooter}/>
                ) : questionType == "question-sub-questions-answers" ? (
                    <QuestionSubQuestionsAnswers question={questionData} handleSubmit={props?.handleSubmit} usersAnswer={usersAnswer} isHideFooter={isHideFooter}/>
                ) : questionType == "question-image-option-abcd" ? (
                    <QuestionImageOptionAbcd question={questionData} handleSubmit={props?.handleSubmit} usersAnswer={usersAnswer} isHideFooter={isHideFooter}/>
                ) : questionType == "question-image-position-abcd" ? (
                    <QuestionImagePositionAbcd question={questionData} handleSubmit={props?.handleSubmit} usersAnswer={usersAnswer} isHideFooter={isHideFooter}/>
                ): questionType == "multi-question-text-drag-answer-image" ? (
                    <MultiQuestionTextDragAnswerImage question={questionData} handleSubmit={props?.handleSubmit} usersAnswer={usersAnswer} isHideFooter={isHideFooter}/>
                ) : questionType == "multi-text-drag-text" ? (
                    <MultiTextDragText question={questionData} handleSubmit={props?.handleSubmit} usersAnswer={usersAnswer} isHideFooter={isHideFooter}/>
                ) : questionType == "multi-image-drag-text" ? (
                    <MultiImageDragText question={questionData} handleSubmit={props?.handleSubmit} usersAnswer={usersAnswer} isHideFooter={isHideFooter}/>
                ) : questionType == "multi-image-two-drag" ? (
                    <MultiImageTwoDrag question={questionData} handleSubmit={props?.handleSubmit} usersAnswer={usersAnswer} isHideFooter={isHideFooter}/>
                ) :  questionType == "multi-text-two-drag" ? (
                    <MultiTextTwoDrag question={questionData} handleSubmit={props?.handleSubmit} usersAnswer={usersAnswer} isHideFooter={isHideFooter}/>
                ) : (
                    <></>
                )
            }
        </>
    )
}

export default MangeTemplate