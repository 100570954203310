import React,{useState,useEffect} from 'react'
import {FormControlLabel,Checkbox} from '@mui/material';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import TemplateFooter from '../../TemplateFooter';
import { postRequest } from '../../../ApiCalls';
import StudentImg from "../../../assets/images/student.svg";
import CheckImg from "../../../assets/images/check.svg";
import { UserContext } from '../../../App';
import { useContext } from 'react';

const SelectCheckBoxInput = (props) => {
    const question = props?.question

    const { state, dispatch } = useContext(UserContext);
    const [isAnswerWrong,setIsAnswerWrong] = useState(false)
    const [isHideFooter,setIsHideFooter] = useState(false);
    const [usersAnswer,setUserAnswer] = useState("")
    const [correctAnswer,setCorrectAnswer] = useState()
    const [answer,setAnswer] = useState([])
    const [isDisabled,setIsDisabled] = useState(true)

    const studentAns = usersAnswer && question?.questionData?.options.filter((elem) => {
        if(usersAnswer.split(",").indexOf(elem.index) !== -1) {
            return elem 
        }
    })
    const correctAns = question?.questionData?.options.filter((elem) => {
        if(question?.questionData?.correct_answer.includes(elem.index)) {
            return elem
        }
    })

    // useEffect(()=>{
    //     setCorrectAnswer(correctAns);
    // },[]);

    const onSelect = (e) => {
        if(e.target.checked) {
			setAnswer([...answer,e.target.value]);
		} else {
			const index=answer.indexOf(e.target.value);
			answer.splice(index,1);
		}
    }
    const handleSubmit = async() => {
        const correctAnswer = question?.questionData?.correct_answer
        if(answer == "") {
            toast.warning("Please, select answer");
            setIsDisabled(true)
            return 0
        }
        if(JSON.stringify(answer.sort()) === JSON.stringify(correctAnswer)){
            props?.handleSubmit(true,answer)
        } else {
            setIsAnswerWrong(true)
            setIsHideFooter(true)
            var data = {
                question_id : question.id,
                users_ans : answer.toString()
            }
            // var res = await postRequest("show-correct-answer",data)
            setUserAnswer(answer.toString())
            // props?.handleSubmit(false, answer)
            setIsDisabled(true)
        }
        setAnswer([])
    }
    const nextQuestion = () => {
        setIsAnswerWrong(false)
        setIsHideFooter(false)
        props?.handleSubmit(false, usersAnswer)
        setUserAnswer("")
    }

    useEffect(()=>{
        setIsHideFooter(props?.isHideFooter)
        setUserAnswer(props?.usersAnswer)
        setCorrectAnswer(correctAns);
    },[question])
    return (
        <>
            <div className='select-checkbox-input text-center'>
                {question.questionData.options.map((elem, index) => (
                    <FormControlLabel
                        key={`${question?.id}_${index}`}
                        control={
                            <Checkbox onChange={onSelect} value={elem.index}  key={`${question?.id}_${index}`} checked={answer.includes(elem.index)?true:false}/>
                        }
                        label={
                            <>
                                <h4 className='m-0'>{elem.option}</h4>
                            </>
                        }
                    />
                ))}
            {
                !isHideFooter &&
                <div className='questions-footer'>
                    <TemplateFooter question={question}/>
                    <div className='question-btn'>
                        <Link className="yellow-button" onClick={()=> isDisabled && handleSubmit()}>
                            Submit
                        </Link>
                    </div>
                </div>
            } 
            {
                usersAnswer && 
                <div className='answer-info'>
                    <div className='box'>
                        <span className='student'>{state.type == 3 || state.type == 4 ? "Children" : "Student"} Answer <img src={StudentImg} className="img-fluid"/></span>
                        <h5>
                        {
                            studentAns.map((elem,index)=>(
                                <React.Fragment key={index}> 
                                {elem.option} {" "}
                            </React.Fragment>
                        )) 
                        }
                        </h5>
                    </div>
                    <div className='box'>
                        <span className='correct'>Correct Answer <img src={CheckImg} className="img-fluid"/></span>
                        <h5>
                        {
                            correctAnswer && correctAnswer.map((elem,index)=>(
                                <React.Fragment key={index}>
                                    {elem.option} {" "}
                                </React.Fragment>
                            ))
                        }
                        </h5>
                    </div>
                </div>
            }
            <div className='explanation-info'>
            {
                usersAnswer && isAnswerWrong &&
                <div className='details'>
                    <span>Explanation</span>
                    {
                        question?.hintType == 0 ? 
                            <p>{question.questionData.answer_hint}</p>
                        :
                            <img src={question.questionData.answer_hint} className="img-fluid question-img" />
                    }                
                    </div>
                }  
                {
                    isAnswerWrong && 
                    <div className='question-btn'>
                        <Link className="yellow-button" onClick={nextQuestion}>
                            Next
                        </Link>
                    </div>
                } 
            </div>
            </div>
        </>
    )
}

export default SelectCheckBoxInput