import React from 'react'
import { Link } from 'react-router-dom'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import BannerComponent from '../../component/BannerComponent'
import Subscribe from "../../assets/images/subscribe-icon.svg";
import HomePage from "../../assets/images/home-icon.svg";

const AccountExpire = () => {
    return (
      <>
        <BannerComponent title="Free Trial Expired"/>
        <section className="account-sec-wrap">
          <div className="container">
            <div className='section-title'>
              <h4>Sorry, your free trial has expired.</h4>
            </div>

            <div className="account-expire">
              <Row className="justify-content-md-evenly flex-wrap">
                <Col lg={3} md={5} xs={12}>
                  <Link variant="outline-primary" to="/renew">
                    <img src={Subscribe} className="img-fluid" />
                    <h4>Subscribe</h4>
                  </Link>
                </Col>

                <Col lg={3} md={5} xs={12}>
                  <Link variant="outline-primary" to="/">
                    <img src={HomePage} className="img-fluid" />
                    <h4>Home Page</h4>
                  </Link>
                </Col>
              </Row>
            </div>
          </div>
        </section>
      </>
    )
}

export default AccountExpire