import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import Table from "./Table/Table";

const Accordian = (props) => {

  const type = props?.type;
  const columns = props?.columns;
  const rowData= props?.rowData;
  const deleteRecord= props?.deleteRecord;
    
  return (
    <div className="common-accordion-repeat">
      <Accordion>
        <AccordionSummary
          // expandIcon={<AddIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>{props.title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            {
              type == "table" ? (
                  <Table rowData={rowData?.assignments} columns={columns} type="Individual assignment/s"/>
                ) : (
                <>
                  {props.content}
                </>
              )
            }
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default Accordian;
