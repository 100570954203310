import React, { useState,useContext,useEffect } from "react";
import "./Footer.css";
// images
import { UserContext } from "../../App";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Facebook from "../../assets/images/facebook.png";
import Twitter from "../../assets/images/twitter.png";
import Instagram from "../../assets/images/instagram.png";
import Pinterest from "../../assets/images/pinterest.png";
import Youtube from "../../assets/images/youtube.png";
import { ModalComponent } from "../ModalComponent";
import { callApi } from "../../ApiCalls";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import * as Yup from "yup";
import { useFormik } from "formik";
import swal from "sweetalert";

const Footer = (props) => {
	const data = props.footerData[0];
	const { state, dispatch } = useContext(UserContext);
	const [menuClick, setMenuClick] = useState();
	const [show, setShow] = useState(false);
	const navigate = useNavigate();
 	const [standards,setStandards] = useState()

	const handleClose = () => {
		setShow(false);
	};
	const handleModal = () => {
		setShow(true);
	};

	const userSchema = Yup.object({
		email: Yup.string().email("Email must be a valid email").required("Email is required"),
	});
	const initialValues = {
		email : "",
	};
	const { values, touched, errors, handleChange, handleSubmit } = useFormik({
		initialValues: initialValues,
		validationSchema: userSchema,
		onSubmit: async (values, { resetForm }) => {
			var response = await callApi('subscribers',values);
			if (response.statusCode == 200) {
				resetForm();
			}
		},
	});

	const date = new Date();
	let year = date.getFullYear();

	const logout = async () => {
		swal({
            title: "Are you sure, You want to Logout?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
        .then(async(willDelete) => {
            if (willDelete) {
                const response = await callApi("logout",{});
			if (response.statusCode == 200) {
				navigate("/");
				dispatch({ type: "USER", payload: null });
				localStorage.clear();
			}
            } 
        });
		
	};

	const location = useLocation()
	useEffect(()=>{
		setMenuClick(location.pathname)
	},[location])

	useEffect(()=>{
		async function getStandards() {
			var res = await callApi("get-standard-info");
			setStandards(res.data)
	  	}
	  	getStandards()
	},[])
	
	return (
		<>
			<footer className={state.type != null ? "after-login" : ""}>
				<div className="container">
					{
						!state.type && (
							<div
							className="footer-sign-up"
							style={{ backgroundImage: `url(${data.sectionBackgroundImage})` }}
							>
								<h3>{data.sectionTitle}</h3>
								<p>{data.sectionContent}</p>
								<Link to="/signup" className="yellow-button">
									{data.sectionBtnText}
								</Link>
							</div>
						)
					}
					<div className="footer-wrap">
						<div className="footer-left">
							<Link to="/" className="navbar-brand" >
								<img src={data.footerLogo} alt="" onClick={()=>setMenuClick(10)}/>
							</Link>
							<p>{data.websiteDescription}</p>
						</div>
						<div className="footer-right">
							{
								state.type != null && (
									<ul>
										<li>
											<strong className="blue">My Account</strong>
										</li>
										{
											(state.type == 3 || state.type == 5) && (
												<>
													<li>
														<Link className={ menuClick === "/my-account" ? "nav-link active" : "nav-link"} onClick={() => setMenuClick("/my-account")} to="/my-account">
															My Account
														</Link>
													</li>
													<li>
													{
														state.type == 3 ? (
															<>
																<Link className={menuClick === "/my-children" ? "nav-link active" : "nav-link"} onClick={() => setMenuClick("/my-children")} to="/my-children">
																	My Children
																</Link>
															</>
														) : ( 
															<>
																<Link className={menuClick === "/my-student" ? "nav-link active" : "nav-link" } onClick={() => setMenuClick("/my-student")} to="/my-student">
																	My Student
																</Link>
															</>
														)
													}
													</li>
													<li>
														<Link className={menuClick === "/overview-assignment" ? "nav-link active" : menuClick === "/individual-assignment" ? "nav-link active" : "nav-link"} onClick={() => {setMenuClick("assignment");handleModal("assignment")}}>
															Assignment
														</Link>
													</li>
													<li>
														<Link className={menuClick === "/progress" ? "nav-link active" : "nav-link"} onClick={() => setMenuClick("/progress")} to="/progress">
															Progress
														</Link>
													</li>
													<li>
														<Link className={menuClick === "/renew" ? "nav-link active" : "nav-link"} onClick={() => setMenuClick("/renew")} to="/renew">
															Renew
														</Link>
													</li>
												</>
											)
										}
										{
											(state.type == 4 || state.type == 6) && (
												<>
													<li>
														<Link className={menuClick === "/my-setting" ? "nav-link active" : "nav-link"} onClick={() => setMenuClick("/my-setting")} to="/my-setting">
															My Setting
														</Link>
													</li>
													<li>
														<Link className={menuClick === "/my-progress" ? "nav-link active" : "nav-link"} onClick={() => setMenuClick("/my-progress")} to="/my-progress">
															My Progress
														</Link>
													</li>
												</>
											)
										}
										<li>
											<Link className={menuClick === "/logout" ? "nav-link active" : "nav-link" } onClick={logout} >
												Logout
											</Link>
										</li>
									</ul>
								)
							}
							{ state.type == null && (
							<ul>
								<li>
									<strong className="blue">Quick Links</strong>
								</li>
								<li>
									<Link className={menuClick === "/" ? "nav-link active" : "nav-link"} onClick={() => setMenuClick("/")} to="/" >
										Home
									</Link>
								</li>
								<li>
									<Link className={ menuClick === "/about" ? "nav-link active" : "nav-link" } onClick={() => setMenuClick("/about")} to="/about">
										About Us
									</Link>
								</li>
								<li>
									<Link className={menuClick === "/testimonial" ? "nav-link active" : "nav-link"} onClick={() => setMenuClick("/testimonial")} to="/testimonial" >
										Testimonials
									</Link>
								</li>
								<li>
									<Link className={menuClick === "/pricing" ? "nav-link active" : "nav-link"} onClick={() => setMenuClick("/pricing")} to="/pricing">
										Pricing
									</Link>
								</li>
								
								<li>
									<Link
									className={menuClick === "/contactUs" ? "nav-link active" : "nav-link"} onClick={() => setMenuClick("/contactUs")} to="/contactUs">
										Contact Us
									</Link>
								</li>
								{/* <li>
									<Link
										className={
											menuClick === "/schoolLicenseInfo" ? "nav-link active" : "nav-link"
										}
										onClick={() => setMenuClick("/schoolLicenseInfo")}
										to="/schoolLicenseInfo"
									>
										School License Info
									</Link>
								</li>
								<li>
									<Link
										className={
											menuClick === "/standards" ? "nav-link active" : "nav-link"
										}
										onClick={() => setMenuClick("/standards")}
										to="/standards"
									>
										Standards
									</Link>
								</li> */}
							</ul>
							)}
							<ul>
								<li>
									<strong className="purple">Features</strong>
								</li>
								<li>
									<Link
									className={
										menuClick === "/blog" ? "nav-link active" : "nav-link"
									}
									onClick={() => setMenuClick("/blog")}
									to="/blog"
									>
										Blog
									</Link>
								</li>
								<li>
									<Link className={menuClick === "/FAQ" ? "nav-link active" : "nav-link"} onClick={() => setMenuClick("/FAQ")} to="/FAQ">
										FAQs
									</Link>
								</li>
								{/* <li>
									<Link className={menuClick === "/sponsor" ? "nav-link active":"nav-link"} onClick={() => setMenuClick("/sponsor")} to="/sponsor">
										Sponsor
									</Link>
								</li>
								<li>
									<Link className={menuClick === "/partner" ? "nav-link active" : "nav-link"} onClick={() => setMenuClick("/partner")} to="/partner">
										Partner
									</Link>
								</li>
								<li>
								{state.type && (
									<>
									<Link className={menuClick === "/awards" ? "nav-link active" : "nav-link"} onClick={() => setMenuClick("/awards")} to="/awards">
										Awards
									</Link>
									</>
								)}
								</li> */}
								<li>
									<Link className={menuClick === "/privacy-policy" ? "nav-link active" : "nav-link"} onClick={() => setMenuClick("/privacy-policy")} to="/privacy-policy">
										Privacy Policy
									</Link>
								</li>
								<li>
									<Link className={menuClick === "/terms-of-use" ? "nav-link active" : "nav-link"} onClick={() => setMenuClick("/terms-of-use")} to="/terms-of-use">
										Terms of Use
									</Link>
								</li>
								{
									standards?.length > 0 && (
										<li className="standards-link">								
											<span>Standards</span>
												<div className="link-group">
													({
														standards.map((elem,index)=>(
															<React.Fragment key={index}>
																<Link onClick={() => setMenuClick(window.open(elem.file,"__blank"))} title={elem.description}>
																{(index ? ', ' :  '') + elem.title}
																</Link>
														    </React.Fragment>
														    ))
													})
												</div>
										</li>
									)
								}
								{/* <li>
									<Link
									className={
										menuClick === "/newsLetter" ? "nav-link active" : "nav-link"
									}
									onClick={() => setMenuClick("/newsLetter")}
									to="/newsLetter"
									>
										Newsletter
									</Link>
								</li>
								<li>
									<Link
									className={
										menuClick === "/monthlyContest" ? "nav-link active" : "nav-link"
									}
									onClick={() => setMenuClick("/monthlyContest")}
									to="/monthlyContest"
									>
										Monthly Contest
									</Link>
								</li> */}
							</ul>
						</div>		

						<div className="footer-subscribe">							
							<h4>Subscribe to our newsletter</h4>
							<Form autoComplete="false" onSubmit={handleSubmit}>
								<Form.Group className="form-group full-fuied">
									<Form.Control type="email" placeholder="Email Address" name="email" value={values.email} onChange={handleChange}/>
									{errors.email && touched.email && (
										<Form.Text className="text-danger">
											{errors.email}
										</Form.Text>
									)}
								</Form.Group>

								<Button
									variant="primary"
									type="submit"
									className="yellow-button"
									>
									Subscribe
								</Button>
							</Form>
						</div>				
					</div>
					<div className="footer-social-media text-center d-flex flex-wrap align-items-center justify-content-between">
						<span>Copyright © {year} iSoar Math. All rights reserved.</span>
						<ul className="header-social-media">
							<li>
								<a href={data.facebook} target="_blank">
									<img src={Facebook} />
								</a>
							</li>
							<li>
								<a href={data.twitter} target="_blank">
									<img src={Twitter} />
								</a>
							</li>
							<li>
								<a href={data.instagram} target="_blank">
									<img src={Instagram} />
								</a>
							</li>
							<li>
								<a href={data.pinterest} target="_blank">
									<img src={Pinterest} />
								</a>
							</li>
							<li>
								<a href={data.youtube} target="_blank">
									<img src={Youtube} />
								</a>
							</li>
						</ul>
					</div>
				</div>
			</footer>
			{
				show && <ModalComponent show={show} handleClose={handleClose} type={"Assignments"} className="assignment" title="Assignments" />
			}
		</>
	);
};

export default Footer;
