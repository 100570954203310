import { Link } from "react-router-dom";
import DataBlog from "../../../assets/images/calender.png";
import BlogCommnet from "../../../assets/images/comment.png";
import { dateConverter } from "../../../ApiCalls";

export const NewsAndBlogCard = (props) => {
  const blogData = props.newsAndBlogData;
  return (
    <li>
      <div className="blog-image">
        <img src={blogData.postBanner} />
      </div>
      <div className="data-comments">
        <div className="blog-date">
          <img src={DataBlog} />
          <span>{dateConverter(blogData.createdAt)}</span>
        </div>
        {/* <div className="commente">
          <img src={BlogCommnet} />
          <span>Comment ({blogData.commentCount})</span>
        </div> */}
      </div>
      <div className="blog-detail">
        <p dangerouslySetInnerHTML={{ __html: blogData.shortContent }} />
      </div>
      <div className="blog-btn">
        <Link className="yellow-button" to={"/blogdetail/" + blogData.postSlug}>
          Read More
        </Link>
      </div>
    </li>
  );
};
