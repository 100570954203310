import React, { useState, useContext,useEffect } from "react";
import "./Header.css";
// images
import Facebook from "../../assets/images/facebook.png";
import Twitter from "../../assets/images/twitter.png";
import Instagram from "../../assets/images/instagram.png";
import Pinterest from "../../assets/images/pinterest.png";
import Search from "../../assets/images/search.png";
import Frame from "../../assets/images/Frame.png";

import swal from "sweetalert";
import { UserContext } from "../../App";
import { ModalComponent } from "../ModalComponent";
import { useNavigate, useLocation,Link } from "react-router-dom";
import { callApi } from "../../ApiCalls";
import { Button, Form } from "react-bootstrap";

const Header = (props) => {
    const data = props.headerData[0];

    const { state, dispatch } = useContext(UserContext);
    const navigate = useNavigate();

    const [show, setShow] = useState(false);
    const [isLogin, setIsLogin] = useState(false);
    const [isAssignment, setIsAssignment] = useState(false);
    const [changeUser, setChangeUser] = useState(false);
    const [menuClick, setMenuClick] = useState();
    const [search,setSearch] = useState("");
    const [userData,setUserData] = useState([]);

    const handleClose = () => {
        setShow(false);
        setIsLogin(false);
        setIsAssignment(false);
        setChangeUser(false)
    };

    const handleModal = (type) => {
        setShow(true);
        if (type === "login") 
            setIsLogin(true);
        else if (type === "changeUser")
            setChangeUser(true)
        else 
            setIsAssignment(true);
    };

    const logout = async () => {
        swal({
            title: "Are you sure, You want to Logout?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
        .then(async(willDelete) => {
            if (willDelete) {
                const response = await callApi("logout",{});
                if (response.statusCode == 200) {
                    dispatch({ type: "USER", payload: null });
                    localStorage.clear();
                    navigate("/");
                }
            } 
        });
    };
    const submitHandler = async(e) => {
        e.preventDefault()
        if(search.length > 0) {
            navigate({
                pathname: "/grade-lesson-search",
                search: `?search=${search}`,
            });
            setSearch("")
        }
    }

    const renewPlan = async() => {
        var res = await callApi("get-plans-details")
        swal({
            title: res.message,
            icon: "warning",
            buttons: true,
            dangerMode: true,
            html: true,
            //className : ["renew-plan-alert"]
        })
        .then(async(willDelete) => {
            if (willDelete) {
                navigate("/renew");
            } 
        });
    }

    const location = useLocation()
    useEffect(()=>{
        setMenuClick(location.pathname)
    },[location])
    
    useEffect(()=>{
        async function getData(){
            var userData = await callApi("my-account/get-account-details")
            setUserData(userData.data)
        }
        if(state.type){
            getData()
        }
    },[state.type,state.avatarImage])

    return (
    <>
        <header>
            <nav className="navbar navbar-expand-lg navbar-light ">
                <div className="header-top-main">
                    <div className="container">
                        <div className="header-top">
                            <ul className="header-social-media">
                                <li>
                                    <a href={data.facebook}>
                                        <img src={Facebook} />
                                    </a>
                                </li>
                                <li>
                                    <a href={data.twitter}>
                                        <img src={Twitter} />
                                    </a>
                                </li>
                                <li>
                                    <a href={data.instagram}>
                                        <img src={Instagram} />
                                    </a>
                                </li>
                                <li>
                                    <a href={data.pintrest}>
                                        <img src={Pinterest} />
                                    </a>
                                </li>
                            </ul>
            
                            <Form autoComplete="false" onSubmit={submitHandler}>
                                <Form.Group >
                                    <input className="form-control mr-sm-2" type="search" placeholder="Search Lessons" value={search} onChange={(e)=>setSearch(e.target.value)} />
                                    <button className="btn btn-outline-success my-2 my-sm-0" type="submit"><img src={Search} /></button>
                                </Form.Group>
                            </Form>
                            {
                                ((state.type == 3 || state.type == 4 || state.type == 5 || state.type == 6) && state.isExpired != 1) && (
                                    <>
                                        <ul className="header-top-buttons">
                                            {
                                                (state.type == 3 || state.type == 5) && (
                                                    <li>
                                                        <Link onClick={renewPlan} className="yellow-button">Renew</Link>
                                                    </li>
                                                )
                                            }
                                            {
                                                (state.type == 3 || state.type == 4) && 
                                                    <li className="change-button">
                                                        <Link onClick={()=>handleModal("changeUser")} style={{ backgroundImage: `url(${Frame})` }} variant="secondary" >Change User</Link>
                                                    </li>
                                            }
                                            <li >
                                                <Link style={{ backgroundImage: `url(${Frame})` }} onClick={logout}>Logout</Link>
                                            </li>
                                        </ul>
                                    </>
                                ) 
                            }
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="header-button">
                        <Link to="/" className="navbar-brand" >
                            <img src={data.headerLogo} className="img-fluid" alt="" onClick={()=>setMenuClick(10)}/>
                        </Link>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                    
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <div className="menu-wraper">
                                <ul className="navbar-nav mr-auto align-items-lg-center mobile-menu">
                                    {
                                        (state.type != null && state.isExpired != 1) ? (
                                            state.type == 3 ? (
                                                <>
                                                    <li className="nav-item active">                        
                                                        <Link className={`nav-link ${menuClick === "/my-account" && "active"}`}  onClick={()  => setMenuClick("/my-account")} to="/my-account">My Account</Link>
                                                    </li>
                                                    <li className="nav-item">
                                                        <Link className={`nav-link ${menuClick === "/my-children" && "active"}`}  onClick={() => setMenuClick("/my-children")} to="/my-children">My Children</Link>
                                                    </li>
                                                    <li className="nav-item">
                                                        <Button className={`nav-link-btn ${menuClick === "/overview-assignment" ? "active" : menuClick === "/individual-assignment" ? "active" : "inactive"}`}  onClick={() => {handleModal("assignment")}} >Assignments</Button>
                                                    </li>
                                                    <li className="nav-item">
                                                        <Link className={`nav-link ${menuClick === "/progress" ? "active" : menuClick === "/progress/recent-activity-progress" ? "active" : menuClick === "/progress/individual-progress" ? "active" : menuClick === "/progress/assignment-progress" ? "active" : menuClick === "/progress/printable-progress-report" ? "active" : "inactive"}`}  onClick={() => setMenuClick("/progress")} to="/progress">Progress</Link>
                                                    </li>
                                                    {
                                                        state.isPaid == 1 && (
                                                            <li className="nav-item">
                                                                <Link className={`nav-link ${menuClick === "/worksheet" && "active"}`}  onClick={()  => setMenuClick("/worksheet")} to="/worksheet">Worksheet</Link>
                                                            </li>
                                                        )
                                                    }
                                                </>
                                            ) :  state.type == 5 ? (
                                                <>
                                                    <li className="nav-item active">                        
                                                        <Link className={`nav-link ${menuClick === "/my-account" && "active"}`}  onClick={()  => setMenuClick("/my-account")} to="/my-account">My Account</Link>
                                                    </li>
                                                    <li className="nav-item">
                                                        <Link className={`nav-link ${menuClick === "/my-student" && "active"}`}  onClick={() => setMenuClick("/my-student")} to="/my-student">My Students</Link>
                                                    </li>
                                                    <li className="nav-item">
                                                        <Button className={`nav-link-btn ${menuClick === "/overview-assignment" ? "active" : menuClick === "/individual-assignment" ? "active" : "inactive"}`}  onClick={() => {handleModal("assignment")}} >Assignments</Button>
                                                    </li>
                                                    <li className="nav-item">
                                                        <Link className={`nav-link ${menuClick === "/progress" ? "active" : menuClick === "/progress/recent-activity-progress" ? "active" : menuClick === "/progress/individual-progress" ? "active" : menuClick === "/progress/assignment-progress" ? "active" : menuClick === "/progress/printable-progress-report" ? "active" : "inactive"}`}  onClick={() => setMenuClick("/progress")} to="/progress">Progress</Link>
                                                    </li>
                                                    {
                                                        state.isPaid == 1 && (
                                                            <li className="nav-item">
                                                                <Link className={`nav-link ${menuClick === "/worksheet" && "active"}`}  onClick={()  => setMenuClick("/worksheet")} to="/worksheet">Worksheets</Link>
                                                            </li>
                                                        )
                                                    }
                                                </>
                                            ) :
                                             (
                                                <>
                                                    <li className="nav-item active">                        
                                                        <Link className={`nav-link ${menuClick === "/" && "active"}`}  onClick={()  => setMenuClick("/")} to="/">My Home</Link>
                                                    </li>
                                                    <li className="nav-item">
                                                        <Link className={`nav-link ${menuClick === "/my-setting" && "active"}`}  onClick={()  => setMenuClick("/my-setting")} to="/my-setting">My Setting</Link>
                                                    </li>
                                                    <li className="nav-item active">                        
                                                        <Link className={`nav-link ${menuClick === "/my-progress" && "active"}`}  onClick={() => setMenuClick("/my-progress")} to="/my-progress">My Progress</Link>
                                                    </li>
                                                    {
                                                        state.isPaid == 1 && (
                                                            <li className="nav-item">
                                                                <Link className={`nav-link ${menuClick === "/worksheet" && "active"}`}  onClick={()  => setMenuClick("/worksheet")} to="/worksheet">Worksheet</Link>
                                                            </li>
                                                        )
                                                    }
                                                </>
                                            )
                                        ) 
                                        : (
                                            <>
                                                <li className="nav-item active">                        
                                                    <Link className={`nav-link ${menuClick === "/" && "active"}`}  onClick={()  => setMenuClick("/")} to="/">Home</Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link className={`nav-link ${menuClick === "/about" && "active"}`}  onClick={() => setMenuClick("/about")} to="/about">About Us</Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link className={`nav-link ${menuClick === "/FAQ" && "active"}`}  onClick={() => setMenuClick("/FAQ")} to="/FAQ">FAQ</Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link className={`nav-link ${menuClick === "/pricing" && "active"}`}  onClick={() => setMenuClick("/pricing")} to="/pricing">Pricing</Link>
                                                </li>
                                            </>
                                        )
                                    }
                                </ul>
                                {
                                    (!state.type || state.isExpired == 1) && (
                                        <ul className="header-buttons">
                                            <li className="header-buttons">
                                                <Link to="/signup" style={{ backgroundImage: `url(${Frame})` }} variant="secondary" >Sign Up</Link>
                                            </li>
                                            <li >
                                                <Link style={{ backgroundImage: `url(${Frame})` }} onClick={()=>handleModal("login")}>Sign In</Link>
                                            </li>  
                                        </ul>
                                    )
                                }
                                {
                                    state.type && state.isExpired != 1 && ( 
                                        <Link className="logedin" to={ state.type == 5 || state.type == 3 ? "/my-account" : "/"}>
                                            <span>Hi, {userData.firstName}</span>
                                            <img src={userData.avtar} />
                                        </Link>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </header>
        {
            show && isLogin ? 
                <ModalComponent show={show} handleClose={handleClose} type={"Login"} title="Login"/>
            : show && changeUser ?
                <ModalComponent show={show} handleClose={handleClose} type={"changeUser"} className="changeUser" title="Change User" />
            : show && isAssignment ?
                <ModalComponent show={show} handleClose={handleClose} type={"Assignments"} className="assignment" title="Assignments" />
            : ""
        }
    </>
  );
};

export default Header;
