import React from 'react'
import { Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const CategoryCard = (props) => {

    const {gradeSlug,id,image,name} = props?.categoryData
    return (
        <Link to={`/${gradeSlug}/${id}`} className="sub-category">
            <Card border="primary">
                <div className="img-wrap">
                    <Card.Img variant="left" src={image} />
                </div>
                <Card.Body>
                    <Card.Title>{name}</Card.Title>
                </Card.Body>
            </Card>
        </Link> 
    )
}

export default CategoryCard