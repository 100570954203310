import React from "react";
import "./NewsAndBlog.css";
import { NewsAndBlogCard } from "./NewsAndBlogCard";

export const NewsAndBlog = (props) => {
  const blog = props.newsAndBlogData.data
  return (
    <>
      {
        blog.length > 0 && (
          <section className="latest-blog">
            <div className="container">
              <div className="main-title">
                <h2>Latest News & Blog</h2>
              </div>
              <div className="latest-blog-wrap">
                <ul>
                  {blog.map((elem, index) => (
                    <React.Fragment key={index}>
                      <NewsAndBlogCard newsAndBlogData={elem} />
                    </React.Fragment>
                  ))}
                </ul>
              </div>
            </div>
          </section>
        )
      }
    </>
  );
};
