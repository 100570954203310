import React,{useState ,useEffect} from 'react'
import { callApi } from '../../ApiCalls'
import Spinner from '../Loader/Spinner'
import {Row,Col,Button} from 'react-bootstrap';
import swal from 'sweetalert';
import { Link,useNavigate } from "react-router-dom";

const Certificate = (props) => {

    const navigate = useNavigate();

    const assignmentId = props?.infoData;
    const userId = props?.userData;
    const title = props?.title;

    const [badgeCertificateData,setBadgeCertificateData] = useState([])
    const [isDisable, setIsDisable] = useState(false);
    const [certificateId,setCertificateId] = useState("")
    
    const assignCertificate = async(certificateId,certificateImage) => {
        setIsDisable(true)
        setCertificateId(certificateId)
        swal({
            title: "Are you sure, You want to assign Certificate ?",
            // icon: "info",
            buttons: true,
            dangerMode: true,
        })
        .then(async(willDelete) => {
            if (willDelete) {
                 var data = {
                    certificateId : certificateId,
                    assignmentId : assignmentId,
                    userId : userId
                }
                navigate('/certificate',{state : {certificateId:certificateId,assignmentId:assignmentId,certificateImage:certificateImage,userId:userId}})
                // var response = await callApi("assign-badge",data)
                // if (response.statusCode == 200) {
                //     props.handleClose();
                //     setIsDisable(false)
                // }
            } 
        });
    }

    useEffect(()=>{
        async function getBadgeData(){
            const response = await callApi("badge-list")
            setBadgeCertificateData(response)
        }
        async function getCertificateData() {
            const response = await callApi("teacher/all-certificates")
            setBadgeCertificateData(response)
        }
        if(props?.title == "Certificate")
            getCertificateData()
        else 
            getBadgeData()
    },[])
    return (
        <>
            {
                badgeCertificateData.data ? (
                    <Row className='certificate-wrapper'>
                        {
                            badgeCertificateData.data.map((elem,index)=>(  
                                <Col lg={3} md={6} xs={12} key={index}>
                                    <div className={`img-box ${certificateId == elem.certificateId ? "active" : ""}`} onClick={()=>assignCertificate(elem.certificateId,elem.certificateImage)}>
                                        <div className='box'>
                                            <img src={elem.certificateThumbImage} alt="certificate image"/>
                                        </div>
                                        <h4>{elem.certificateTitle}</h4>
                                    </div>
                                </Col> 
                            ))
                        }
                    </Row>
                ) : (
                    <Spinner />
                )
            }
        </>
    )
}

export default Certificate