import { FormGroup, Switch } from '@mui/material'
import React,{useEffect, useState} from 'react'
import { Form } from 'react-bootstrap'
import axios from 'axios'
import Spinner from '../Loader/Spinner'
import environment from '../../environment';
const EmailSetting = (props) => {

    const [emailSetting, setEmailSetting] = useState([])
    const [loader,setLoader] = useState(false)
    
    const handleEmailSettingChange = (e,type) => {
      var {name,value} = e.target
      name = type
      var newVal = 0
      if(type == "emailAnnouncements" ) {
        if(emailSetting.emailAnnouncements == 1)
          newVal = 0
        else 
          newVal = 1
      } else if(type == "newsletters") {
        if(emailSetting.newsletters == 1)
          newVal = 0
        else 
          newVal = 1
      } else if(type == "renewalReminders") {
        if(emailSetting.renewalReminders == 1)
          newVal = 0
        else 
          newVal = 1
      } else {
        if(emailSetting.weeklyUpdates == 1)
          newVal = 0
        else 
          newVal = 1
      }
      value = newVal
      setEmailSetting((preval)=>{
        return {...preval,[name] : value}
      })
      var data = {
        [name] : value
      }
      var token = localStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      };
      axios.post(environment.REACT_APP_API_URL + "my-account/email-setting-update", data, {
        headers: headers,
      })
    }
    
    useEffect(()=>{
      setTimeout(() => {
        setEmailSetting(props?.emailSetting)
        setLoader(true)
      }, 500);
    },[])

    return (
      <div className="account-details">
        {
          loader ? (
            <Form>
              {/* <div className=" email-setting">
                <div className="email-setting-content ">
                  <strong>E-mail Announcements</strong>
                  <p>
                    {" "}
                    We occasionally send out website-related
                    announcements and news. We also send happy birthday
                    messages if you've entered your birthday in the
                    account settings section.
                  </p>
                </div>
                <FormGroup className="text-right switch">
                  <Switch checked={emailSetting.emailAnnouncements == 1 ? false:true} onChange={(e)=>handleEmailSettingChange(e,"emailAnnouncements")} />
                </FormGroup>
              </div> */}
              <div className=" email-setting">
                <div className="email-setting-content ">
                  <strong>Newsletters</strong>
                  <p>
                    {" "}
                    We'll send out a monthly newsletter with articles and information about our site.
                  </p>
                </div>
                <FormGroup className="text-right switch">
                  <Switch checked={emailSetting.newsletters == 1 ? false:true} onChange={(e)=>handleEmailSettingChange(e,"newsletters")} />
                </FormGroup>
              </div>
              <div className=" email-setting">
                <div className="email-setting-content ">
                  <strong>Renewal Reminders</strong>
                  <p>
                    {" "}
                    We'll send you reminders when your membership plan expiration date is approaching. Reminders are typically sent 7 days before your expiration date. We may also send one about 1 week after your account has expired.
                  </p>
                </div>
                <FormGroup className="text-right switch">
                  <Switch checked={emailSetting.renewalReminders == 1 ? false:true} onChange={(e)=>handleEmailSettingChange(e,"renewalReminders")} />
                </FormGroup>
              </div>
              <div className=" email-setting">
                <div className="email-setting-content ">
                  <strong>Weekly Updates</strong>
                  <p>
                    {" "}
                    We'll send out a weekly report with information about your child's progress.
                  </p>
                </div>
                <FormGroup className="text-right switch">
                  <Switch checked={emailSetting.weeklyUpdates == 1 ? false:true} onChange={(e)=>handleEmailSettingChange(e,"weeklyUpdates")} />
                </FormGroup>
              </div>
            </Form>
          ) : <Spinner/>
        }
      </div>
    )
}

export default EmailSetting