import React,{useEffect,useState} from 'react'
import { Row } from 'react-bootstrap'
import { postRequest } from '../../ApiCalls'
import BadgeCard from '../Card/BadgeCard'
import Spinner from '../Loader/Spinner'

const Badge = (props) => {
    const [badgeData,setBadgeData] = useState([])
    const [loader,setLoader] = useState(false)

    useEffect(()=>{
        async function getBadgeData () {
            var badgeData = await postRequest('assign-badge-list',{userId: props?.userData});
            setBadgeData(badgeData.data)
            setLoader(true)
        }
        getBadgeData()
    },[])

    return (
        <>
            {
                loader ? (
                    <Row className='student-assignments-wrap p-0'>
                        {
                            badgeData.length > 0 ? badgeData.map((elem,index)=>(  
                                <React.Fragment>
                                    <BadgeCard data={elem}/>
                                </React.Fragment>
                            ))
                            :
                                <center>No Badge Found</center>
                        }
                    </Row>
                ) : <Spinner/>
            }
        </>
    )
}

export default Badge