import React, { useEffect, useState } from "react";
import BannerComponent from "../component/BannerComponent";
import { useLocation } from "react-router-dom";
import { callApi } from "../ApiCalls";
import Spinner from "../component/Loader/Spinner";
import { GradeCard } from "../component/Home/HomeSection/GradeCard";
import CategoryCard from "../component/Card/CategoryCard";

const Grade = () => {
  
  const [resData, setResData] = useState([]);
  const [grade,setGrade] = useState([])
  const [gradeData, setGradeData] = useState([]);

  const location = useLocation();
 
  useEffect(() => {
    async function getData() {
      var grade = location.pathname.substring(location.pathname.lastIndexOf('/') + 1)
      setGrade(grade)
      const res = await callApi(`get-category/${grade}`);
      setResData(res);
      const gradeData = await callApi("get-only-grades");
      setGradeData(gradeData);
    }
    getData();
  }, [location]);
  return (
    <>
      {
        resData.data && gradeData.data ? (
          <div className="class-tab-main">
            <BannerComponent title={grade}/>
            <div className="common-lessions-section">
              <div className="inner-menu p-0 mb-5">
                <div className="container">
                  <ul className="hero-menu">
                    {gradeData.data.map((elem, index) => (
                      <React.Fragment key={index}>
                        <GradeCard elem={elem} />
                      </React.Fragment>
                    ))}
                  </ul>
                </div>
              </div>

              <div className="container">
                {
                  resData.data && resData.data.length > 0 ? (
                    <>
                      {resData.data.map((elem, index) => (
                        <React.Fragment key={index}>
                          {elem.status == 1 && (
                            <CategoryCard categoryData={elem} />
                          )}
                        </React.Fragment>
                      ))}
                    </>
                  ) : (
                    <center>No Data Found</center>
                  )
                }
              </div>
            </div>
          </div>
        ) : <Spinner/>
      }
    </>
  );
};

export default Grade;
