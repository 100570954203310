import React,{useState} from 'react'
import { useEffect } from 'react'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { postRequest } from '../../../ApiCalls'
import TemplateFooter from '../../TemplateFooter'
import StudentImg from "../../../assets/images/student.svg";
import CheckImg from "../../../assets/images/check.svg";
import { UserContext } from '../../../App'
import { useContext } from 'react'

const QuestionImageAlphaNumericalSequence = (props) => {
    const question = props?.question
    var textBoxLength = question?.questionData?.correct_answer.length

    const { state, dispatch } = useContext(UserContext);
    const [isAnswerWrong,setIsAnswerWrong] = useState(false)
    const [isHideFooter,setIsHideFooter] = useState(false);
    const [usersAnswer,setUserAnswer] = useState("")
    const [inputData,setInputData] = useState({})
    
    const handleChange = (e,regex) => {
        const {name,value} = e.target
        setInputData((preVal)=>{
            return {
                ...preVal,
                [name] : value.trim()
            }
        })
    }
    const handleSubmit = async(e) => {
        e.preventDefault()
        const correctAnswer = question?.questionData?.correct_answer
        
        const ordered = Object.keys(inputData).sort().reduce(
            (obj, key) => { 
                obj[key] = inputData[key]; 
                return obj;
            }, 
            {}
            );
        
            var data = Object.values(ordered);
        
            if(data.length == 0) {
            toast.warning("Please, enter answer");
            return 0
        }
        if(JSON.stringify(data) === JSON.stringify(correctAnswer)){
            props?.handleSubmit(true,data)
        } else {
            setIsAnswerWrong(true)
            setIsHideFooter(true)
            var dataa = {
                question_id : question.id,
                users_ans : data
            }
            // var res = await postRequest("show-correct-answer",dataa)
            setUserAnswer(data)
            // props?.handleSubmit(false, answer)
        }   
        document.getElementById("inputForm").reset()
        setInputData({})
    };
    const nextQuestion = () => {
        setIsAnswerWrong(false)
        setIsHideFooter(false)
        props?.handleSubmit(false, usersAnswer)
        setUserAnswer("")
    }

    useEffect(()=>{
        setIsHideFooter(props?.isHideFooter)
        setUserAnswer(props?.usersAnswer)
    },[])
    return (
        <>
            <div className='question-image-alpha-numerical-sequence'>
                <form autoComplete="false" onSubmit={handleSubmit} id="inputForm">
                    <div className='img-box'>
                        <img src={question?.questionData?.select_question_image?.images.replace('original', 'thumb')} alt="Question Image"/>
                    </div>
                    
                    <div className='input-sequence py-4'>
                        {(() => {
                            let option = [];
                            for (let i = 1; i <=textBoxLength ; i++) {
                                option.push(
                                    <span key={`${question?.id}_${i}`}>                  
                                        <input type="text"  className='form-control' required name={`answer${i}`}  onChange={(e)=>handleChange(e,/[0-9]|\./)} autoComplete="off"/>
                                    </span>
                                );
                            }
                            return option;
                        }
                        )()}
                    </div>
                    {
                        !isHideFooter && 
                            <div className='questions-footer'>
                                <TemplateFooter question={question}/>
                                <div className='question-btn'>
                                    <Button className="yellow-button" type="submit">
                                        Submit
                                    </Button>
                                </div>
                            </div>
                    }
                    {
                        usersAnswer &&
                        <div className='answer-info'>
                            <div className='box'>
                                <span className='student'>{state.type == 3 || state.type == 4 ? "Children" : "Student"} Answer <img src={StudentImg} className="img-fluid"/></span>
                                <h5>{usersAnswer.toString().replaceAll(",", " ")}</h5>
                            </div>
                            <div className='box'>
                                <span className='correct'>Correct Answer <img src={CheckImg} className="img-fluid"/></span>
                                <h5>
                                    {question?.questionData?.correct_answer.map((elem, index) => (
                                    <React.Fragment key={index}>
                                        {elem} {" "}
                                    </React.Fragment>))}
                                </h5>
                            </div>
                        </div>
                    }
                    <div className='explanation-info'>
                    {
                    usersAnswer && isAnswerWrong &&
                    <div className='details'>
                        <span>Explanation</span>
                        {
                                question?.hintType == 0 ? 
                                    <p>{question.questionData.answer_hint}</p>
                                :
                                    <img src={question.questionData.answer_hint} className="img-fluid question-img" />
                        }                    
                        </div>
                    }  
                    {
                        isAnswerWrong && 
                        <div className='question-btn'>
                            <Link className="yellow-button" onClick={nextQuestion}>
                                Next
                            </Link>
                        </div>
                    }
                    </div>
                </form>
            </div>
        </>
    )
}

export default QuestionImageAlphaNumericalSequence