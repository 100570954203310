import React, { useEffect, useState ,useContext} from "react";
import DataBlog from "../assets/images/calender.png";
import BlogCommnet from "../assets/images/comment.png";
import swal from "sweetalert";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import * as Yup from "yup";
import { useFormik } from "formik";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { useParams } from "react-router-dom";
import BannerComponent from "../component/BannerComponent";
import ReplyComponent from "../component/ReplyComponent";
import { callApi } from "../ApiCalls";
import { UserContext } from "../App";
import Spinner from "../component/Loader/Spinner"

const BlogDetails = () => {
	const { state, dispatch } = useContext(UserContext);

  const [resData, setResData] = useState([]);
  const [valueOne, setValueOne] = useState(0);
  const [valueTwo, setValueTwo] = useState(0);
  const [ans, setAns] = useState();
  const { postSlug } = useParams();
  const digitsOnly = (value) => /^\d+$/.test(value);
  const userSchema = Yup.object({
    comment: Yup.string().max(500).required("Comment is a required field"),
    answer: Yup.string()
      .required("Answer is a required field")
      .test("Digits only", "The field should have digits only", digitsOnly),
  });
  const initialValues = {
    comment: "",
    answer: "",
  };
  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: userSchema,
    onSubmit: async(values, { resetForm }) => {
      if (parseInt(values.answer) == ans) {
        delete values.answer;
        const data = {
          comment: values.comment,
          userId: parseInt(localStorage.getItem("userId")),
        };
        const response = await callApi(`post-comment/${resData.data.blogDetails.postID}`,data)
        if(response.statusCode == 200) {
            resetForm();
            getData();
        }
      } else {
        swal({
          icon: "error",
          text: "Wrong answer",
          timer: 2000,
        });
      }
    },
  });
  async function getData() {
    const res = await callApi(`get-blog-details/${postSlug}`);
    setResData(res);
  }
  useEffect(() => {
    async function getData() {
      const res = await callApi(`get-blog-details/${postSlug}`);
      setResData(res);
    }
    getData();

    const x = parseInt(Math.random() * 10);
    setValueOne(x);
    const y = parseInt(Math.random() * 10);
    setValueTwo(y);
    const z = x + y;
    setAns(z);
  }, []);
  return (
    <>
    <BannerComponent bannerData={resData.data ? resData.data.pageData : "Blog"} title="Blog"/>
      {
        resData.data ? (
          <>
            
            <div className="blog-details-main">
              <div className="container">
                <div className="blog-details-wrap">
                  <div className="blog-image">
                    <img src={resData.data.blogDetails.postBanner} />
                  </div>
                  <strong>{resData.data.blogDetails.postTitle}</strong>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: resData.data.blogDetails.postContent,
                    }}
                  />
                  {/* {
                    resData.data.blogDetails.commentCount == 0 ? ("") :
                    <div className="blog-commnets">
                    <h4>
                      Comment{" "}
                      <span>({resData.data.totalComments})</span>
                    </h4>
                    <div className="blog-msg-main">
                      {resData.data.blogComment.map((elem, index) => (
                        <React.Fragment key={index}>
                          <ReplyComponent commentData={elem} />
                        </React.Fragment>
                      ))}
                    </div>
                    </div>
                  }
                  {
                    state && (
                      <div className="blog-form">
                        <h4>Leave A Comment</h4>
                        <Form onSubmit={handleSubmit} className="row">
                          <FloatingLabel
                            controlId="floatingTextarea2"
                            className="col-md-6 field-reversed"
                          >
                            <Form.Label>Comments</Form.Label>
                            <Form.Control
                              as="textarea"
                              name="comment"
                              onChange={handleChange}
                              placeholder="Leave a comment here"
                              value={values.comment}
                            />
                            {errors.comment && touched.comment && (
                              <Form.Text className="text-danger">
                                {errors.comment}
                              </Form.Text>
                            )}
                          </FloatingLabel>

                          <Form.Group className="form-group full-fuied col-md-6 " controlId="plus" >
                            <Form.Label>Solve</Form.Label>
                            <div className="solve">
                              <Form.Control
                                type="text"
                                value={valueOne}
                                disabled
                                className="custom-form-width"
                              />
                              <Form.Text className="text-muted ">
                                {" "}
                                {errors.text}
                              </Form.Text>
                              <span>+</span>
                              <Form.Control
                                type="text"
                                value={valueTwo}
                                disabled
                                className="custom-form-width"
                              />
                              <Form.Text className="text-muted ">
                                {" "}
                                {errors.text}
                              </Form.Text>
                              <span>=</span>
                              <Form.Control
                                type="text"
                                name="answer"
                                onChange={handleChange}
                                value={values.answer}
                                placeholder="Enter Answer"
                              />
                              {errors.answer && touched.answer && (
                                <Form.Text className="text-danger">
                                  {errors.answer}
                                </Form.Text>
                              )}
                            </div>
                          </Form.Group>

                          <Button variant="primary" type="submit" className="yellow-button col-md-12" >
                            Submit
                          </Button>
                        </Form>
                      </div>
                    )
                  } */}
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            {
              resData.statusCode == 404 ? (
                <center>Blog Not Found</center>
              ) : <Spinner />
            }
          </>
        )
      }
    </>
  );
};

export default BlogDetails;
