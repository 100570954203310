
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import BannerComponent from '../../component/BannerComponent';
import Spinner from '../../component/Loader/Spinner';
import { callApi, dateConverter } from '../../ApiCalls';
import Table from '../../component/Table/Table';
import { useContext } from 'react';
import { UserContext } from '../../App';

const ProgressReport = () => {
    
    const navigatePage = useNavigate();
    // const {state} = useLocation();
    const location = useLocation();
    const { state, dispatch } = useContext(UserContext);
    const [progressReport,setProgressReport] = useState({})
    const [isLoder,setIsLoader] = useState(false)
    const navigate = window.location.pathname.split('/')[1];
    const childNavigate = window.location.pathname.split('/')[2];

    useEffect(() => {
      async function getData() {
        var userId = location.pathname.substring(location.pathname.lastIndexOf('/') + 1)

        var res = await callApi(`get-progress-report/${userId}`)
        setProgressReport(res.data)
        setIsLoader(true)
      }
      getData();
    }, []);
    
    var columns = [
        {
            dataField: "lessonName",
            text: "Lessons",
            sort: true
        },
        {
            dataField: "status",
            text: "Status",
            sort: true
        },
        {
            dataField: "completionDate",
            text: "Completion Date",
            formatter : (cell,row) => {
                return cell == null ? "-" : dateConverter(cell)
            }
        },
        {
            dataField: "score",
            text: "Score",
            formatter : (cell,row) => {
                return cell == null ? "-" : cell
            }
        },
        {
            dataField: "info",
            text: "More Info",
            formatter: (cell, row, rowIndex) => {
                return (
                    <div className={`assign-detail ${row.isComplete == 0 && "disable-link"}`} onClick={()=>navigatePage(`/progress/recent-activity-progress/lesson-report/${row.assignmentId}`)}>More Info</div>
                )
            }
        }
    ];

    return (
        <>
            {
                isLoder ? (
                    <>
                        <BannerComponent title="Progress Report" navigate={navigate} childNavigate={childNavigate} />
                        <section className='progress-report-wrap'>
                            <div className='container'>    
                                <div className="main-title">
                                    <h2>{state.type == 3 ? "children" : "student"} Progress</h2>
                                </div>
                                
                                <ul className=''>
                                    <li><span>User Name: </span> {progressReport.name}</li>
                                    <li><span>Lessons Completed: </span> {progressReport.lessonCompleted}</li>
                                    <li><span>Incompleted Lessons: </span> {progressReport.lessonStartedNotCompleted}</li>
                                    <li><span>Grade Level: </span> {progressReport.gradeLevel}</li>
                                    <li><span>Math Ability Level: </span> {progressReport.abilityLevel == null ? "-" : progressReport.abilityLevel}</li>
                                </ul>                                
                                <Table rowData={progressReport.lessons} columns={columns} type="Progress report"/>
                            </div>
                        </section>  
                    </>
                ) : (
                    <Spinner/>
                )
            }
        </>
    )
}

export default ProgressReport