import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const WorksheetCard = (props) => {
    const {worksheetTitle, worksheetImage, pdfFile} = props?.cardData
    return (
        <Col lg={3} md={6} xs={12}>
            <Link className='worksheet-img' onClick={()=>window.open(pdfFile,"__blank")}>
                <div className='img-wrap'>
                    <img src={worksheetImage} alt="worksheet Image" className='img-fluid'/>
                </div>
                <h4>{worksheetTitle}</h4>
            </Link>
        </Col>
    )
}

export default WorksheetCard