import React, { useState,useEffect } from "react";
import styled from "@emotion/styled";
import { DragDropContext,Draggable } from "react-beautiful-dnd";
import { StrictModeDroppable } from "./StrictModeDroppable";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import TemplateFooter from "../../TemplateFooter";
import { postRequest } from "../../../ApiCalls";
import StudentImg from "../../../assets/images/student.svg";
import CheckImg from "../../../assets/images/check.svg";
import { useContext } from "react";
import { UserContext } from "../../../App";
import SoundIcon from "../../../assets/images/speaker-icon.svg";
import Tooltip from '@mui/material/Tooltip';

const Container = styled.div`
  display: flex;
`;
const TaskList = styled.div`
  min-height: 100px;
  display: flex;
  flex-direction: column;
  background: #f3f3f3;
  min-width: 341px;
  border-radius: 5px;
  padding: 15px 15px;
  margin-right: 45px;
`;
const TaskColumnStyles = styled.div`
  margin: 8px;
  display: flex;
  width: 100%;
  min-height: 80vh;
`;
const Title = styled.span`
  color: #10957d;
  background: rgba(16, 149, 125, 0.15);
  padding: 2px 10px;
  border-radius: 5px;
  align-self: flex-start;
`;

const MultiImageDragBox = (props) => {
    const question = props?.question
    
    const { state, dispatch } = useContext(UserContext);
    const [isAnswerWrong,setIsAnswerWrong] = useState(false)
    const [isHideFooter,setIsHideFooter] = useState(false);
    const [usersAnswer,setUserAnswer] = useState("")
    const [saveAns,setSaveAns] = useState([])
    const [answer,setAnswer] =useState([])
    const [isDisabled,setIsDisabled] = useState(true)
    const [correctAnswer,setCorrectAnswer] = useState()
    const [columns, setColumns] = useState({});

    const studentAns = usersAnswer && usersAnswer.split(",").map((element)=>{
        return question.questionData.question_image.find(elem=>elem.index === element)
    })

    const correctAns = question.questionData.question_image.filter((elem) => {
        if(question?.questionData?.correct_answer.indexOf(elem.index) !== -1) {
            return elem
        }
    })
    const play = (sound) =>{
        var audio = new Audio(sound);
        audio.play();
    }
    const onDragEnd = (result, columns, setColumns) => {
        if (!result.destination) return;
        const { source, destination } = result;
        if (source.droppableId !== destination.droppableId) {
            const sourceColumn = columns[source.droppableId];
            const destColumn = columns[destination.droppableId];
            const sourceItems = [...sourceColumn.items];
            const destItems = [...destColumn.items];
            const [removed] = sourceItems.splice(source.index, 1);
            destItems.splice(destination.index, 0, removed);
            setColumns({
                ...columns,
                [source.droppableId]: {
                    ...sourceColumn,
                    items: sourceItems
                },
                [destination.droppableId]: {
                    ...destColumn,
                    items: destItems
                }
            });
            if(saveAns.includes(result.draggableId)){
                saveAns.splice(saveAns.indexOf(result.draggableId), 1);
                return;
            }
            saveAns.push(result.draggableId)
            setAnswer(saveAns)
        } else {
            const column = columns[source.droppableId];
            const copiedItems = [...column.items];
            const [removed] = copiedItems.splice(source.index, 1);
            copiedItems.splice(destination.index, 0, removed);
            setColumns({
                ...columns,
                [source.droppableId]: {
                    ...column,
                    items: copiedItems
                }
            });
        }
    };
    
    const handleSubmit = async() => {
        const correctAnswer = question?.questionData?.correct_answer
        setIsDisabled(false)
        if(answer.length == 0) {
            toast.warning("Please, drag answer");
            setIsDisabled(true)
            return 0
        }
        if(JSON.stringify(answer.sort()) === JSON.stringify(correctAnswer)){
            props?.handleSubmit(true,answer)
        } else {
            setIsAnswerWrong(true)
            setIsHideFooter(true)
            var data = {
                question_id : question.id,
                users_ans : answer.toString()
            }
            // var res = await postRequest("show-correct-answer",data)
            setUserAnswer(answer.toString())
        }
        setAnswer([])
        setColumns({})
        setIsDisabled(true)
    }
    const nextQuestion = () => {
        setIsAnswerWrong(false)
        setIsHideFooter(false)
        props?.handleSubmit(false, usersAnswer)
        setUserAnswer("")
    }

    useEffect(()=>{
        setColumns(prev => {
            return {
                ...prev,
                "zone1": {
                    title: "Question Image",
                    items: 
                        question.questionData.question_image.map((elem, index) => {
                            return elem
                        })
                },
                "zone2": {
                    title: "Answer",
                    items: []
                }
            }
        })
        setIsHideFooter(props?.isHideFooter)
        setUserAnswer(props?.usersAnswer)
        setCorrectAnswer(correctAns);
    },[question])

    return (
        <div className="multi-image-dragbox multi-image-dragbox-sound">
            <DragDropContext onDragEnd={(result) => onDragEnd(result, columns, setColumns)}>
                <Container>
                    <TaskColumnStyles>
                        {Object.entries(columns).map(([columnId, column], index) => {
                            return (
                                <StrictModeDroppable key={`${question?.id}_${columnId}`} droppableId={columnId}>
                                    {(provided, snapshot) => (
                                        <TaskList ref={provided.innerRef} {...provided.droppableProps} className="drag-box">
                                            {column.items.map((item, index) => (
                                                <Draggable key={`${question?.id}_${item.index}`} draggableId={item.index} index={index} >
                                                    {(provided) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            {
                                                                item?.sound != null &&
                                                                <div className="head-name">
                                                                    <Link onClick={() => play(item?.sound)} alt="Question Audio">
                                                                    <Tooltip title="Play Audio" placement="top"><img src={SoundIcon} /></Tooltip>
                                                                    </Link>
                                                                </div>
                                                            }
                                                            <div className="secondary-details">
                                                                <img src={item.images.replace('original', 'thumb')} />
                                                            </div>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </TaskList>
                                    )}
                                </StrictModeDroppable>
                            );
                        })}
                    </TaskColumnStyles>
                </Container>
            </DragDropContext>
            {
                !isHideFooter &&
                        <div className='questions-footer'>
                            <TemplateFooter question={question}/>
                            <div className='question-btn'>
                                <Link className="yellow-button" onClick={()=> isDisabled && handleSubmit()}>
                                    Submit
                                </Link>
                            </div>
                        </div>
            }
            {
                usersAnswer &&
                <div className='answer-info'>
                    <div className='box'>
                        <span className='student'>{state.type == 3 || state.type == 4 ? "Children" : "Student"} Answer <img src={StudentImg} className="img-fluid"/></span>
                        <div className="img-sec">
                        {
                            studentAns.map((elem,index)=>(
                                <img  src={elem.images} key={index}/>
                            ))
                        }
                        </div>
                    </div>
                    <div className='box'>
                        <span className='correct'>Correct Answer <img src={CheckImg} className="img-fluid"/></span>
                        <div className="img-sec">
                        {
                            correctAnswer && correctAnswer.map((elem,index)=>(
                                <img  src={elem.images} key={index}/>
                            ))
                        }
                        </div>
                    </div>
                </div>
            }
            <div className='explanation-info'>
            {
                usersAnswer && isAnswerWrong &&
                <div className='details'>
                    <span>Explanation</span>
                        {
                            question?.hintType == 0 ? 
                                <p>{question.questionData.answer_hint}</p>
                            :
                                <img src={question.questionData.answer_hint} className="img-fluid question-img"/>
                        }
                    
                </div>
            }  
            {
                isAnswerWrong && 
                <div className='question-btn'>
                    <Link className="yellow-button" onClick={nextQuestion}>
                        Next
                    </Link>
                </div>
            } 
            </div>
        </div>
    );
};

export default MultiImageDragBox;