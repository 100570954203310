import React,{useEffect,useState,useContext} from 'react'
import { callApi } from '../../ApiCalls'
import Spinner from '../../component/Loader/Spinner'
import WorksheetCard from '../../component/Card/WorksheetCard'
import BannerComponent from '../../component/BannerComponent'
import { Row } from 'react-bootstrap'
import { UserContext } from '../../App'
import {useNavigate } from 'react-router-dom'

const ListWorksheet = () => {
    
    const { state, dispatch } = useContext(UserContext);
    const navigation = useNavigate();
    const navigate = window.location.pathname.split('/')[1];

    const [worksheetData,setWorksheetData] = useState([])

    useEffect(()=>{
        if(state.isPaid == 0)
            navigation("/");
        async function getWorksheetData() {
            var worksheetData = await callApi("get-worksheet-info")
            setWorksheetData(worksheetData)
        }
        getWorksheetData()
    },[])

    return (
        <>
            <BannerComponent title="List Worksheet" navigate={navigate}/>
            <div  className="worksheet-list-wrap">
                <div className="container">
            {
                worksheetData.data ? (
                    <Row className='p-0'>
                        {
                            worksheetData.data && worksheetData.data.length > 0 ? 
                                worksheetData.data.map((elem,index)=>(
                                    <WorksheetCard cardData={elem} key={index}/>
                                ))
                            :
                                <center>No Worksheet Found</center>
                        }
                    </Row>
                ) : (
                    <Spinner/>
                )
            }
            </div>
            </div>
        </>
    )
}

export default ListWorksheet