import React, { useContext } from "react";
import GuestHome from "./CmsPages/GuestHome";
import ParentHome from "./Parent/Home";
import TeacherHome from "./Teacher/Home";
import ChildHome from "./Children/Home"
import { UserContext } from "../App";

const Home = () => {
  const { state, dispatch } = useContext(UserContext);

  return (
    <>
      {state.type != null && state.isExpired != 1 ? (
        state.type == 3 ? (
          <ParentHome />
        ) : state.type == 4 ? (
          <ChildHome />
        ) : state.type == 5 ? (
          <TeacherHome />
        ) : (
          <ChildHome />
        )
      ) : (
        <GuestHome />
      )}
    </>
  );
};

export default Home;
