import React,{ useContext,useState,useEffect } from "react";
import { Link } from "react-router-dom";
import AboutUs from "../../src/assets/images/aboutus-image.jpg";
import { UserContext } from "../App";

const BannerComponent = (props) => { 

  const { state, dispatch } = useContext(UserContext);
  const [bannerImage,setBannerImage] = useState("")

  const pageTitle = props.bannerData?.pageTitle
    ? props.bannerData?.pageTitle
    : props?.title
    ? props?.title
    : " ";
  const childNavigate = props?.childNavigate
  const navigate = props?.navigate
  const pageBanner = props.bannerData?.pageBanner;

  useEffect(()=>{
    setBannerImage(state.bannerImage)
  },[])

  return (
    <>
      <section
        className="common-banner"
        style={{ background: `url(${pageBanner ? pageBanner : bannerImage})` }}
      >
        <span className="common-header-animation"></span>
        <span className="common-header-animation-two"></span>
        <div className="container">
          <div className="common-banner-wraper">
            <h1>{pageTitle.charAt(0).toUpperCase() + pageTitle.slice(1)}</h1>
            <ul className="bread-cum">
              {pageTitle != "Teacher Home" && pageTitle != "Parent Home" && pageTitle != "My Home" && pageTitle != "Email Verification" &&(
                <>
                  {navigate ?
                    <>                
                      <li>
                        <Link to="/">Home</Link>{" "}
                      </li>
                      <li>
                        <Link to={`/${navigate}`}>{navigate.charAt(0).toUpperCase() + navigate.slice(1).replace(/-/g,' ')}</Link>
                      </li>
                    </>
                  :
                  <li>
                    <Link to="/">Home</Link>{" "}
                  </li>
                }
                  {childNavigate &&
                        <li>
                          <Link to={`/${navigate}/${childNavigate}`}>{childNavigate.charAt(0).toUpperCase() + childNavigate.slice(1).replace(/-/g,' ')}</Link>
                        </li> 
                  }
                  <li>
                    {pageTitle.charAt(0).toUpperCase() + pageTitle.slice(1)}
                  </li>
                </>
              )}
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default BannerComponent;
