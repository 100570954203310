import React, { useState,useEffect,useContext } from 'react'
import { callApi, dateConverter } from '../../ApiCalls';
import { UserContext } from '../../App';
import BannerComponent from '../../component/BannerComponent';
import Spinner from '../../component/Loader/Spinner';
import {Tabs,Tab,Box,Typography} from "@mui/material";
import Table from '../../component/Table/Table';
import {useNavigate } from 'react-router-dom'

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
        >
        {value === index && (
            <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
            </Box>
        )}
        </div>
    );
    
}
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
        "tab-value" : index,
    };
}
const ViewWorksheet = () => {

    const { state, dispatch } = useContext(UserContext);
    const navigation = useNavigate();
    const navigate = window.location.pathname.split('/')[1];

    const [classData,setClassData] = useState([])
    const [activeTab,setActiveTab] = useState(0)
    const [worksheetData,setWorksheetData] = useState([])
    const [value, setValue] = useState(0);

    const handleChange = async(event, newValue) => {
        setValue(newValue);
        var classId = event.target.getAttribute("tab-value")
        const response = await callApi(`get-worksheet-studentinfo/${classId}`);
        setWorksheetData(response.data)
        setActiveTab(classId)
    };

    useEffect(()=>{
        async function getStudentWorksheetData() {
            var classData = await callApi("get-class");
            setClassData(classData)
            if(classData.data.length > 0){
                setActiveTab(classData.data[0].id)
                const response = await callApi(`get-worksheet-studentinfo/${classData.data[0].id}`);
                setWorksheetData(response.data)
            }
        }
        async function getChildWorksheetData() {
            const response = await callApi(`get-worksheet-studentinfo`);
            setWorksheetData(response.data)
        }
        if(state.isPaid == 0)
            navigation("/");

        if(state.type == 5)
            getStudentWorksheetData()
        else 
            getChildWorksheetData()
    },[])

    var columns = [
        {
            dataField: "name",
            text: "Name",
            sort: true
        },
        {
            dataField: "worksheetTitle",
            text: "Worksheet Title",
            sort: true
        },
        {
            dataField: "createdAt",
            text: "Date",
            formatter : (cell,row) => {
                return dateConverter(cell)
            }
        },
        {
            dataField: "info",
            text: "View",
            formatter: (cell, row, rowIndex) => {
                return (
                    <div className="assign-detail" onClick={()=>window.open(row.pdfFile,"__blank")}>View Worksheet</div>
                );
            }
        }
    ];

    return (
        <>
            <BannerComponent title="View Worksheet" navigate={navigate}/>
            {
                worksheetData ? (
                    <div className="upcomingWebinars-main">
                        <div className="container">
                            <div className="upcomingWebinars-main-wraper">
                                {state.type == 5 && classData.data && classData.data.length > 0 ? (
                                    <>                          
                                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className="tab-items">
                                            <Tabs value={value} onChange={handleChange}>
                                            {
                                                classData.data.map((elem,index)=>(
                                                    <Tab label={`${elem.className}`} key={index} {...a11yProps(elem.id)} />
                                                ))
                                            }
                                            </Tabs>
                                        </Box> 
                                        <div className="tab-content">
                                            <TabPanel value={value} index={value} >                                                                            
                                                <Table rowData={worksheetData} columns={columns} type="Worksheet" keyField="worksheetId"/>
                                            </TabPanel>
                                        </div>
                                    </>
                                    ) :
                                        <Table rowData={worksheetData} columns={columns} type="Worksheet" keyField="worksheetId"/>
                                }
                            </div>
                        </div>
                    </div>
                ) : (
                    <Spinner />
                )
            }
        </>
    )
}

export default ViewWorksheet