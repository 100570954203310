import React,{useEffect} from 'react'
import { callApi } from '../../ApiCalls'
import { useState } from 'react'
import axios from 'axios'
import { Link, useNavigate } from 'react-router-dom'
import { ModalComponent } from '../ModalComponent'
import Progress from "../../assets/images/progress.png";
import Redcross from "../../assets/images/red-cross.png";
import BlueEdit from "../../assets/images/blue-edit.png";
import Table from '../Table/Table'
import swal from "sweetalert";
import environment from '../../environment';
const MyClass = () => {

  const [classData,setClassData] = useState([])
  const [newClassData,setnewClassData] = useState([])
  const [show, setShow] = useState(false);
  const [title,setTitle] = useState()
  const [isUpdate, setIsUpdate] = useState(false);
  const [updateData,setUpdateData] = useState([])
  const navigate = useNavigate();

  const deleteClass = (id) => {
    swal({
        title: "Are you sure, You want to delete ?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
    })
    .then((willDelete) => {
        if (willDelete) {
            deleteRecord(id)
            swal("Record has been deleted!", {
                icon: "success",
                timer: 2000,
            });
        } 
    });
  }

  const handleShow = (type) => {
    if(type === "addClass") {
        setTitle("Add Class")
    } else {
        setTitle("Add Student")
    }
    setShow(true)
  }
  const handleClose = () => {
    setShow(false)
    setIsUpdate(false)
  }
  const getClass = async() => {
    const response = await callApi("get-class");
    setClassData(response.data)
    setnewClassData(response)
  }

  const deleteRecord = async(id) => {
    var token = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    await axios.delete(environment.REACT_APP_API_URL + `destroy-class/${id}`, {
        headers: headers,
      }).then((res) => {
        if(res.data.statusCode == 200){
          setClassData((preVal)=> {
            return preVal.filter((elem)=>{
              if(elem.id != id){
                return elem
              }
            })
         })
         getClass()
        }
      }).catch((err) => {
        console.log(err, "Error");
      });
  }
  const editRecord = (data) => {
    setShow(true)
    setIsUpdate(true)
    setTitle("Edit Class")
    setUpdateData(data)
  }
  useEffect(()=>{
    async function getData() {
      const response = await callApi("get-class");
      setClassData(response.data)
      setnewClassData(response)
    }
    getData()
  },[])
  var columns =[
    {
      dataField: "className",
      text: "Name",
      sort: true
    },
    {
      dataField: "progress",
      text: "Progress",
      formatter: (cell, row, rowIndex) => {
        return (
          <div className='action-btns' onClick={()=>navigate("/progress")}><img src={Progress}/></div>
        );
      }
    },
    {
      dataField: "edit",
      text: "Edit",
      formatter: (cell, row, rowIndex) => {
        return (
          <div className='action-btns' onClick={()=>editRecord(row)}><img src={BlueEdit}/></div>
        );
      }
    },
    {
      dataField: "delete",
      text: "Delete",
      formatter: (cell, row, rowIndex) => {
        return (
          <div className='action-btns' onClick={()=>deleteClass(row.id)}><img src={Redcross}/></div>
        );
      }
    }
  ]
  return (
    <>
      {
        newClassData.data && (
          <>
            <div className="container">
              <Table rowData={classData} columns={columns} type="Class"/>
              <div className="d-flex justify-content-between align-item-center flex-wrap add-child-button">
                {
                  newClassData.classCapacity > newClassData.data.length && 
                    <Link onClick={()=>handleShow("addClass")} className="yellow-button">Create Class</Link>
                }
              </div>
            </div>
            {
              show &&
              <ModalComponent show={show} isUpdate={isUpdate} userData={updateData} addData={getClass} handleClose={handleClose} detailType="addClass" type="addClass" className="addClass" title={title}/>
            }
          </>
        )
      }
    </>
  )
}

export default MyClass