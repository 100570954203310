import React, { useEffect, useState } from "react";
import BannerComponent from "../component/BannerComponent";
import { useLocation } from "react-router-dom";
import { callApi } from "../ApiCalls";
import Question from "../component/Question/Question";
import Spinner from "../component/Loader/Spinner";

var index = 0;

const AssignmentQuestions = () => {
  const [queList, setQueList] = useState([]);
  const [currentpage, setcurrentpage] = useState(1);
  const [perPage, setperPage] = useState(1);
  const [queLength,setQueLength] = useState(0)
  const [loader,setLoader] = useState(false)
  const [userProgress,setUserProgres] = useState({})

  const location = useLocation();

  const indexOfLastTodo = currentpage * perPage;
  const indexOfFirstTodo = indexOfLastTodo - perPage;
  const currentTodos = queList.slice(indexOfFirstTodo, indexOfLastTodo);

  const submit = () => {
    setcurrentpage(currentpage + 1);
    index++;
  };
  useEffect(() => {
    async function getData() {
      var urlArr = location.pathname.split('/')
      const userProgress = await callApi(`get-progress-stat/${urlArr[2]}`);
      setUserProgres(userProgress.data)
      setQueLength(userProgress.data.totalQuestions)
    }
    getData();
  }, [currentpage]);
  useEffect(()=>{
    async function getData(){
      var slug = location.pathname.substring(location.pathname.lastIndexOf('/') + 1)
      const res = await callApi(`get-lesson-by-id/${slug}`);
      setQueList(res.data);
      setLoader(true)
    }
    getData()
  },[])

  return (
    <>
      {
        queList && loader ? (
          <>
            <BannerComponent title="Question" />
            <div className="task-main">
              <div className="container">
                {
                  queLength > 0 ? (
                    <>
                      <div className="task-wrap">
                        {currentTodos.map((elem, index) => (
                          <React.Fragment key={index}>
                            <Question question={elem} userProgress={userProgress} currentpage={currentpage} queLength={queLength} submit={submit} />
                          </React.Fragment>
                        ))}
                      </div>
                    </>
                  ) : (
                    <center>No Question Found</center>
                  )
                }
              </div>
            </div>
          </>
        ) : <Spinner />
      }
    </>
  );
};

export default AssignmentQuestions;
