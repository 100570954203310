import React, { useState, useEffect } from "react";
import BannerComponent from "../../component/BannerComponent";
import Location from "../../assets/images/location.png";
import Mail from "../../assets/images/mail.png";
import Phone from "../../assets/images/phone.png";
import Fax from "../../assets/images/fax.png";
import {Form,Button} from "react-bootstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import ContactImage from "../../assets/images/contact-image.png";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import { callApi } from "../../ApiCalls";
import Spinner from "../../component/Loader/Spinner"

const ContactUs = () => {

  const [resData, setResData] = useState([]);
  const [valueOne, setValueOne] = useState(0);
  const [valueTwo, setValueTwo] = useState(0);
  const [ans, setAns] = useState();

  useEffect(() => {
    async function getData() {
      const contactUsData = await callApi("contact-us")
       setResData(contactUsData)
    }
    getData();
    const x = parseInt(Math.random() * 10);
    setValueOne(x);
    const y = parseInt(Math.random() * 10);
    setValueTwo(y);
    const z = x + y;
    setAns(z);
  }, []);

  const digitsOnly = (value) => /^\d+$/.test(value);

  const userSchema = Yup.object({
    full_name: Yup.string().max(40,"Full name must be at most 40 characters").required("Full name is required ").matches(/^[A-Za-z0-9 ]+$/, "Only Alphabets, Number and Space are allowed for this field"),
    email: Yup.string().email("Email must be a valid email").max(30).required("Email is required "),
    email_subject: Yup.string().max(50,"Email subject must be at most 50 characters").required("Email subject is required "),
    message : Yup.string().max(500,"Message must be at most 500 characters").required('Message is required'),
    answer: Yup.string()
      .required("Answer is required")
      .max(2,"Answer must be at most 2 characters").test("Digits only", "The field should have digits only", digitsOnly),
  });

  const initialValues = {
    full_name: "",
    email: "",
    email_subject: "",
    message: "",
    answer : ""
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: userSchema,
      onSubmit: async (values, { resetForm }) => {
        if (parseInt(values.answer) == ans) {
          delete values.answer;
          const response = await callApi("contact", values)
          if(response.statusCode == 200) {
            resetForm();
        }
        } else {
          swal({
            icon: "error",
            text: "Wrong answer",
            timer: 2000,
          });
        }
      },
    });
  return (
    <>
      {
        resData.data ? (
          <div className="contact-us">
            <BannerComponent bannerData={resData.data.pageData} />
            <div className="common-animation-inner">
              <div className="contact-page-card common-animation">
                <div className="container">
                  <ul className="contact-card-wrap ">
                    <li className="pink-card">
                      <div className="contact-icon">
                        <img src={Location} alt="Location" />{" "}
                      </div>
                      <p>Mailing Address</p>
                      <a >
                        {resData.data.contactSection.sectionContent.mailingAddress}
                      </a>
                    </li>
                    <li className="purple-card">
                      <div className="contact-icon">
                        <img src={Phone} alt="Phone" />{" "}
                      </div>
                      <p>Call Us</p>
                      <a >
                        {resData.data.contactSection.sectionContent.phoneNumber}
                      </a>
                    </li>
                    <li className="green-card">
                      <div className="contact-icon">
                        <img src={Mail} alt="Mail" />{" "}
                      </div>
                      <p>Email Us</p>
                      <a href="mailto: isoar@example.com">
                        {resData.data.contactSection.sectionContent.email}
                      </a>
                    </li>
                    {/* <li className="yellow-card">
                      <div className="contact-icon">
                        <img src={Fax} alt="Fax" />{" "}
                      </div>
                      <p>Fax Number</p>
                      <a >
                        {resData.data.contactSection.sectionContent.faxNumber}
                      </a>
                    </li> */}
                  </ul>
                </div>
              </div>
              <div className="sign-up-form-wraper contact-sec-wrap">
                <div className="container">
                  <div className="sign-in-form-wraper">
                    <div className="sign-in-image">
                      <img src={resData.data.contactSection.sectionContent.image} />
                    </div>
                    <div className="common-form">
                      <h3>
                        Please use this form to contact us with questions,
                        suggestions or feedback.
                      </h3>
                      <Form autoComplete="false" onSubmit={handleSubmit}>
                        <Form.Group className="form-group half-fuied-main" controlId="formBasicEmail">
                          <div className="half-fuied">
                            <Form.Label>Full Name<span>*</span></Form.Label>
                            <Form.Control
                              type="text"
                              name="full_name"
                              placeholder="Enter Full Name"
                              onChange={handleChange}
                              value={values.full_name}
                              autoComplete="off"
                            />
                            {errors.full_name && touched.full_name && (
                              <Form.Text className="text-danger">
                                {errors.full_name}
                              </Form.Text>
                            )}
                          </div>
                          <div className="half-fuied">
                            <Form.Label>Email<span>*</span></Form.Label>
                            <Form.Control
                              type="email"
                              name="email"
                              placeholder="Enter Your Email"
                              onChange={handleChange}
                              value={values.email}
                            />
                            {errors.email && touched.email && (
                              <Form.Text className="text-danger">
                                {errors.email}
                              </Form.Text>
                            )}
                          </div>
                        </Form.Group>

                        <Form.Group className="form-group full-fuied" controlId="formBasicstudent">
                          <Form.Label>Email Subject<span>*</span></Form.Label>
                          <Form.Control
                            type="text"
                            name="email_subject"
                            placeholder="Enter Your Subject"
                            onChange={handleChange}
                            value={values.email_subject}
                          />
                          {errors.email_subject && touched.email_subject && (
                            <Form.Text className="text-danger">
                              {errors.email_subject}
                            </Form.Text>
                          )}
                        </Form.Group>

                        <Form.Group className="form-group full-fuied">
                          <Form.Label>Message<span>*</span></Form.Label>
                          <Form.Control
                            as="textarea"
                            name="message"
                            onChange={handleChange}
                            placeholder="Leave a comment here"
                            value={values.message}
                          />
                          {errors.message && touched.message && (
                            <Form.Text className="text-danger">
                              {errors.message}
                            </Form.Text>
                          )}
                        </Form.Group>

                        <Form.Group className="form-group full-fuied" controlId="plus" >
                          <Form.Label> Please solve below puzzle to prove you are human<span>*</span></Form.Label>
                          <div className="solve">
                            <Form.Control type="text" defaultValue={valueOne} className="custom-form-width"/>
                            <span>+</span>
                            <Form.Control type="text" defaultValue={valueTwo} className="custom-form-width"/>
                            <span>=</span>
                            <Form.Control type="text" name="answer" onChange={handleChange} value={values.answer} placeholder="Enter Answer" maxLength="2"/>
                            {errors.answer && touched.answer && (<Form.Text className="text-danger"> {errors.answer}</Form.Text>)}
                          </div>
                        </Form.Group>

                        <Button
                          variant="primary"
                          type="submit"
                          className="yellow-button"
                        >
                          Submit
                        </Button>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : <Spinner/>
      }
    </>
  );
};

export default ContactUs;
