const GblObject = {
    videoAnimation : {
        correct : [
            {
                index : 1,
                url : "/videos/RightAnswer/1.mp4"
            },
            {
                index : 2,
                url : "/videos/RightAnswer/2.mp4"
            },
            {
                index : 3,
                url : "/videos/RightAnswer/3.mp4"
            },
            {
                index : 4,
                url : "/videos/RightAnswer/4.mp4"
            },
            {
                index : 5,
                url : "/videos/RightAnswer/5.mp4"
            },
            {
                index : 6,
                url : "/videos/RightAnswer/6.mp4"
            },
            {
                index : 7,
                url : "/videos/RightAnswer/7.mp4"
            },
            {
                index : 8,
                url : "/videos/RightAnswer/8.mp4"
            }
        ],
        inCorrect : [
            {
                index : 1,
                url : "/videos/WrongAnswer/1.mp4"
            },
            {
                index : 2,
                url : "/videos/WrongAnswer/2.mp4"
            },
            {
                index : 3,
                url : "/videos/WrongAnswer/3.mp4"
            },
            {
                index : 4,
                url : "/videos/WrongAnswer/4.mp4"
            },
            {
                index : 5,
                url : "/videos/WrongAnswer/5.mp4"
            },
            {
                index : 6,
                url : "/videos/WrongAnswer/6.mp4"
            },
            {
                index : 7,
                url : "/videos/WrongAnswer/7.mp4"
            },
            {
                index : 8,
                url : "/videos/WrongAnswer/8.mp4"
            }
        ]
    },
    displayCorrectVideo : 0,
    displayInCorrectVideo : 0,
    lessonSearch : "",
    animation : 0,
    lessons_score : 0,
    hint : 0,
    animationSound : 0,
    studentAnimation : 0,
    totalAttempt : 0
}
export default GblObject