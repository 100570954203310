import React,{useContext} from 'react'
import { UserContext } from '../../App'
import ParentIndividualAssignment from "../Parent/IndividualAssignment"
import TeacherIndividualAssignment from "../Teacher/IndividualAssignment"

const IndividualAssignment = () => {

    const { state, dispatch } = useContext(UserContext);
    return (
        <>
            {
                state.type == 3 ? 
                    <ParentIndividualAssignment />
                :
                    <TeacherIndividualAssignment />
            }
        </>
    )
}

export default IndividualAssignment