import React,{ useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { callApi } from "../../ApiCalls";
import * as Yup from "yup";
import { useFormik } from "formik";
import { InputGroup } from "react-bootstrap";
import { IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const AddEditStudent = (props) => {
  const gradeData = props?.gradeData;
  const isUpdate = props?.isUpdate;
  const userData = props?.userData;
  const classId = props?.classId;
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const [isDisable, setIsDisable] = useState(false);
  const userSchema1 = Yup.object({
    first_name: Yup.string().max(40,"First name must be at least 40 characters").required("First name is required").matches(/^[A-Za-z0-9 ]+$/, "Only Alphabets, Number and Space are allowed for this field"),
    last_name: Yup.string().max(40,"Last name must be at least 40 characters").required("Last name is required").matches(/^[A-Za-z0-9 ]+$/, "Only Alphabets, Number and Space are allowed for this field"),
    user_name: Yup.string().max(20,"User name must be at least 20 characters").required("User name is required").matches(/^[A-Za-z0-9 ]+$/, "Only Alphabets, Number and Space are allowed for this field"),
    password: Yup.string().min(8,"Password must be at least 8 characters").max(20,"Password must be at most 20 characters")
    .required('Password is required')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])/,
      "One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
    grade_level_id: Yup.string().required("Grade is required"),
    class_id: Yup.string().required("Class is required"),
  });

  const initialValues = {
    first_name: isUpdate ? userData.firstName : "",
    last_name: isUpdate ? userData.lastName : "",
    class_id: isUpdate ? userData.classID : classId,
    user_name: isUpdate ? userData.userName : "",
    password: isUpdate ? userData.password : "",
    grade_level_id: isUpdate ? userData.gradeId : "",
    ability_level_id: isUpdate ? userData.abilityLevelID : "",
    parent_email_1: isUpdate ? userData.parentEmail1 : "",
    parent_email_2: isUpdate ? userData.parentEmail2 : "",
  };
  const { values, touched, errors, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: userSchema1,
    onSubmit: async (values, { resetForm }) => {
      setIsDisable(true)
      const userID = isUpdate
        ? parseInt(userData.userId)
        : parseInt(localStorage.getItem("userId"));
      values["class_id"] = parseInt(values.class_id);
      values["grade_level_id"] = parseInt(values.grade_level_id);
      values["ability_level_id"] = parseInt(values.ability_level_id);
      values["teacher_id"] = userID;

      if (isUpdate) {
        var response = await callApi(`update-student/${userID}`, values);
        if (response.statusCode == 200) {
          resetForm();
          props?.addData();
          props?.handleClose();
        }else {
          setIsDisable(false)
        }
      } else {
        var response = await callApi("store-student", values);
        if (response.statusCode == 200) {
          resetForm();
          props?.addData();
          props?.handleClose();
        }else {
          setIsDisable(false)
        }
      }
    },
  });
  return (
    <>
      <div className={`account-details student-pop ${isDisable?"modal-disable":""}`}>
        <Form autoComplete="false" autoFocus={false} onSubmit={handleSubmit}>
          <Form.Group
            className="form-group half-fuied-main"
            controlId="formBasicEmail"
          >
            <div className="half-fuied">
              <Form.Label>
                First Name<span>*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="first_name"
                placeholder="Enter First Name"
                onChange={handleChange}
                value={values.first_name}
                autoComplete="off"
              />
              {errors.first_name && touched.first_name && (
                <Form.Text className="text-danger">
                  {errors.first_name}
                </Form.Text>
              )}
            </div>
            <div className="half-fuied">
              <Form.Label>
                Last Name<span>*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="last_name"
                placeholder="Enter Last Name"
                onChange={handleChange}
                value={values.last_name}
                autoComplete="off"
              />
              {errors.last_name && touched.last_name && (
                <Form.Text className="text-danger">
                  {errors.last_name}
                </Form.Text>
              )}
            </div>
          </Form.Group>
          <Form.Group
            className="form-group half-fuied-main"
            controlId="formBasicEmail"
          >
            <div className="half-fuied">
              <Form.Label>
                User Name<span>*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="user_name"
                disabled={isUpdate?true:false}
                placeholder="Enter User Name"
                onChange={handleChange}
                value={values.user_name}
                autoComplete="off"
              />
              {errors.user_name && touched.user_name && (
                <Form.Text className="text-danger">
                  {errors.user_name}
                </Form.Text>
              )}
            </div>
            <div className="half-fuied input-password">
              <Form.Label>
                Password<span>*</span>
              </Form.Label>
              <Form.Control
                // type="password"
                type={showPassword ? 'text' : 'password'}
                name="password"
                placeholder="Enter Password"
                onChange={handleChange}
                value={values.password}
              />
              <InputGroup.Text id="basic-addon2">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
              </InputGroup.Text>
              {errors.password && touched.password && (
                <Form.Text className="text-danger">{errors.password}</Form.Text>
              )}
            </div>
          </Form.Group>

          <Form.Group className="form-group half-fuied-main" controlId="formBasicEmail" >
            <div className="half-fuied">
              <Form.Label>
                Grade Label<span>*</span>
              </Form.Label>
              <Form.Select
                name="grade_level_id"
                value={values.grade_level_id}
                onChange={handleChange}
              >
                <option value="">Select Grade Level</option>
                {gradeData.map((elem, index) => (
                  <option value={elem.gradeId} key={index}>
                    {elem.gradeName}
                  </option>
                ))}
              </Form.Select>
              {errors.grade_level_id && touched.grade_level_id && (
                <Form.Text className="text-danger">
                  {errors.grade_level_id}
                </Form.Text>
              )}
            </div>
            <div className="half-fuied">
              <Form.Label> Math Ability Level</Form.Label>
              <Form.Select
                name="ability_level_id"
                onChange={handleChange}
                value={values.ability_level_id}
              >
                <option value="">Select Math Ability Level</option>
                {gradeData.map((elem, index) => (
                  <option value={elem.gradeId} key={index}>
                    {elem.gradeName}
                  </option>
                ))}
              </Form.Select>
              {errors.ability_level_id && touched.ability_level_id && (
                <Form.Text className="text-danger">
                  {errors.ability_level_id}
                </Form.Text>
              )}
            </div>
          </Form.Group>

          {/* <Form.Group
            className="form-group full-fuied"
            controlId="formBasicstudent"
          >
            <Form.Label>Student ID</Form.Label>
            <Form.Control
              type="text"
              name="id"
              placeholder="Enter Student Id"
            />
          </Form.Group> */}
          <Form.Group className="form-group half-fuied-main" controlId="formBasicEmail" >
            <div className="half-fuied">
              <Form.Label>Parent E-mail 1</Form.Label>
              <Form.Control
                type="email"
                name="parent_email_1"
                placeholder="Enter Parent E-mail 1"
                onChange={handleChange}
                value={values.parent_email_1}
                autoComplete="off"
              />
              {/* {errors.email && touched.email && (
                <Form.Text className="text-danger">{errors.email}</Form.Text>
              )} */}
            </div>
            <div className="half-fuied">
              <Form.Label>Parent E-mail 2</Form.Label>
              <Form.Control
                type="email"
                name="parent_email_2"
                placeholder="Enter Parent E-mail 2"
                onChange={handleChange}
                value={values.parent_email_2}
                autoComplete="off"
              />
              {/* {errors.email && touched.email && (
                <Form.Text className="text-danger">{errors.email}</Form.Text>
              )} */}
            </div>
          </Form.Group>
          {/* <Form.Group
            className="form-group half-fuied-main"
            controlId="formBasicEmail"
          >
            <div className="half-fuied">
              <Form.Label>Assigned Class<span>*</span></Form.Label>
              <Form.Select
                name="class_id"
                value={values.class_id}
                onChange={handleChange}
              >
                <option value="">Select Class</option>
                {props.classData.map((elem, index) => (
                  <option value={elem.id} key={index}>
                    {elem.className}
                  </option>
                ))}
              </Form.Select>
              {errors.class_id && touched.class_id && (
                <Form.Text className="text-danger">{errors.class_id}</Form.Text>
              )}
            </div>
          </Form.Group> */}

          <div className="student-pop-footer">
            <i>Required Information</i>
            <Button variant="primary" type="submit" className="yellow-button">
              Submit              
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};

export default AddEditStudent;
