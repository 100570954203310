import React from 'react'
import "./Testimonial.css"
import { TestimonialSlider } from './TestimonialSlider';

export const Testimonial = (props) => {
  const  testimonial  = props.testimonialData.testimonial;
  return (
    <>
      {
        testimonial.length > 0 && (
          <section className="testimonial home-testimonial">
              <div className="container">
                  <div className="main-title">
                      <h2>Testimonial</h2>
                  </div>
                  
                  <div className="testimonial-slider">
                      <TestimonialSlider Testimonial={testimonial}/>
                  </div>
              </div>
          </section>
        )
      }
    </>
  )
}
