import React,{useEffect,useContext,useState} from "react"
import Accordian from '../../component/Accordian'
import BannerComponent from "../../component/BannerComponent";
import CommonTable from "../../component/CommonTable"
import { UserContext } from '../../App'
import { callApi } from "../../ApiCalls";
import {Tabs,Tab,Box,Typography} from "@mui/material";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
      <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      >
      {value === index && (
          <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
          </Box>
      )}
      </div>
  );
}
function a11yProps(index) {
  return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
      "tab-value" : index,
  };
}
const Award = () => {

  const { state, dispatch } = useContext(UserContext);
    
  const [value, setValue] = useState(0);
  const [classData,setClassData] = useState([])
  const [studentData, setStudentData] = useState([])
  const [activeTab,setActiveTab] = useState(0)

  const handleChange = async(event, newValue) => {
    // var classId = event.target.getAttribute("tab-value")
    // const response = await callApi(`get-student/${classId}`);
    // setStudentData(response.data)
    // setActiveTab(classId)
    setValue(newValue);
  };

  useEffect(()=>{
    async function getStudentData() {
        var classData = await callApi("get-class");
        setClassData(classData)
        if(classData.data.length > 0){
            setActiveTab(classData.data[0].id)
            const response = await callApi(`get-student/${classData.data[0].id}`);
            setStudentData(response.data)
        }
    }
    if(state.type == 5)(
        getStudentData()
    )
},[])

  const column = ["Date","Last Name","First Name","Lessons","View","Print"]
  const column2 = ["Date","Lesson","award","View","Print"]

  return (
    <>
      <BannerComponent title="Awards"/>
      <div className='award-sec-wrap'>
        <div className="upcomingWebinars-main">
          <div className="container">
            <div className="upcomingWebinars-main-wraper">
              {/* <CommonTable column={column} rowData={[]} type="awards" filter="select"/> */}
              {state.type == 5 && classData.data && classData.data.length > 0 ? (
              <>                          
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className="tab-items">
                      <Tabs value={value} onChange={handleChange}>
                      {
                          classData.data.map((elem,index)=>(
                              <Tab label={`${elem.className}`} key={index} {...a11yProps(elem.id)} />
                          ))
                      }
                      </Tabs>
                  </Box> 
                  <div className="tab-content">
                      <TabPanel value={value} index={value} >                                                                            
                          <CommonTable column={column} rowData={[]} type="awards" filter="select"/>
                      </TabPanel>
                  </div>
              </>
              ) :
                  <CommonTable column={column} rowData={[]} type="awards" filter="select"/>
          }
            </div>
          </div>
        </div>
        <div className='common-accordion-main'>
          <div className="container">
            <div className='common-accordion-wrap'>
              <Accordian column={column2} rowData={[]} type="table" title={"Test User"} content={"elem.queAnsContent"}/>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Award