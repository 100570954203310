import React,{useState,useEffect,useRef} from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import BannerComponent from '../../component/BannerComponent'
import axios from 'axios'
import { ModalComponent } from '../../component/ModalComponent'
import EmailImage from "../../assets/images/email-verification.png";
import environment from '../../environment';
const EmailVerification = () => {

  const location = useLocation();
  const navigate = useNavigate();

  const [email,setEmail] = useState()
  const [response,setResponse] = useState()
  const [show,setShow] = useState(false)
  const [isResendEmail,setIsResendEmail] = useState(false)
  const [iscall,setIsCall] = useState()

  const handleClose = () => setShow(false)
  const isUnmountedRef = useRef(false)

  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  useEffect(()=>{
    
    if (isUnmountedRef.current == false){
      const urlArr = location.pathname.split('/')
      async function emailVefify(){
        setEmail(params.email)
        var data = {
          code : urlArr[2],
          email : params.email
        }
        await axios.post(environment.REACT_APP_API_URL+"email-verification",data).then((res)=>{
          setResponse(res.data.statusCode)
        }).catch((err)=>{
          console.log(err,"Error")
        })
      }
      async function resendEmailVerification(){
        var data = {
          code : urlArr[2],
          email : params.email
        }
        await axios.post(environment.REACT_APP_API_URL+"resend-email-verification",data).then((res)=>{
          setResponse(res.data.statusCode)
        }).catch((err)=>{
          console.log(err,"Error")
        })
      }
      if(params.email != null){
        if(params.action == null) {
          setIsResendEmail(false)
          emailVefify()
        }
        else {
          setIsResendEmail(true)
          resendEmailVerification()
        }
      } else {
        navigate("/")
      }
    } 
    return () => { isUnmountedRef.current = true }

  },[])
  return (
    <>
      <BannerComponent title={params.action == "resend" ? "Resend Verification Email" : "Email Verification"} />
      {
        isResendEmail ? (
          <div className='verification-wrap'>
            <div className='container'>
            <div className='box-wrap'>
              <div className='img-wrap'>
                <img src={EmailImage} />
              </div>
            {
              response == 2002 ? (
                <p>Your email is already verified.</p>
              ) : (
                <p>New email verification link is sent to your email address.</p>
              )
            }
            </div>
            </div>
          </div>
        ) : (
          <div className='verification-wrap'>
            <div className='container'>
              <div className='box-wrap'>
                <div className='img-wrap'>
                  <img src={EmailImage} />
                </div>
                <h3>Hello !</h3>
                {
                  response == 200 ? (
                    <p>Your email <b>{email}</b> is successfully verified!</p>
                  ) : response == 2001 ? (
                    <p>Verification link expired.</p>
                  ) : (
                    <p>Your email is already verified.</p>
                  )
                }
                <p className='m-0'>Now you can login <Link onClick={()=>{setShow(true)}}>Click here</Link></p> 
              </div>
            </div>
          </div>
        )
      }
      {
        show && 
          <ModalComponent show={show} handleClose={handleClose} type={"Login"} title="Login"/>
      }
    </>
  )
}

export default EmailVerification
