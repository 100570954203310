import React,{ useState,useEffect,useContext } from 'react'
import {MenuItem,FormHelperText,FormControl,FormGroup,FormControlLabel,Checkbox,TextField,Stack} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import {Form,Button} from "react-bootstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { callApi } from '../../ApiCalls';
import { useLocation } from 'react-router-dom';
import swal from 'sweetalert';
import { UserContext } from '../../App';
import { DatePicker , LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from "moment";

const AssignAssignment = (props) => {

  const location = useLocation();
  const { state, dispatch } = useContext(UserContext);

  const [classData,setClassData] = useState([])
  const [isParent,setIsParent] = useState(false)
  const [childData,setChildData] = useState([])
  const [assignTo,setAssignTo] = useState([])
  const [assignee,setAssignee] = useState(0)
  const [selectEndDate,setSelectEndDate] = useState("")
  const [selectStartDate,setSelectStartDate] = useState("")
  const [isDisable, setIsDisable] = useState(false);
  const [lessonType,setLessonType] = useState(false)

  const date = new Date();
  let day = ('0' + date.getDate()).slice(-2);
  let days = ('0' + (date.getDate()+7)).slice(-2);
  let minEndDay = ('0' + (date.getDate()+1)).slice(-2);
  let month = ('0' + (date.getMonth()+1)).slice(-2);
  let year = date.getFullYear();

  const startDate = `${year}-${month}-${day}`;
  const endDate = moment(date).add(7, 'd').format('YYYY-MM-DD');
  const endMinDay = `${year}-${month}-${minEndDay}`
  const maxEndDate = moment(date).add(3, 'M').format('YYYY-MM-DD');

  const handleClass = async(e) => {
    const {value} = e.target;
    const response = await callApi(`get-student/${value}`);
    setChildData(response.data)
    response.data.map((elem)=>{
      setAssignTo(oldArray => [elem.userId,...oldArray] );
    })
  }
  const handleStudent = (e) => {
    const {value} = e.target;
    if(value === 0){
      if(isParent){
        childData.map((elem)=>{
          setAssignTo(oldArray => [elem.childId,...oldArray] );
        })
      } else {
        childData.map((elem)=>{
          setAssignTo(oldArray => [elem.userId,...oldArray] );
        })
      }
    } else {
      setAssignTo([])
    }
    setAssignee(value)
  }
  const handleDate = (e,type) => {
    if(type === "startDate") {
        if(e != endDate)
            var newDate = moment(e).add(1, 'd').format('MM-DD-YYYY')
        else 
            var newDate = moment(e).format('MM-DD-YYYY')
        setSelectEndDate(newDate)
    } else {
        var newDate = moment(e).subtract(1, 'd').format('MM-DD-YYYY')
        setSelectStartDate(newDate)
    }
  }

  const handleChildAssignee = (e,childId) => {
    const index = assignTo.indexOf(childId);
    if (index > -1) { 
      assignTo.splice(index, 1); 
    } else {
      assignTo.push(childId)
    }
  }
  const handleStudentAssignee = (e,childId) => {
    const index = assignTo.indexOf(childId);
    if (index > -1) { 
      assignTo.splice(index, 1); 
    } else {
      assignTo.push(childId)
    }
  }
  const userSchema1 = Yup.object({
    assign_to: Yup.string().typeError().required("Please select user whoom you want to assign"),
    is_complete: Yup.string().required("Assignment status is required"),
    lesson_type: Yup.string().required("Lesson completion type is required"),
    auto_reassign: Yup.string().required("Auto re-assignment is required"),
    number_of_questions: Yup.string().when('lesson_type', {
      is: (value) => lessonType == 0,
      then: Yup.string().required('Number of questions is required'),
      otherwise: Yup.string()
    }),
  });
  const userSchema2 = Yup.object({
    class_id: Yup.string().required("Class is required"),
    assign_to: Yup.string().typeError().required("Please select user whoom you want to assign"),
    is_complete: Yup.string().required("Assignment status is required"),
    lesson_type: Yup.string().required("Lesson completion type is required"),
    auto_reassign: Yup.string().required("Auto re-assignment is required"),
    number_of_questions: Yup.string().when('lesson_type', {
      is: (value) => lessonType == 0,
      then: Yup.string().required('Number of questions is required'),
      otherwise: Yup.string()
    }),
  });

  const initialValues = {
    class_id : "",
    assign_to: 0,
    is_complete: 0,
    lesson_type:0,
    auto_reassign:0,
    start_date: startDate,
    end_date: endDate,
    lesson_time: "5",
    number_of_questions: "",
    reassign_percentage: "50",
  };

  const { values, touched, errors, handleChange, handleSubmit, setFieldValue } = useFormik({
    initialValues: initialValues,
    validationSchema: isParent ? userSchema1 : userSchema2,
    onSubmit: async (values, { resetForm }) => {
      setIsDisable(true)
      function onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
      }
      values["lesson_id"] = props?.lessonId?.id
      values["assign_to"] = assignTo.filter(onlyUnique)
      if(values["is_complete"] == 0){
        values["start_date"] = "";
        values["end_date"] = "";
      }
      if(values["lesson_type"] == 0){
        values["lesson_time"] = "";
      } else if (values["lesson_type"] == 1) {
        values["number_of_questions"] = "";
      }
      if(values["auto_reassign"] == 0){
        values["reassign_percentage"] = "";
      }
      var response = await callApi('add-assignment',values);
      if (response.statusCode == 200) {
        resetForm();
        props?.getData()
        props?.handleClose();
      } else {
        setIsDisable(false)
      }
    },
  });

  useEffect(()=>{
    async function getData() {
      if(state.type == 3){
        setIsParent(true)
        var userId = parseInt(localStorage.getItem("userId"));
        const response = await callApi(`my-children/${userId}`);
        setChildData(response.data.childData.data)
        response.data.childData.data.map((elem)=>{
          setAssignTo(oldArray => [elem.childId,...oldArray] );
        })
      } else {
        const classData = await callApi("get-class");
        setClassData(classData.data)
      }
    }
    getData(); 
    const date = new Date();
    const endDate = moment(date).add(7, 'd').format('YYYY-MM-DD');
    setSelectStartDate(endDate)
  },[])

  return (
    <div className={`${isDisable?"modal-disable":""}`}>
      <Form autoComplete="false" onSubmit={handleSubmit}>
        {
          !isParent && 
        <div className={"accordion-custom"}>
          <div className='custom-accordion-wap'>
            <strong>Choose a class:</strong>
            <div className='custom-accordion-wrap'>
              <FormControl sx={{ m: 1, minWidth: 120 }}>
                <Select
                  name="class_id"
                  value={values.class_id}
                  onChange={(e)=>{handleChange(e);handleClass(e)}}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  <MenuItem value="">None</MenuItem>
                  {
                    classData.map((elem,index)=>(
                      <MenuItem value={elem.id} key={index}>{elem.className}</MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </div>
            {errors.class_id && touched.class_id && (
              <FormHelperText className="text-danger">
                {errors.class_id}
              </FormHelperText>
            )}
          </div>
        </div>
        }
        <div className='accordion-custom'>
          <div className='custom-accordion-wap'>
            <strong>Assign to:</strong>
            <div className='custom-accordion-wrap'>
              <FormControl sx={{ m: 1, minWidth: 120 }}>
                <Select
                  name="assign_to"
                  value={assignee}
                  onChange={(e)=>{handleChange(e);handleStudent(e)}}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  <MenuItem value={0} >All {state.type == 3 ? "children" : "students"}</MenuItem>
                  <MenuItem value={1} >Some {state.type == 3 ? "children" : "students"}</MenuItem>
                </Select>
              </FormControl>
              <div className={ assignee === 1 ? "number active" :  "number"}>
                <div className="inner-title"><strong>{state.type == 3 ? "Children" : "Student"} list</strong></div>
                <FormGroup className='select-student-list'>
                  {
                    isParent ?
                      childData.map((elem,index)=>(
                        <React.Fragment key={index}>
                            <FormControlLabel control={<Checkbox defaultChecked={false} onChange={(e)=>handleChildAssignee(e,elem.childId)}/>} label={elem.firstName} />
                          </React.Fragment>
                        ))
                    :
                      childData.map((elem,index)=>(
                        <React.Fragment key={index}>
                            <FormControlLabel control={<Checkbox defaultChecked={false} onChange={(e)=>handleStudentAssignee(e,elem.userId)}/>} label={elem.fullName} />
                          </React.Fragment>
                        ))  
                  }
                </FormGroup>
              </div>
            </div>
            {errors.assign_to && touched.assign_to && (
              <FormHelperText className="text-danger">
                {errors.assign_to}
              </FormHelperText>
            )}
          </div>
        </div>
        <div className='accordion-custom'>
          <div className='custom-accordion-wap'>
            <strong>Display lesson on {state.type == 3 ? "Children" : "Student"}'s page:</strong>
            <div className='custom-accordion-wrap'>
              <FormControl sx={{ m: 1, minWidth: 120 }}>
                <Select
                  value={values.is_complete}
                  name="is_complete"
                  onChange={handleChange}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  <MenuItem value={0} >Until they've completed lessons</MenuItem>
                  <MenuItem value={1} >For a certain date range</MenuItem>
                </Select>
              </FormControl>
              <div className={ values.is_complete === 1 ? "number active" :  "number"}>
                <div className='custom-accordion-data-picker-wrap'>
                  <Stack component="form" noValidate spacing={3} className="custom-accordion-datapicker">
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        inputFormat={"MM-DD-YYYY"}
                        name="start_date"
                        onChange={(e) => {
                          setFieldValue("start_date",moment(e).format(`YYYY-MM-DD`))
                          handleDate(moment(e).format(`YYYY-MM-DD`),"startDate")
                        }}
                        maxDate	= {selectStartDate}
                        minDate = {startDate}
                        label="start date"
                        value={values.start_date}
                        renderInput={(params) =>
                          <TextField
                            {...params}
                            onKeyDown={(e)=>e.preventDefault()}
                            variant="outlined"
                            autoComplete={"off"}
                            inputProps={{
                              ...params.inputProps,
                              readOnly: true
                            }}
                          />
                        }
                      />
                    </LocalizationProvider>
                  </Stack>
                  <span className="custom-to">To</span>
                  <Stack component="form" noValidate spacing={3} className="custom-accordion-datapicker">
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        inputFormat={"MM-DD-YYYY"}
                        name="end_date"
                        onChange={(e) => {
                          setFieldValue("end_date",moment(e).format(`YYYY-MM-DD`))
                          handleDate(moment(e).format(`YYYY-MM-DD`),"EndDate")
                        }}
                        maxDate = {maxEndDate}
                        minDate = {selectEndDate?selectEndDate:endMinDay}
                        label="End date"
                        value={values.end_date}
                        renderInput={(params) =>
                          <TextField
                            {...params}
                            onKeyDown={(e)=>e.preventDefault()}
                            variant="outlined"
                            autoComplete={"off"}
                            inputProps={{
                              ...params.inputProps,
                              readOnly: true
                            }}
                          />
                        }
                      />
                    </LocalizationProvider>
                  </Stack>
                </div>
              </div>
            </div>
            {errors.is_complete && touched.is_complete && (
              <FormHelperText className="text-danger">
                {errors.is_complete}
              </FormHelperText>
            )}
          </div>
        </div>
        <div className='accordion-custom'>
          <div className='custom-accordion-wap'>
            <strong>To complete this assignment, I want {state.type == 3 ? "Children" : "Students"} to:</strong>
            <div className='custom-accordion-wrap'>
              <FormControl sx={{ m: 1, minWidth: 120 }}>
                <Select
                  value={values.lesson_type}
                  name="lesson_type"
                  onChange={(e)=>{handleChange(e);setLessonType(true)}}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  <MenuItem value={0} >Complete a certain number of problems</MenuItem>
                  <MenuItem value={1} >Work for a certain amount of time</MenuItem>
                </Select>
              </FormControl>
              <div className={`${values.lesson_type}?"active":""`+"number"}>
                {
                  values.lesson_type === 0 ? (
                    <div className='custom-accordion-data-picker-wrap'>
                      <strong>Number of questions:</strong>
                      <FormControl sx={{ m: 1, minWidth: 120 }}>
                        <Select
                          value={values.number_of_questions}
                          name="number_of_questions"
                          onChange={handleChange}
                          displayEmpty
                          inputProps={{ 'aria-label': 'Without label' }}
                        >
                          <MenuItem value="">Select questions</MenuItem>
                          {(() => {
                            let option = [];
                            for (let i = 1; i <= props?.lessonId?.totalNumberOfQuestion; i++) {
                              option.push(<MenuItem value={i}>{i}</MenuItem>);
                            }
                            return option;
                            }
                          )()}
                        </Select>
                        {errors.number_of_questions && touched.number_of_questions && (
                          <FormHelperText className="text-danger">
                            {errors.number_of_questions}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </div>
                  ) : values.lesson_type === 1 ? (
                    <div className='custom-accordion-data-picker-wrap'>
                      <strong>Choose time limit:</strong>
                      <FormControl sx={{ m: 1, minWidth: 120 }}>
                        <Select
                          value={values.lesson_time}
                          name="lesson_time"
                          onChange={handleChange}
                          displayEmpty
                          inputProps={{ 'aria-label': 'Without label' }}
                        >
                          <MenuItem value={5} >5 minutes</MenuItem>
                          {(() => {
                            let option = [];
                            for (let i = 10; i <= 60; i+=10) {
                              option.push(<MenuItem value={i} >{i} minutes</MenuItem>);
                            }
                            return option;
                            }
                          )()}
                        </Select>
                      </FormControl>
                    </div>
                  ) : ""
                }
              </div>
            </div>
            {errors.lesson_type && touched.lesson_type && (
              <FormHelperText className="text-danger">
                {errors.lesson_type}
              </FormHelperText>
            )}
          </div>
        </div>
        <div className='accordion-custom'>
          <div className='custom-accordion-wap'>
            <strong>Automatically reassign if {state.type == 3 ? "children" : "student"} score below a certain percentage:</strong>
            <div className='custom-accordion-wrap'>
              <FormControl sx={{ m: 1, minWidth: 120 }}>
                <Select
                  value={values.auto_reassign}
                  name="auto_reassign"
                  onChange={handleChange}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  <MenuItem value={0}>No</MenuItem>
                  <MenuItem value={1}>Yes</MenuItem>
                </Select>
              </FormControl>
              <div className={ values.auto_reassign === 1 ? "number active" :  "number"}>
                <div className='custom-accordion-data-picker-wrap'>
                  <strong>Auto reassign if score is lower than:</strong>
                  <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <Select
                      value={values.reassign_percentage}
                      name="reassign_percentage"
                      onChange={handleChange}
                      displayEmpty
                      inputProps={{ 'aria-label': 'Without label' }}
                    >
                      {(() => {
                        let option = [];
                        for (let i = 50; i <= 100; i++) {
                          if(i % 5 == 0)
                            option.push(<MenuItem value={i}>{i}%</MenuItem>);
                        }
                        return option;
                        }
                      )()}
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>
            {errors.auto_reassign && touched.auto_reassign && (
              <FormHelperText className="text-danger">
                {errors.auto_reassign}
              </FormHelperText>
            )}
          </div>
        </div>
        <Button variant="primary" type="submit" className="yellow-button mt-4">Assign</Button>
      </Form>
    </div>
  )
}

export default AssignAssignment