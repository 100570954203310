import React, { useState, useEffect } from "react";
import BannerComponent from "../../component/BannerComponent";
import {Tabs,Tab,Box,Typography} from "@mui/material";
import { callApi, dateConverter } from "../../ApiCalls";
import Spinner from "../../component/Loader/Spinner";
import Accordian from "../../component/Accordian"
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import swal from "sweetalert";
import Redcross from "../../assets/images/red-cross.png";
import environment from '../../environment';
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
    "tab-value" : index,
  };
}
const IndividualAssignment = () => {
  
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const [classData,setClassData] = useState([])
  const [assignmentData, setAssignmentData] = useState([])
  const [activeTab,setActiveTab] = useState(0)

  // const deleteId= assignmentData.data.map((elem,index)=>{
  //   return elem.id
  // })
  // console.log("deleteId",deleteId)

  const handleChange = async(event, newValue) => {
    setValue(newValue);
    var classId = event.target.getAttribute("tab-value")
    const assignmentData = await callApi(`get-individual-assignments/${classId}`);
    setAssignmentData(assignmentData)
    setActiveTab(classId)
  };
  const deleteAssignment = (id,userId = null) => {
    swal({
        title: "Are you sure, You want to delete ?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
    })
    .then((willDelete) => {
        if (willDelete) {
            deleteRecord(id,userId)
            swal("Record has been deleted!", {
                icon: "success",
                timer: 2000,
            });
        } 
    });
}

  const deleteRecord = async(assignmentId,userId) => {
    var token = localStorage.getItem("token");
    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
    };
    var data = {
      assignment_id : assignmentId,
      assign_to : userId,
    }
    await axios.post(environment.REACT_APP_API_URL + "delete-child-student-assignment",data,{
      headers: headers,
    }).then((res) => {
      if(res.data.statusCode == 200){
          toast.success("Assignment has been deleted!");
          getData()
      }
    }).catch((err) => {
      console.log(err, "Error");
    });
  }

  async function getData(){
    var assignmentData = await callApi(`get-individual-assignments/${activeTab}`)
    setAssignmentData(assignmentData) 
  }

  useEffect(() => {
    async function getData() {
      const classData = await callApi("get-class");
      setClassData(classData)
      if(classData.data.length > 0){
        setActiveTab(classData.data[0].id)
        const assignmentData = await callApi(`get-individual-assignments/${classData.data[0].id}`);
        setAssignmentData(assignmentData)
      }
    }
    getData();
  }, []);
  
  var columns = [
    {
      dataField: "assignedDate",
      text: "Date",
      formatter : (cell,row) => {
        return dateConverter(cell)
      }
    },
    {
      dataField: "lessonName",
      text: "Lesson",
      sort: true
    },
    {
      dataField: "dueDate",
      text: "Due Date",
      formatter : (cell,row) => {
        return cell == 0 ? "Until Completed":dateConverter(cell)
      }
    },
    {
      dataField: "delete",
      text: "Delete",
      formatter: (cell, row, rowIndex) => {
          return (
            <div className='action-btns' onClick={()=>deleteAssignment(row.id)}><img src={Redcross} /></div>
          );
      }
    }
];

  return (
    <>
      {
        classData.data ? (
          <div className='account-main'>
            <BannerComponent title="Individual Assignment"/>
            <div className="award-sec-wrap">
              <div className='common-accordion-main'>
                <div className="container">
                <strong>This page displays all active assignments that student currently have.</strong>
                  { 
                    (classData.data && classData.data.length > 0) && (
                      <>                          
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className="tab-items">
                          <Tabs value={value} onChange={handleChange}>
                            {
                              classData.data.map((elem,index)=>(
                                <Tab label={`${elem.className}`} {...a11yProps(elem.id)} />
                              ))
                            }
                          </Tabs>
                        </Box> 
                        <div className="tab-content">
                          {
                            (assignmentData.data && assignmentData.data.length > 0) ? (
                              <>
                                <TabPanel value={value} index={value} >
                                      <div className='common-accordion-wrap'>
                                        {/* <Accordian type="table" title={"Hardik"} content={"elem.queAnsContent"}/> */}
                                        {
                                          assignmentData.data.map((elem,index)=>(
                                            <React.Fragment key={index}>
                                              <Accordian columns={columns} rowData={elem} deleteRecord={deleteRecord} type="table" title={elem?.studentName} content={"elem.queAnsContent"}/>
                                            </React.Fragment>
                                          ))
                                        } 
                                      </div>                                    
                                </TabPanel>
                              </>
                            ) : (
                              <label className="p-4 w-100 text-center">No Assignment Found</label>
                            ) 
                          }
                        </div>
                      </>
                    ) 
                  }
                </div>
              </div>
            </div>
          </div>
        ) : <Spinner/>
      }
    </>
  );
};

export default IndividualAssignment;