import React, { useEffect, useState } from 'react'
import { Link, useLocation,useNavigate } from 'react-router-dom';
import BannerComponent from '../../component/BannerComponent';
import Spinner from '../../component/Loader/Spinner';
import { callApi, dateConverter } from '../../ApiCalls';

const LessonReport = () => {

  const location = useLocation();

  const [lessonReport,setLessonReport] = useState({})
  const [isLoder,setIsLoader] = useState(false)
  const pathNavigate = window.location.pathname.split('/')[1];
  const childNavigate = window.location.pathname.split('/')[2];
  const navigate = useNavigate();
  const assignmentProgressReport = (id,type) => {
    navigate({
      pathname:`/my-progress/assignment-progress-report/${id}`,
      search: `?type=${type}`
    });
  }
  useEffect(() => {
    async function getData() {
      var assignmentId = location.pathname.substring(location.pathname.lastIndexOf('/') + 1)
      var response = await callApi(`get-lesson-report/${assignmentId}`)
      setLessonReport(response.data);
      setIsLoader(true)
    }
    getData();
  }, []);
  return (
    <>
      <BannerComponent title="Lesson Report"  navigate={pathNavigate} childNavigate={childNavigate}/>
      {
        isLoder ? (
          <>
            <section className='lesson-report-wrap'>
              <div className='container'>
                <div className='lesson-info'>
                  <div className='details'>
                    <ul>
                      <li><span>User Name:</span>{lessonReport.name}</li>
                      <li><span>Lesson Name:</span>{lessonReport.lessonName}</li>
                      <li><span>Level:</span>{lessonReport.level == null ? "-" : lessonReport.level}</li>
                    </ul>
                  </div>

                  <div className='score'>
                    <h4 className='sub-title'>Score</h4>
                    <ul>
                      <li><span>Score:</span>{lessonReport.score}</li>
                      <li><span>Percentage:</span>{lessonReport.percentage}</li>
                    </ul>
                  </div>

                  <div className='question-answer'>
                    <h4 className='sub-title'>Questions and Answers</h4>
                    <ul>
                      <li><p className='info'>View all Questions and Answers</p> <div className="yellow-button" onClick={()=>assignmentProgressReport(lessonReport.assignmentId,"all")}>View</div></li>
                      <li><p className='info'>View only Incorrect Questions and Answers</p> <div className="yellow-button" onClick={()=>assignmentProgressReport(lessonReport.assignmentId,"incorrect")}>View</div></li>
                    </ul>
                  </div>

                  <div className='more-info'>
                    <h4 className='sub-title'>More Information</h4>
                    <ul>
                      <li><span>Time Spent:</span>{lessonReport.timeSpent}</li>
                      <li><span>Date-Time Completed:</span>{dateConverter(lessonReport.completedDate)}</li>
                      <li><span>Total Number of Questions Answered:</span>{lessonReport.totalQuestions}</li>
                      <li><span>Lesson Complete?</span>{lessonReport.lessonCompleted}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </section>
          </>
        ) : <Spinner/>
      }
    </>
  )
}

export default LessonReport
