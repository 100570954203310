import React from 'react'

const aboutSectionTwo = (props) => {
    const aboutSectionTwo = props.contentData
    return (
        <>
        <section className="creating_class_rosters">
            <div className="container">
                <div className="creating_class_rosters_wrap">
                    <span>{aboutSectionTwo.sectionContent.subTitle}</span>
                    <img src={aboutSectionTwo.sectionContent.image} />
                </div>
            </div>
        </section>
        </>
    )
}

export default aboutSectionTwo
