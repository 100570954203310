import React from 'react'

const BlogInfo = (props) => {
    const {profilePhoto,clientName,subDescription,subText} = props?.infoData;
    return (
        <div>
            <img src={profilePhoto} />
            <div className="avtar-name">
                <h5>{clientName}</h5>
                {/* <span>dummy text of the printing </span> */}
            </div>
            <p>{subDescription}</p>
            <p>{subText}</p>
        </div>
    )
}

export default BlogInfo