import React, { useState, useEffect, useContext} from "react";
import { Link } from "react-router-dom";
import { useSpeechSynthesis } from 'react-speech-kit';
import Speaker from "../../assets/images/speaker.png";
import { ModalComponent } from "../ModalComponent";
import Progress from "./Progress";
import { toast } from "react-toastify";
import LessonComplete from "./LessonComplete";
import MangeTemplate from "./ManageTemplate";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { callApi, postRequest } from "../../ApiCalls";
import { UserContext } from "../../App";
import GblObject from "../../GblData";
import environment from '../../environment';

const Question = (props) => {
  
  const question = props?.question;
  const userProgress = props?.userProgress;

  const { state, dispatch } = useContext(UserContext);

  const location = useLocation();
  var urlArr = location.pathname.split('/')

  const [show, setShow] = useState(false);
  const [showBoard, setShowBoard] = useState(false)
  const [hint, setHint] = useState(false)
  const [showInfo,setShotInfo] = useState(false)
  const [answer,setAnswer] = useState("")
  const { speak, voices } = useSpeechSynthesis()
  const [correctAns,setCorrectAns] = useState(0)
  const [ratio,setRatio] = useState(0);
  const [progress,setProgress] = useState(0)
  const [score,setScore] = useState(0)
  const [videoAnimation,setVideoAnimation] = useState("Idle")
  const [lastQuestion, setLastQuestion] = useState(false)
  const [lessonTime,setLessonTime] = useState(0)
  const [seconds,setSeconds] = useState(0)
  const [isDisabled,setIsDisabled] = useState(true)
  const [isExpired,setIsExpired] = useState(false)
  const [userSetting,setUserSetting] = useState([])
  const [userQuestionProgress,setUserQuestionProgres] = useState(0)

  const expiredInfo = "Times up! You may finish answering the current question and then proceed to your score!"

  const handleClose = () => {
    setShow(false);
    setShowBoard(false)
    setHint(false);
    setShotInfo(false)
  };
  
  const checkAnswer = (data,type=null) => {
    if(Array.isArray(data)){
      setAnswer(data.sort())
    } else if(type =="noSort"){
      setAnswer(data)
    }else {
      setAnswer(data)
    }
  }

  const textToSpeech = (question) => {
    if(question.length >= 150) {
      return 0;
    } else {
      if (navigator.userAgent.indexOf('Mac OS X') != -1) {
        var iosDevice = [
          'iPad Simulator',
          'iPhone Simulator',
          'iPod Simulator',
          'iPad',
          'iPhone',
          'iPod'
        ];

        if(iosDevice.includes(navigator.platform))
          return speak({ text: question, voice: voices[50], rate: 1, pitch: 1  })
        else
          return speak({ text: question, rate: 1, pitch: 1})

      } else {
        if(navigator.userAgent.match(/Android/i)){
          return speak({ text: question, rate: 1, pitch: 1  })
        } else {
          return speak({ text: question, voice: voices[4], rate: 1, pitch: 1  })
        }
      }
    }
  }
  const loadQuestion = () => {
    if(!isExpired && ((Object.keys(userProgress).length !== 0?userProgress?.totalAttempt+1:props?.currentpage) != props.queLength))
      props?.submit()
    else 
      setLastQuestion(true);
  }
  const handleSubmit = async(result,answer) => {
    setIsDisabled(false)
    const correctAnswer = question?.questionData?.correct_answer
    var is_correct = 0
    if(result == true){
      setCorrectAns((preVal)=>preVal+1)
      setScore((preVal)=>preVal+ratio)
      setVideoAnimation('Right')
      toast.success("Right Answer");
      is_correct = 1
    } else {
      setVideoAnimation('Wrong')
      toast.error("Wrong Answer");
      is_correct = 0
    }
    var token = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    setUserQuestionProgres((preVal)=>preVal+1)
    setProgress((preVal)=>preVal+ratio)
    GblObject.totalAttempt = GblObject.totalAttempt+1
    if(Object.keys(userProgress).length !== 0){
      var is_last = 0
      if(!isExpired && ((Object.keys(userProgress).length !== 0?userProgress?.totalAttempt+1:props?.currentpage) != props.queLength)){
        is_last = 0
      } else {
        is_last = 1
      }
      var data = {
        assignment_id : question.assignmentId,
        question_id : question.id,
        users_ans : answer.toString(),
        attempt_time : seconds,
        que_time : "00:00:00",
        is_correct : is_correct,
        is_last : is_last
      }
      await axios.post(environment.REACT_APP_API_URL +"store-answer",data,{ headers: headers });
      if(is_last == 1)
        await axios.post(environment.REACT_APP_API_URL +"assign-badge",{},{ headers: headers });
    }else {
      var data = {
        slug : urlArr.slice(-1)[0],
        question_id : question.id
      }
      var isLast = 0
      if(props?.currentpage != props.queLength){
        isLast = 0
      } else {
        isLast = 1
      }
      var browsedData = {
        question_id : question.id,
        users_ans: answer.toString(),
        attempt_time: seconds,
        is_correct: is_correct,
        is_last: isLast,
        slug:urlArr.slice(-1)[0]
      }
      await axios.post(environment.REACT_APP_API_URL +"store-lessons-answer",data,{ headers: headers });
      if(state.type == 4 || state.type == 6)
        await postRequest("store-free-question-answer",browsedData)
    }
    setAnswer("")
    if(state.type == 4 || state.type == 6) {
      if(GblObject.animation != 0 || GblObject.studentAnimation != 3) {
        if(!isExpired && ((Object.keys(userProgress).length !== 0?userProgress?.totalAttempt+1:props?.currentpage) != props.queLength))
          props?.submit()
        else 
          setLastQuestion(true);
      }
    }
    setSeconds(0)
    setIsDisabled(true)
  }

  const timeExpired = () => {
    setShow(true);
    setIsExpired(true)
  }
  
  useEffect(()=>{
    async function getSetting() {
      var res = await callApi("get-hint-animations-score-plan")
      GblObject.animation = res.data.animation;
      GblObject.lessons_score = res.data.lessons_score;
      GblObject.hint = res.data.hints;
      GblObject.animationSound = res.data.animationSound;
      GblObject.studentAnimation = res.data.studentAnimation;
      setUserSetting(res.data)
    }
    if(state.type != null)
      getSetting();
    var ratio = 100 / props.queLength;
    setRatio(ratio)
    setProgress(Object.keys(userProgress).length !== 0?userProgress.totalAttempt*ratio:0)
    setScore(Object.keys(userProgress).length !== 0?userProgress.totalCorrect*ratio:0)
    setCorrectAns(Object.keys(userProgress).length !== 0?userProgress.totalCorrect:0)
    setLessonTime(Object.keys(userProgress).length !== 0?userProgress.lessonTime:0)
    setUserQuestionProgres(Object.keys(userProgress).length !== 0?userProgress.totalAttempt:0)
  },[])

  useEffect(()=>{
    const interval = setInterval(() => {
      setSeconds(seconds => seconds + 1);
    }, 1000);
    return () => clearInterval(interval);
  },[progress])

  return (
    <>
      {
        !lastQuestion ? (
          <div className="questions-wrap-box">
            <div className="lessions-display">
              <div className="lession-header">
                <h4>
                  <Link onClick={() => textToSpeech((question?.questionData?.voice_text && question?.questionData?.voice_text!=null) ? question?.questionData?.voice_text :question.questionData.question_text)}>
                    <img src={Speaker} />
                  </Link>
                  {question.questionData.question_text}
                </h4>
              </div>
              
              <div className="question-display">
                <MangeTemplate questionData={question} checkAnswer={checkAnswer} handleSubmit={handleSubmit}/>
              </div>  
            </div>

            <Progress 
              userQuestionProgress={userQuestionProgress}
              progress={progress} 
              videoAnimation={videoAnimation} 
              score={score} 
              correctAns={correctAns} 
              currentpage={Object.keys(userProgress).length !== 0?userProgress?.totalAttempt+1:props?.currentpage} 
              queLength={Object.keys(userProgress).length !== 0?userProgress?.totalQuestions:props?.queLength}
              lessonTime={lessonTime}
              timeExpired = {timeExpired}
              submit={loadQuestion}
            />
          </div>
        ) : (
          <LessonComplete lessonTime={lessonTime} totalAttempt={userProgress?.totalAttempt} correctAns={correctAns} queLength={Object.keys(userProgress).length !== 0?userProgress?.totalQuestions:props?.queLength}/>
        )
      }
      { 
        show && isExpired ?
          <ModalComponent infoData={expiredInfo} show={show} handleClose={handleClose} type={"description"} className="description" title="Information" />
        : ""
      }
    </>
  );
};

export default Question;
