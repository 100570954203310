import React from 'react';
import { usePagination, DOTS } from './usePagination';
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import Button from 'react-bootstrap/Button';

const Pagination = (props) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];
  return (
    <ul className="pagination flex-wrap justify-content-center">
        <li >
          <Button  onClick={onPrevious} disabled={currentPage == 1 ? true : false} >
            <AiOutlineArrowLeft />
            </Button>
        </li>
        
        {
          paginationRange.map((pageNumber,index) => {
            if (pageNumber === DOTS) {
                return <li key={index} className="pagination-item dots"><Button>&#8230;</Button></li>;
            }

            return (
              <li key={index} onClick={() => onPageChange(pageNumber)}>
                <Button className={currentPage == pageNumber ? "active" : ""}>
                  {pageNumber}
                </Button>
              </li>
            );
          })
        }
       
        <li >
          <Button onClick={onNext}  disabled={lastPage == currentPage ? true : false} >
            <AiOutlineArrowRight />
            </Button>
        </li>
    </ul>
  );
};

export default Pagination;