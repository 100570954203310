import React from 'react'

const LessonInfo = (props) => {

    const infoData = props?.infoData;
    return (
        <p
            dangerouslySetInnerHTML={{
                __html: infoData,
            }}
        />
    )
}

export default LessonInfo