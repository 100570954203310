import React,{useState,useEffect,useContext} from "react"
import BannerComponent from "../../component/BannerComponent";
import {Form,Button,Row,Col} from "react-bootstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import {TextField,Stack} from '@mui/material';
import { callApi } from '../../ApiCalls';
import Signup from "../../assets/images/signup-img.png";
import {UserContext} from "../../App"
import axios from "axios";
import environment from '../../environment';
import { DatePicker , LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from "moment";

const PrintableProgressReport = () => {

    const { state, dispatch } = useContext(UserContext);
    
    const [selectEndDate,setSelectEndDate] = useState("")
    const [selectStartDate,setSelectStartDate] = useState("")
    const [isParent,setIsParent] = useState(false)
    const [userData,setUserData] = useState([])
    const [classData,setClassData] = useState([])

    const navigate = window.location.pathname.split('/')[1];
    const date = new Date();
    let day = ('0' + date.getDate()).slice(-2);
    let month = ('0' + (date.getMonth()+1)).slice(-2);
    let year = date.getFullYear();
    let minEndDay = ('0' + (date.getDate()+1)).slice(-2);

    const startDate = `${year-1}-${month}-${day}`;
    const endDate = `${year}-${month}-${day}`;
    const endMinDay = `${year-1}-${month}-${minEndDay}`

   
    const handleClass = async(e) => {
        const {name,value} = e.target
        const studentData = await callApi(`get-student/${value}`);
        setUserData(studentData.data)
    }
    const userSchema1 = Yup.object({
        user: Yup.string().required("User is required"),
		start_date: Yup.string().required("Start Date is required"),
        end_date: Yup.string().required("End Date is required"),
	});
    const userSchema2 = Yup.object({
		class: Yup.string().required("Class is required"),
        user: Yup.string().required("User is required"),
		start_date: Yup.string().required("Start Date is required"),
        end_date: Yup.string().required("End Date is required"),
	});
	const initialValues = {
		class : "",
        user : "",
        start_date: startDate,
        end_date: endDate,
	};
	const { values, touched, errors, handleChange, handleSubmit, setFieldValue} = useFormik({
		initialValues: initialValues,
		validationSchema: isParent ? userSchema1 : userSchema2,
		onSubmit: async (values, { resetForm }) => {
            var data = {
                assign_to:values.user,
                start_date:values.start_date,
                end_date:values.end_date,
                class_id:isParent?null:values.class
            }
            var token = localStorage.getItem("token");
            const headers = {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            };
            var windowReference = window.open();
            await axios.post(environment.REACT_APP_API_URL +"download-report",data,{ headers: headers }).then((response)=>{
                windowReference.location = response.data.data.reportUrl;
                resetForm()
            }).catch((err)=>{
                console.log(err)
            });
		},
	});

    const handleDate = (e,type) => {
        if(type === "startDate") {
            if(e != endDate)
                var newDate = moment(e).add(1, 'd').format('MM-DD-YYYY')
            else 
                var newDate = moment(e).format('MM-DD-YYYY')
            setSelectEndDate(newDate)
        } else {
            var newDate = moment(e).subtract(1, 'd').format('MM-DD-YYYY')
            setSelectStartDate(newDate)
        }
    }

    useEffect(()=>{
        async function getParentData(){
            var userId = parseInt(localStorage.getItem("userId"));
            const childData = await callApi(`my-children/${userId}`);
            setUserData(childData.data.childData.data)
        } 
        async function getTeacherDate(){
            const classData = await callApi("get-class");
            setClassData(classData.data)
        }
        if(state.type == 3){
            setIsParent(true)
            getParentData()
        } else {
            setIsParent(false)
            getTeacherDate()
        }
        let day = ('0' + date.getDate()).slice(-2);
        let month = ('0' + (date.getMonth()+1)).slice(-2);
        let year = date.getFullYear();
        var endDate = `${year}-${month}-${day}`;
        setSelectStartDate(endDate)
    },[])
    return(
        <>
            <BannerComponent title="Printable Progress Report" navigate={navigate}/>
            <div className="progress-report-wrap">
                <div className="container">
                    <div className="box-wrap">
                        <Row className="w-100">
                            <Col lg={4} md={5} xs={12}>
                                <div className="report-img-wrap">
                                    <img src={Signup} />
                                </div>
                            </Col>

                            <Col lg={8} md={7} xs={12}>
                                <Form autoComplete="false" className="report-form" onSubmit={handleSubmit}>
                                    <Form.Group className="form-group half-fuied-main">
                                        {
                                            !isParent && (
                                                <div className="half-fuied">
                                                    <Form.Label>Class<span>*</span></Form.Label>
                                                        <Form.Select 
                                                        name="class"
                                                        value={values.class}
                                                        onChange={(e)=>{handleChange(e);handleClass(e)}}
                                                        >
                                                            <option value="">Select Class</option>
                                                            {
                                                                classData && classData.map((elem,index)=>(
                                                                    <React.Fragment key={index}>
                                                                        <option value={elem.id}>{elem.className}</option>
                                                                    </React.Fragment>
                                                                ))
                                                            }
                                                        </Form.Select>
                                                        {errors.class && touched.class && (
                                                            <Form.Text className="text-danger">
                                                            {errors.class}
                                                        </Form.Text>
                                                    )}
                                                </div>
                                            )
                                        }
                                        <div className="half-fuied">
                                            <Form.Label>User<span>*</span></Form.Label>
                                                <Form.Select
                                                    name="user"
                                                    value={values.user}
                                                    onChange={handleChange}
                                                >
                                                    <option value="">Select User</option>
                                                    {
                                                        isParent ? 
                                                            userData.map((elem,index)=>(
                                                                <React.Fragment key={index}>
                                                                    <option value={elem.childId}>{elem.firstName}</option> 
                                                                </React.Fragment>
                                                            )) 
                                                        :
                                                        userData.map((elem,index)=>(
                                                            <React.Fragment key={index}>
                                                                <option value={elem.userId}>{elem.fullName}</option> 
                                                            </React.Fragment>
                                                        ))
                                                    }
                                                </Form.Select>
                                            {errors.user && touched.user && (
                                                <Form.Text className="text-danger">
                                                    {errors.user}
                                                </Form.Text>
                                            )}
                                        </div>
                                    </Form.Group>
                                    <Form.Group className="form-group full-fuied">
                                        <div className="half-fuied">
                                            <Form.Label>Date Range</Form.Label>
                                            <div className='custom-accordion-data-picker-wrap'>
                                                <Stack component="form" noValidate spacing={3} className="custom-accordion-datapicker">
                                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                                        <DatePicker
                                                            inputFormat={"MM-DD-YYYY"}
                                                            name="start_date"
                                                            onChange={(e) => {
                                                                setFieldValue("start_date",moment(e).format(`YYYY-MM-DD`))
                                                                handleDate(moment(e).format(`YYYY-MM-DD`),"startDate")
                                                            }}
                                                            maxDate	= {selectStartDate}
                                                            minDate = {startDate}
                                                            label="start date"
                                                            value={values.start_date}
                                                            renderInput={(params) =>
                                                                <TextField
                                                                    {...params}
                                                                    onKeyDown={(e)=>e.preventDefault()}
                                                                    variant="outlined"
                                                                    autoComplete={"off"}
                                                                    inputProps={{
                                                                        ...params.inputProps,
                                                                        readOnly: true
                                                                    }}
                                                                />
                                                            }
                                                        />
                                                    </LocalizationProvider>
                                                </Stack>
                                                <span className="custom-to">To</span>
                                                <Stack component="form" noValidate spacing={3} className="custom-accordion-datapicker">
                                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                                        <DatePicker
                                                            inputFormat={"MM-DD-YYYY"}
                                                            name="end_date"
                                                            onChange={(e) => {
                                                                setFieldValue("end_date",moment(e).format(`YYYY-MM-DD`))
                                                                handleDate(moment(e).format(`YYYY-MM-DD`),"EndDate")
                                                            }}
                                                            maxDate	= {endDate}
                                                            minDate = {selectEndDate?selectEndDate:endMinDay}
                                                            label="End date"
                                                            value={values.end_date}
                                                            renderInput={(params) =>
                                                                <TextField
                                                                    {...params}
                                                                    onKeyDown={(e)=>e.preventDefault()}
                                                                    variant="outlined"
                                                                    autoComplete={"off"}
                                                                    inputProps={{
                                                                        ...params.inputProps,
                                                                        readOnly: true
                                                                    }}
                                                                />
                                                            }
                                                        />
                                                    </LocalizationProvider>
                                                </Stack>
                                            </div>
                                        </div>
                                    </Form.Group>
                                    <Form.Group className="form-group full-fuied mb-0">
                                        <div className="half-fuied">
                                            <div className="frm-bottom">
                                                <label className="form-label">Click "Submit" to generate a printable PDF report and a new window will open</label>
                                                <Button variant="primary" type="submit" className="yellow-button" >
                                                    Submit
                                                </Button>
                                            </div>
                                        </div>
                                    </Form.Group>
                                </Form>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PrintableProgressReport;
