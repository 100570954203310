import React,{ useRef,useState,useEffect } from 'react'
import BannerComponent from '../../component/BannerComponent'
import Input from '../../component/Input';
import Draggable from 'react-draggable'; 
import { Button, Col, Form,Row } from 'react-bootstrap';
import SignaturePad from 'react-signature-canvas';
import {TextField,Stack} from '@mui/material';
import * as Yup from "yup";
import { useFormik } from "formik";
import html2canvas from "html2canvas";
import { useLocation, useNavigate } from "react-router-dom";
import Spinner from '../../component/Loader/Spinner';
import Certificate1 from '../../assets/images/certificate-1.jpg';
import eraser from '../../assets/images/eraser.svg';
import { callApi } from '../../ApiCalls';
import { DatePicker , LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';

const Certificate = () => {

    const location = useLocation();
    const navigate = useNavigate();

    const sigCanvas = useRef({})
    const [name,setName] = useState("")
    const [date,setDate] = useState("")
    const [signature,setSignature] = useState("")
    const [certificateImage,setCertificateImage] = useState("")
    const [loader,setLoader] = useState(false)
    const [isImageFromState,setIsImageFromState] = useState(false)

    const clear = () => sigCanvas.current.clear();

    const currentDate = new Date();
    let day = ('0' + currentDate.getDate()).slice(-2);
    let month = ('0' + (currentDate.getMonth()+1)).slice(-2);
    let year = currentDate.getFullYear();

    const startDate = `${year}-${month}-${day}`;

    const assignCertificate = () => {

        const certificateImage = document.getElementById("userCertificate");
        // var dummyImage = certificateImage

        // var element = document.getElementById("formElement");
        // dummyImage.removeChild(element);

        html2canvas(certificateImage,{
            useCORS : true,
            allowTaint : true,
            scrollY: window.scrollTo({
                top: 0,
                behavior: 'smooth'
              })
            // scale : 1,
            // dpi : 100,
            // width: 960,
            // height: 900
        }).then(async(canvas)=>{
            const imgObj = {
                image: canvas.toDataURL(),
            }
            var data = {
                assignmentId : location.state.assignmentId,
                studentId : location.state.userId,
                image : imgObj.image
            }
            var res = await callApi("assign-certificate",data)
            if(res.statusCode == 200) {
                navigate("/progress/assignment-progress")
            }
        })
    }

    const userSchema = Yup.object({
        fullName: Yup.string().max(40,"Full name must be at least 40 characters").required("Full Name is required").matches(/^[A-Za-z0-9 ]+$/, "Only Alphabets, Number and Space are allowed for this field"),
        date: Yup.string().required("Date is required"),
    });
  
    const initialValues = {
        fullName : "",
        date : startDate
    };
  
    const { values, touched, errors, handleChange, handleSubmit, setFieldValue} = useFormik({
        initialValues: initialValues,
        validationSchema: userSchema,
        onSubmit: async (values, { resetForm }) => {
            setName(values.fullName)
            var date = new Date(values.date);
            var newDate= ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '-' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '-' + date.getFullYear();

            setDate(newDate)
            setSignature(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"))
        }
    });
    
    useEffect(()=>{
        if(location.state != null){
            setCertificateImage(location.state.certificateImage)
            setIsImageFromState(true)
        } else {
            setIsImageFromState(false)
        }
        setLoader(true)
    },[])

    return (
        <>
            <BannerComponent title="certificate"/>
            {
                loader ? (
                    <section className="certificate-wrap">
                        <div className="container">
                            <Row className='flex-wrap'>                                
                                <Col lg={3} md={12} xs={12} className="left-area">
                                    <Form autoComplete="false" onSubmit={handleSubmit} className="frm-content">
                                        <Form.Group className={`form-group full-fuied`} >
                                            <Form.Label>Full Name</Form.Label>
                                            <Input
                                                type="text"
                                                name="fullName"
                                                value={values.fullName}
                                                onChange={handleChange}
                                                placeholder="Enter Your Full Name"
                                            />
                                            {errors.fullName && touched.fullName && (
                                                <Form.Text className="text-danger">
                                                    {errors.fullName}
                                                </Form.Text>
                                            )}
                                        </Form.Group>

                                        <Form.Group className={`form-group full-fuied`} >
                                            <Form.Label>Date</Form.Label>
                                            <div className='custom-accordion-data-picker-wrap'>
                                                <Stack component="form" noValidate spacing={3} className="custom-accordion-datapicker">
                                                    {/* <TextField
                                                        type="date"
                                                        name="date"
                                                        onChange={handleChange}
                                                        defaultValue={startDate}
                                                        sx={{ width: 220 }}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    /> */}
                                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                                        <DatePicker
                                                            inputFormat={"MM-DD-YYYY"}
                                                            name="date"
                                                            onChange={(e) => {
                                                                setFieldValue("date",moment(e).format(`YYYY-MM-DD`))
                                                            }}
                                                            label="End date"

                                                            value={values.date}
                                                            renderInput={(params) =>
                                                                <TextField
                                                                    {...params}
                                                                    variant="outlined"
                                                                    autoComplete={"off"}
                                                                />
                                                            }
                                                        />
                                                    </LocalizationProvider>
                                                </Stack>
                                            </div>
                                            {errors.date && touched.date && (
                                                <Form.Text className="text-danger">
                                                    {errors.date}
                                                </Form.Text>
                                            )}  
                                        </Form.Group>
                                        
                                        <Form.Group className={`form-group full-fuied mb-0`} >
                                            <div className='sig-wrap'>
                                                <Form.Label className='m-0'>Signature</Form.Label>
                                                <span className='clear' onClick={clear}><img src={eraser} className='img-fluid'/></span>
                                            </div>
                                            <SignaturePad ref={sigCanvas} />
                                        </Form.Group>

                                        <Form.Group className={`form-group full-fuied`} >
                                            {/* <Button onClick={saveSignature}>Save Signature</Button> */}
                                            <Form.Label className="text-danger">Note : Please, Drag all form element in Certificate Image</Form.Label>
                                            <Button type="submit" className="yellow-button">Submit</Button>
                                        </Form.Group>
                                    </Form>
                                </Col>

                                <Col lg={9} md={12} xs={12}>
                                    <div id="userCertificate">
                                        <div className='img-wrap'>
                                            <img src={isImageFromState?certificateImage:Certificate1} className='img-fluid' id="certificate" alt="certificate Image"/>
                                            {/* <img src="https://images.pexels.com/photos/3985411/pexels-photo-3985411.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load" crossOrigin="Anonymous" id="certificate" alt="certificate Image"/> */}
                                        </div>

                                        <span className='content-info'>                                            
                                            <Draggable>
                                                <h4>{name}</h4>
                                            </Draggable>
                                        
                                            <Draggable>
                                                <h4>{date}</h4>
                                            </Draggable>
                                        
                                            <Draggable>
                                                <div className='signature'><img src={signature} /></div>
                                            </Draggable>       
                                        </span>                                 
                                    </div>
                                </Col>
                            </Row>
                            
                            <div className='assign-btn'>
                                <Button onClick={assignCertificate} className="yellow-button">Assign Certificate</Button>
                            </div>
                        </div>
                    </section>
                ):
                    <Spinner/>
            }
        </>
    )
}

export default Certificate