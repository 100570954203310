  import React from "react";
import { Link } from "react-router-dom";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import BannerComponent from "../../component/BannerComponent";
import Family from "../../assets/images/family-icon.svg";
import Teacher from "../../assets/images/teacher-icon.svg";

const SignUp = () => {
  return (
    <div>
      <BannerComponent title="SignUp"/>
      <section className="signup-wrap">
        <div className="container">
          <div className="section-title">
            <h4>Sign up for free 15 day trial. Solve 5 problems per day. No credit card required. <br/> To begin, choose an account type by clicking below.</h4>
          </div>

          <div className="account-type">
            <Row className="justify-content-md-evenly flex-wrap">
              <Col lg={3} md={5} xs={12}>
                <Link variant="outline-primary" to="/family-signup">
                  <img src={Family} />
                  <h4>Family Sign Up</h4>
                  <p>15 Day Trial</p>
                </Link>
              </Col>

              <Col lg={3} md={5} xs={12}>
                <Link variant="outline-primary" to="/teacher-signup">
                  <img src={Teacher} />
                  <h4>Teacher Sign Up</h4>
                  <p>15 Day Trial</p>
                </Link>
              </Col>
            </Row>
          </div>

          <div className="info">
            <p>iSoar Math K-6 grade offers a unique experience for students, teachers and parents.  With interactive capabilities, this Math practice site can be used for independent practice, classroom assessment, homework or to meet the individual needs of struggling students.  Using a tiered three level approach, created by professionals with over fifteen years of experience, students can receive instruction, and meet individual education goals while parents and teachers monitor their progress.  Students receive badges, certificates, hints to aid in the learning process and can view their progress.  As they answer questions,  animated characters will celebrate their success.  iSoar Math is a simple tool, "Enhancing learning experiences for all!"</p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SignUp;
