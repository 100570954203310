import React from 'react'
import { Card, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { dateConverter } from '../../ApiCalls'

const AssignmentCard = (props) => {
    const {settingId,lessonSlug,lessonImage,dueDate,lessonName,categoryName} = props?.assignmentData;
    return (
        <Col lg={4} md={6} xs={12} >
            <Link to={`/assignment-question/${settingId}/${lessonSlug}`} className="assignments-list">
                <Card>
                    <div className="img-wrap">
                        <Card.Img variant="top" src={lessonImage} />
                    </div>
                    <Card.Body>
                        <Card.Title>Assignment : {categoryName} - {lessonName}</Card.Title>
                        <Card.Title>{dueDate ? "Due : " + dateConverter(dueDate) : "Due : Until lesson is completed"}</Card.Title>                        
                    </Card.Body>
                </Card>
            </Link>
        </Col>
    )
}

export default AssignmentCard