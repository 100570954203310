import React,{useState,useEffect, useContext} from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import TemplateFooter from '../../TemplateFooter'
import { postRequest } from '../../../ApiCalls';
import StudentImg from "../../../assets/images/student.svg";
import CheckImg from "../../../assets/images/check.svg";
import { UserContext } from '../../../App';

const ImageAndOptions = (props) => {
    
    const { state, dispatch } = useContext(UserContext);
    const question = props?.question
    const [answer,setAnswer] =useState("")
    const [usersAnswer,setUserAnswer] = useState("")
    const [isAnswerWrong,setIsAnswerWrong] = useState(false)
    const [isHideFooter,setIsHideFooter] = useState(false);
    const [isDisabled,setIsDisabled] = useState(true)
    
    const handleSubmit = async() => {
        const correctAnswer = question?.questionData?.correct_answer
        setIsDisabled(false)
        if(answer == "") {
            toast.warning("Please, select answer");
            setIsDisabled(true)
            return 0
        }
        if(answer === correctAnswer){
            props?.handleSubmit(true,answer)
        } else {
            setIsAnswerWrong(true)
            setIsHideFooter(true)
            var data = {
                question_id : question.id,
                users_ans : answer
            }
            // var res = await postRequest("show-correct-answer",data)
            setUserAnswer(answer)
            // console.log(res,"DD")

            // props?.handleSubmit(false, answer)
        }
        setAnswer("")
        setIsDisabled(true)
    }
    const nextQuestion = () => {
        setIsAnswerWrong(false)
        setIsHideFooter(false)
        setUserAnswer("")
        props?.handleSubmit(false, usersAnswer)
    }

    useEffect(()=>{
        setIsHideFooter(props?.isHideFooter)
        setUserAnswer(props?.usersAnswer)
    },[])
    return (
        <div className='image-and-options'>
            <div className='img-box'>
                <img src={question?.questionData?.select_question_image?.images.replace('original', 'thumb')} alt="Question Image"/>
            </div>
            
            <div className='py-4 text-center'>
                {question?.questionData?.options.map((elem, index) => (
                    <Link className={answer==elem.option?"question-info active":'question-info'} key={index} onClick={() => {setAnswer(elem.option)}}>
                        <h5 className='m-0'>{elem.option}</h5>
                    </Link>
                ))}    
            </div>
            {
                !isHideFooter &&
                <div className='questions-footer'>
                    <TemplateFooter question={question}/>
                    <div className='question-btn'>
                        <Link className="yellow-button" onClick={()=> isDisabled && handleSubmit()}>
                            Submit
                        </Link>
                    </div>
                </div>
            }
            {
                usersAnswer &&
                <div className='answer-info'>
                    <div className='box'>
                        <span className='student'>{state.type == 3 || state.type == 4 ? "Children" : "Student"} Answer <img src={StudentImg} className="img-fluid"/></span>
                        <h5>{usersAnswer.replaceAll(",", " ")}</h5>
                    </div>
                    <div className='box'>
                        <span className='correct'>Correct Answer <img src={CheckImg} className="img-fluid"/></span>
                        <h5>{question?.questionData?.correct_answer}</h5>
                    </div>
                </div>                
            }
            <div className='explanation-info'>
            {
                    usersAnswer && isAnswerWrong &&
                        <div className='details'>
                            <span>Explanation</span>
                            {
                                question?.hintType == 0 ? 
                                    <p>{question.questionData.answer_hint}</p>
                                :
                                    <img src={question.questionData.answer_hint} className="img-fluid question-img" />
                            }
                        </div>
                }  
                {
                    isAnswerWrong && 
                    <div className='question-btn'>
                        <Link className="yellow-button" onClick={nextQuestion}>
                            Next
                        </Link>
                    </div>
                } 
            </div>
        </div>  
    )
}

export default ImageAndOptions