import React from "react";

export const StateCounter = (props) => {
  const counterData = props.counterStatsData

  return (
    <>
      <li>
        <div className="rounded-shape">
          <img src={counterData.icon} />
        </div>

        <strong>{counterData.count} +</strong>
        <span>{counterData.text}</span>
      </li>
    </>
  );
};
