import React, { useEffect, useState, useContext } from 'react'
import { Link, useLocation,useNavigate } from 'react-router-dom';
import {Row,Col} from 'react-bootstrap';
import { callApi, dateConverter } from '../../ApiCalls';
import swal from "sweetalert";
import axios from 'axios';
import Spinner from "../../component/Loader/Spinner"
import { toast } from "react-toastify";
import Table from '../../component/Table/Table';
import Progress from "../../assets/images/progress.png";
import Redcross from "../../assets/images/red-cross.png";
import BlueEdit from "../../assets/images/blue-edit.png";
import environment from '../../environment';
import { UserContext } from '../../App';
const AssignmentDetail = () => {

    const { state, dispatch } = useContext(UserContext);
    const location = useLocation();
    const navigate = useNavigate();

    const [assigmentDetail,setAssignmentDetail] = useState({})
    const [userData,setUserData] = useState([])
    const [isLoader,setIsLoader] = useState(false)

    const deleteAssignment = async() => {
        swal({
            title: "Are you sure, You want to delete ?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
        .then(async(willDelete) => {
            if (willDelete) {
                var token = localStorage.getItem("token");
                const headers = {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                };
                await axios.post(environment.REACT_APP_API_URL + 'delete-entire-assignment',{setting_id:assigmentDetail.id}, {
                    headers: headers,
                }).then((res) => {
                    if(res.data.statusCode == 200){
                        swal("Assignment has been deleted!", {
                            icon: "success",
                            timer: 2000,
                        });
                        navigate('/overview-assignment')
                    }
                }).catch((err) => {
                    console.log(err, "Error");
                });
            } 
        });
    }

    const removeUserAssignment = async(id,type) => {
        setUserData((preVal)=> {
           return preVal.map((elem)=>{
                if(elem.id == id){
                    if(type == "remove")
                        return {...elem,status:"Not Assigned"}
                    else if(type == "reAssign")
                        return {...elem,status:"Not Started"}
                    else
                        return {...elem,status:"Not Started"}
                }else{
                    return elem
                }
           })
        })
    }

    const manageAssignment = (userId,type) => {
        swal({
            title: type=="reAssign"? "Are you sure, You want to reassign ?":type=="remove"?"Are you sure, You want to delete ?":"Are you sure, You want to assign ?",
            icon: type=="remove"?"warning":type=="reAssign"?"info":"info",
            buttons: true,
            dangerMode: true,
        })
        .then(async(willDelete) => {
            if (willDelete) {
                var assignmentId = assigmentDetail.id;
                var token = localStorage.getItem("token");
                const headers = {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                };
                var data = {
                    setting_id : assignmentId,
                    assign_to : userId,
                }
                if(type=="reAssign"){
                    //reAssign assignment
                    await axios.post(environment.REACT_APP_API_URL + "re-assignment", data,{
                        headers: headers,
                    }).then((res) => {
                        if(res.data.statusCode == 200){
                            toast.success(res.data.message);
                            removeUserAssignment(userId,type)
                        }
                    }).catch((err) => {
                        console.log(err, "Error");
                    });
                } else if (type=="remove") {
                    //Remove assignment
                    await axios.post(environment.REACT_APP_API_URL + "delete-child-student-overview-assignment", data,{
                        headers: headers,
                    }).then((res) => {
                        if(res.data.statusCode == 200){
                            toast.success(res.data.message);
                            removeUserAssignment(userId,type)
                        }
                    }).catch((err) => {
                        console.log(err, "Error");
                    });
                } else if (type=="assign") {
                    //Assign assignment
                    await axios.post(environment.REACT_APP_API_URL + "assign-assignment-to-user", data,{
                        headers: headers,
                    }).then((res) => {
                        if(res.data.statusCode == 200){
                            toast.success(res.data.message);
                            removeUserAssignment(userId,type)
                        }
                    }).catch((err) => {
                        console.log(err, "Error");
                    });
                }
            } 
        })
    }
    useEffect(() => {
        async function getData() {
            var assignmentId = location.pathname.substring(location.pathname.lastIndexOf('/') + 1)
            var assignmentDetail = await callApi(`get-assignment-overview-details/${assignmentId}`)
            setAssignmentDetail(assignmentDetail.data.assignmentDetails)
            setUserData(assignmentDetail.data.users)
            setIsLoader(true)
        }
        getData();
    }, []);

    var columns = [
        {
            dataField: "name",
            text: "Student",
            sort: true
        },
        {
            dataField: "created",
            text: "Created",
            formatter : (cell,row) => {
                return dateConverter(cell)
            }
        },
        {
            dataField: "score",
            text: "Score",
            formatter : (cell,row) => {
                return row.status == "Not Assigned" ? 0 : row.score
            }
        },
        {
            dataField: "status",
            text: "Status",
            formatter : (cell,row) => {
                return <div>{row.status}{" "}<p>{row.attemptCount > 1 && "("+(row.attemptCount)+" attempt)" }</p></div>
            }
        },
        {
            dataField: "actions",
            text: "Actions",
            formatter: (cell, row, rowIndex) => {
                return (
                    <>
                        {
                            row.status == "Not Assigned" ? (
                                <>
                                    <Link className="assign" onClick={()=>manageAssignment(row.id,"assign")}><img src={BlueEdit}/>{" "}Assign</Link>
                                </>
                            ) : row.status == "Completed" ? (
                                <>
                                    <Link className="re-assign" onClick={()=>manageAssignment(row.id,"reAssign")}><img src={Progress}/>{" "}Reassign</Link>
                                </>
                            ) : row.status == "Not Started" || row.status == "In Progress" ? (
                                <>
                                    <Link className="remove" onClick={()=>manageAssignment(row.id,"remove")}><img src={Redcross}/>{" "}Remove</Link>
                                </>
                            ) : (
                                <></>
                            )
                        }
                    </>
                );
            }
        }
    ];
    return (
        <>
            {
                userData && isLoader ? (
                    <section className='assignment-detail-wrap'>
                        <div className='container'>
                            <div className="main-title">
                                <h2>Assignment Details</h2>
                            </div>
                            <div className='assigment-info'>
                                <Row className=''>
                                    <Col lg={3} md={6} xs={12}>
                                        <div className="img-wrap">
                                            <img src={assigmentDetail.lessonImage} />
                                        </div>
                                    </Col>
                                    <Col lg={9} md={6} xs={12}>
                                        <div className="content">
                                            <ul>
                                                <li><span>Lesson: </span> {assigmentDetail.lessonName}</li>
                                                <li><span>Lesson Created: </span> {dateConverter(assigmentDetail.assignedDate)}</li>
                                                <li><span>Assigned To: </span>{assigmentDetail.assignTo}</li>
                                                <li>
                                                    <span>Display Lesson On {state.type == 3 ? "Children's" : "Student's"} Page:</span>
                                                    {
                                                        assigmentDetail.dueDate == 0 ? 
                                                            "Until Completed"
                                                        : (
                                                            <>
                                                                <div className='date-info'>
                                                                    <span>From:</span><span className='date'>{assigmentDetail.lessonDisplayStartDate ? dateConverter(assigmentDetail.lessonDisplayStartDate): " - "}</span>
                                                                </div>
                                                                <div className='date-info'>
                                                                    <span>To:</span><span className='date'>{assigmentDetail.lessonDisplayEndDate ? dateConverter(assigmentDetail.lessonDisplayEndDate): " - "}</span>
                                                                </div>
                                                            </>
                                                        )
                                                    }
                                                </li>
                                                <li>
                                                    <span>{state.type == 3 ? "Children" : "Student/s"} will: </span>
                                                    {
                                                        assigmentDetail.lessonType == 1 ?
                                                            "Work " + assigmentDetail.lessonTime / 60 + " minutes"
                                                        :
                                                            "Answer " + assigmentDetail.numberOfQuestions + " questions"
                                                    } 
                                                </li>
                                                <li><span>Auto Reassign: </span>{assigmentDetail.autoReassign == 0 ? "No" : "Yes"}</li>
                                            </ul>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <Table rowData={userData} columns={columns} type="Assignment/s overview"/>
                            <div className="d-flex justify-content-between align-items-center flex-wrap assigment-button">
                                <button className="yellow-button" onClick={deleteAssignment}>Delete Entire Assignment</button>
                            </div>
                        </div>
                    </section>
                ) : <Spinner/>
            }
        </>
    )
}

export default AssignmentDetail