import React,{useEffect,useContext,useState} from "react"
import { UserContext } from '../../App'
import BannerComponent from "../../component/BannerComponent";
import { Link } from 'react-router-dom';
import {Row,Col,Button} from 'react-bootstrap';
import {Tabs,Tab,Box,Typography} from "@mui/material";
import { callApi } from "../../ApiCalls";
import BarChart from "../../assets/images/barChart.png"
import PieChart from "../../assets/images/pieChart.png"
import Data from "./data.json"
import Chart from '../../component/Charts/GoogleChart'
import Spinner from '../../component/Loader/Spinner';
import moment from "moment";

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
        >
        {value === index && (
            <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
            </Box>
        )}
        </div>
    );
}
  
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
        "tab-value" : index,
    };
}
const Progress = () => {

    const { state, dispatch } = useContext(UserContext);
    
    const [value, setValue] = useState(0);
    const [classData,setClassData] = useState([])
    const [completedState,setCompletedState] = useState({})
    const [skillData,setSkillData] = useState({})
    const [avgAnsData,setAvgAnsData] = useState({})
    const [progressData, setProgressData] = useState({})
    const [activeTab,setActiveTab] = useState(0)
    const [isLoder,setIsLoader] = useState(false)

    const handleChange = async(event, newValue) => {
        setValue(newValue);
        var classId = event.target.getAttribute("tab-value")
        var completedState = await callApi(`assignment-completed-stat/${classId}`)
        setCompletedState(completedState.data)
        var skillData = await callApi(`graph-skill-focus/${classId}`)
        setSkillData(skillData.data)
        var avgAnsData = await callApi(`graph-average-answers/${classId}`)
        setAvgAnsData(avgAnsData.data)
        setProgressData(Data.data)
        setIsLoader(true)
        setActiveTab(classId)
    };

    useEffect(()=>{
        async function getStudentData() {
            var classData = await callApi("get-class");
            setClassData(classData)
            if(classData.data.length > 0){
                setActiveTab(classData.data[0].id)
                var completedState = await callApi(`assignment-completed-stat/${classData.data[0].id}`)
                setCompletedState(completedState.data)
                var skillData = await callApi(`graph-skill-focus/${classData.data[0].id}`)
                setSkillData(skillData.data)
                var avgAnsData = await callApi(`graph-average-answers/${classData.data[0].id}`)
                setAvgAnsData(avgAnsData.data)
                setProgressData(Data.data)
            }
            setIsLoader(true)
        }
        async function getChildData() {
            var completedState = await callApi("assignment-completed-stat")
            setCompletedState(completedState.data)
            var skillData = await callApi("graph-skill-focus")
            setSkillData(skillData.data)
            var avgAnsData = await callApi("graph-average-answers")
            setAvgAnsData(avgAnsData.data)
            setProgressData(Data.data)
            setIsLoader(true)
        }
        if(state.type == 5)
            getStudentData()
        else 
            getChildData()
    },[])
    return(
        <>
            <BannerComponent title="Progress"/>
            {
                isLoder ? (
                    <section className='progress-wrap'>
                        <div className='container'>
                            <Row className=''>
                                <Col lg={3} md={12} xs={12}>
                                    <div className="left-area">
                                        <Link to='/progress/recent-activity-progress' className="custom-link">
                                            Recent Activity
                                        </Link>
                                        <Link to='/progress/individual-progress' className="custom-link">
                                            {state.type == 5 ? "Student" : "Child" } Progress
                                        </Link>
                                        <Link to='/progress/assignment-progress' className="custom-link">
                                            Assignment Progress
                                        </Link>
                                        <Link to='/progress/printable-progress-report' className="custom-link">
                                            Printable Progress Report
                                        </Link>
                                        {/* <Link to='/award' className="custom-link">
                                            {state == 5 && "Student" } Awards
                                        </Link> */}
                                    </div>
                                </Col>
                                <Col lg={9} md={12} xs={12}>
                                    {state.type == 5 && classData.data && classData.data.length > 0 ? (
                                        <>                          
                                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className="tab-items">
                                                <Tabs value={value} onChange={handleChange}>
                                                {
                                                    classData.data.map((elem,index)=>(
                                                        <Tab label={`${elem.className}`} key={index} {...a11yProps(elem.id)} />
                                                    ))
                                                }
                                                </Tabs>
                                            </Box> 
                                            <div className="tab-content">
                                                <TabPanel value={value} index={value} >                                                                            
                                                    <div className="main-content">
                                                        <div className="chart-info">
                                                            {
                                                                Object.keys(completedState).length !== 0 ? (
                                                                    <>
                                                                        <div className="question-info">
                                                                            <ul>
                                                                                <li><span>Number of Questions Answered:</span> {completedState.numberOfQuestion} </li>
                                                                                <li><span>Total Time Spent (hh:mm):</span> {moment.unix(completedState.timeSpent).utc().format('HH [:] mm []')} </li>
                                                                            </ul>
                                                                        </div>

                                                                        <div className="chart-data-wrap">
                                                                            <div className="piechart">
                                                                                <strong>Skill Focus</strong>
                                                                                <Chart chartData={skillData.skillFocus} chartType="PieChart" />
                                                                            </div>
                                                                            <div className="barchart">
                                                                                <strong>Assigments Completed</strong>
                                                                                <Chart chartData={completedState.assignmentCompleted} chartType="Bar" />
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                ) : (
                                                                    <center>No Data Found</center>
                                                                    // <img src={PieChart} alt="Pie Chart"/>
                                                                )
                                                            }
                                                        </div>
                                                        <div className="chart-info">
                                                            {
                                                                Object.keys(avgAnsData).length !== 0 ? (
                                                                    <>
                                                                        <strong>Average Number of Questions Answered</strong>
                                                                        <Chart chartData={avgAnsData.averageNumberOfQuestionAnswer} chartType="Bar" />
                                                                    </>
                                                                ) : (
                                                                    <center>No Data Found</center>
                                                                    // <img src={BarChart} alt="Pie Chart"/>
                                                                )
                                                            }
                                                        </div>
                                                    </div>                                                    
                                                </TabPanel>
                                            </div>
                                        </>
                                    ) : (
                                        <div className="main-content">
                                            <div className="chart-info">
                                                {
                                                    Object.keys(completedState).length !== 0 ? (
                                                        <>
                                                            <div className="question-info">
                                                                <ul>
                                                                    <li><span>Number of Questions Answered:</span> {completedState.numberOfQuestion} </li>
                                                                    <li><span>Total Time Spent (hh:mm):</span> {moment.unix(completedState.timeSpent).utc().format('HH [:] mm []')} </li>
                                                                </ul>
                                                            </div>
                                                            <strong>Skill Focus</strong>
                                                            <div className="chart-data-wrap">
                                                                <div className="piechart">
                                                                    <Chart chartData={skillData.skillFocus} chartType="PieChart" />
                                                                </div>
                                                                <div className="barchart">
                                                                    <Chart chartData={completedState.assignmentCompleted} chartType="Bar" />
                                                                </div>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <center>No Data Found</center>                                                        
                                                        // <img src={PieChart} alt="Pie Chart"/>
                                                    )
                                                }
                                            </div>
                                            <div className="chart-info">
                                            {
                                                Object.keys(avgAnsData).length !== 0 ? (
                                                    <>
                                                        <strong>Average Number of Questions Answered</strong>
                                                        <Chart chartData={avgAnsData.averageNumberOfQuestionAnswer} chartType="Bar" />
                                                    </>
                                                ) : (
                                                    <center>No Data Found</center>
                                                    // <img src={BarChart} alt="Pie Chart"/>
                                                )
                                            }
                                            </div>
                                        </div>                        
                                    )}      
                                </Col>
                            </Row>
                        </div>
                    </section>
                ) : (
                    <Spinner />
                )
            }
        </>
    )
}

export default Progress;