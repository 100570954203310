import { Grid, IconButton } from "@mui/material";
import React, { useRef, useState, useCallback } from "react";
import UndoIcon from "@mui/icons-material/Undo";
import RedoIcon from "@mui/icons-material/Redo";
import { ReactSketchCanvas } from "react-sketch-canvas";
import { borderRadius } from "@mui/system";


const SketchBoard = () => {
    const canvasRef = useRef(null);
    const [svg, setSvg] = useState("");
  
    // const handleClear = useCallback(() => {
    //   canvasRef.current.clearCanvas();
    //   setSvg("");
    // }, []);

    const undo = useCallback(() => {
      canvasRef.current.undo();
      setSvg("");
    }, []);

    const redo = useCallback(() => {
      canvasRef.current.redo();
      setSvg("");
    }, []);

    return (
      <div className="sketch-canvas-wrap">
        <ReactSketchCanvas
          style={{
            border: "3px solid #23215B",
            borderRadius: "10px"
          }}
          ref={canvasRef}
          strokeWidth={4}
          strokeColor="#212529"
          canvasColor="#FFFFFF"
        />

        <div className="sketch-btn">
          <IconButton>
            <UndoIcon type="button" onClick={undo} />
          </IconButton>
          &nbsp;
          <IconButton>
            <RedoIcon type="button" onClick={redo} />
          </IconButton>
        </div>
      </div>
    );
};

export default SketchBoard;