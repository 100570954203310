export const initialState = {
    type : null,
    userId : 0,
    isExpired : 0,
    bannerImage : "",
    avatarImage : "",
    isPaid : 0
}

export const reducer = (state=initialState,action) => {
    if(action.type === "USER"){
        return {...state,type : action.payload}
    }
    if(action.type === "USER_ID"){
        return {...state,userId : action.payload}
    }
    if(action.type === "CHECK_SUBSCRIPTION"){
        return {...state,isExpired : action.payload}
    }
    if(action.type === "SET_BANNER_IMAGE"){
        return {...state,bannerImage : action.payload}
    }
    if(action.type === "SET_AVATAR_IMAGE"){
        return {...state,avatarImage : action.payload}
    }
    if(action.type === "IS_PAID"){
        return {...state,isPaid : action.payload}
    }
    return state
}