import React from "react";

export const InfoComponentTwo = (props) => {

    const title =
        props.contentData.sectionContent.title.charAt(0).toUpperCase() +
        props.contentData.sectionContent.title.slice(1);

    const {image} = props.contentData.sectionContent
    const {imagePosition} = props.contentData.sectionContent
    const {content} = props.contentData.sectionContent

    return (
        <div className="common-sections-main">
            <div className="container">
                <div className={`common-section-wrap ${imagePosition == "right" ? "image-right" : "image-left"}`}>
                    <div className="common-section-content">
                        <h2>{title}</h2>
                        <p>{content}</p>
                    </div>
                    <div className="common-section-image">
                        <img src={image} className="img-fluid" />
                    </div>
                </div>
            </div>
        </div>
    );
};
