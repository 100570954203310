import React, { useEffect, useState, createContext, useReducer } from "react";
// bootstrap
import {} from "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import {} from "../node_modules/bootstrap/dist/js/bootstrap.bundle.js";
// css
import {} from "slick-carousel/slick/slick.css";
import {} from "slick-carousel/slick/slick-theme.css";
import {} from "./style.css";
// component
import Header from "./component/Header/Header"
import Footer from "./component/Footer/Footer"
import AppRoutes from "./AppRoutes";

import {reducer,initialState} from './reducer/useReducer'
import ScrollToTop from "./component/ScrollToTop";
import { callApi } from "./ApiCalls";
import { ToastContainer } from 'react-toastify';

export const UserContext = createContext()

const App = () => {
  const [headerFooterData, setHeaderFooterData] = useState([]);
  const [state,dispatch] = useReducer(reducer,initialState)

  useEffect(() => {
    async function getData() {
      const response = await callApi("get-website-information")
      setHeaderFooterData(response);
      dispatch({type:"SET_BANNER_IMAGE",payload:response.data[0].defaultBannerImage})
    }
    getData();
    var user = localStorage.getItem("userRole");
    var isExpired = localStorage.getItem("isExpired");
    var userData = JSON.parse(localStorage.getItem("users"))
    var isPaid = localStorage.getItem(isPaid)
    dispatch({type:"USER",payload:user})
    dispatch({type:"CHECK_SUBSCRIPTION",payload:isExpired})
    dispatch({type:"IS_PAID",payload:isPaid})
    if(userData != null)
      dispatch({type:"IS_PAID",payload:userData.planDetails.planType})
  }, []);

  return (
    <>
      {headerFooterData.data && (
        <>
          <ToastContainer theme="dark" autoClose={3000}/>
          <UserContext.Provider value={{state,dispatch}}>
            <ScrollToTop/>
            <Header headerData={headerFooterData.data} />
            <AppRoutes/>
            <Footer footerData={headerFooterData.data} />
          </UserContext.Provider>
        </>
      )}
    </>
  );
};

export default App;
