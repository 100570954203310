import React from "react";
import "./Counter.css";
import { StateCounter } from "./StateCounter";

export const Counter = (props) => {
  const stats = props.counterStatsData.counter;
  return (
    <section className="counter-section">
      <div className="container">
        <ul className="counter-wraper">
          {stats.sectionContent.map((elem, index) => (
            <React.Fragment key={index}>
              <StateCounter counterStatsData={elem} />
            </React.Fragment>
          ))}
        </ul>
      </div>
    </section>
  );
};
