import React, { useEffect, useState } from "react";
import { NewsAndBlog } from "../../component/Home/NewsAndBlog/NewsAndBlog";
import { Testimonial } from "../../component/Home/Testimonial/Testimonial";
import { Counter } from "../../component/Home/Counter/Counter";
import { HomeSection } from "../../component/Home/HomeSection/HomeSection";
import { InfoComponentTwo } from "../../component/InfoComponentTwo";
import InfoComponent from "../../component/InfoComponent";
import AboutSectionTwo from "../../component/Home/AboutSectionTwo";
import { callApi } from "../../ApiCalls";
import Spinner from "../../component/Loader/Spinner"

const GuestHome = () => {
  const [resData, setResData] = useState([]);

  useEffect(() => {
    async function getData() {
      const res = await callApi("home");
      setResData(res);
    }
    getData();
  }, []);
  return (
    <>
      {
        resData.data ? (
          <div className="home-main">
            <HomeSection gradeData={resData.data} />
            {resData.data.aboutSectionOne.status == 1 && <InfoComponent contentData={resData.data.aboutSectionOne}/> }
            {resData.data.aboutSectionTwo.status == 1 && <AboutSectionTwo contentData={resData.data.aboutSectionTwo}/> }
            {resData.data.studentInterface.status == 1 && <InfoComponentTwo contentData={resData.data.studentInterface}/> }
            {resData.data.awesomeAnimation.status == 1 && <InfoComponentTwo contentData={resData.data.awesomeAnimation}/> }
            {resData.data.counter.status == 1 && <Counter counterStatsData={resData.data} /> }
            <Testimonial testimonialData={resData.data} />
            {resData.data.blog.status == 1 && <NewsAndBlog newsAndBlogData={resData.data.blog} /> }
          </div>
        ): <Spinner/>
      }
    </>
  );
};

export default GuestHome;
