import React,{useEffect,useState,useRef,useContext} from 'react'
import {MenuItem,FormControl} from '@mui/material';
import Select from '@mui/material/Select';
import {Form,Button} from "react-bootstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { callApi, dateConverter } from '../../ApiCalls'
import BannerComponent from '../../component/BannerComponent'
import Table from '../../component/Table/Table'
import Spinner from '../../component/Loader/Spinner';
import { UserContext } from '../../App';
import {useNavigate } from 'react-router-dom'

const UploadWorksheet = () => {

  const { state, dispatch } = useContext(UserContext);
  const navigation = useNavigate();
  const navigate = window.location.pathname.split('/')[1];

  const [userWorksheetData,setUserWorksheetData] = useState([])
  const [worksheetData,setWorksheetData] = useState([])
  const [isDisable,setIsDisable] = useState(false)

  const fileRef = useRef(null);

  const SUPPORTED_FORMATS = [
    "application/pdf"
  ];

  const userSchema = Yup.object({
    worksheetId: Yup.string().required("Worksheet is required"),
    worksheetFile: Yup.mixed()
      .required('Worksheet file is required Field')
      .test('type', 'File is not supported', (value) => {
        return value && SUPPORTED_FORMATS.includes(value.type);
      })
      .test('Fichier taille',
        'Please upload a file smaller than 5 MB', (value) => !value || (value && value.size <= 5e6)),
  });

  const initialValues = {
    worksheetId : "",
    worksheetFile: "",
  };

  const { values, touched, errors, handleChange, handleSubmit ,setFieldValue} = useFormik({
    initialValues: initialValues,
    validationSchema: userSchema,
    onSubmit: async (values, { resetForm }) => {
      setIsDisable(true)
      const formData = new FormData();
      formData.append('worksheetId', values.worksheetId);
      formData.append('worksheetFile', values.worksheetFile);

      var res = await callApi("upload-worksheet",formData,null,"multipart/form-data")
      if(res.statusCode == 200){
        getWorksheetData()
        resetForm()
        document.getElementById("workSheetForm").reset();
        setIsDisable(false)
      }else{
        setIsDisable(false)
      }
    },
  });

  const getWorksheetData = async() => {
    var response = await callApi("get-worksheet-studentinfo")
    setUserWorksheetData(response)
  }
  useEffect(()=>{
    if(state.isPaid == 0)
      navigation("/");
    async function getUserWorksheetData(){
      var worksheetData = await callApi("get-worksheet-info")
      setWorksheetData(worksheetData.data)
      var response = await callApi("get-worksheet-studentinfo")
      console.log(response,"RRR")
      setUserWorksheetData(response)
    }
    getUserWorksheetData()
  },[])

  var columns = [
    {
        dataField: "name",
        text: "Name",
        sort: true
    },
    {
        dataField: "worksheetTitle",
        text: "Worksheet Title",
        sort: true
    },
    {
        dataField: "createdAt",
        text: "Date",
        formatter : (cell,row) => {
          return dateConverter(cell)
        }
    },
    {
        dataField: "info",
        text: "View",
        formatter: (cell, row, rowIndex) => {
          return (
              <div className="assign-detail" onClick={()=>window.open(row.pdfFile,"__blank")}>View Worksheet</div>
          );
        }
    }
  ];

  return (
    <>
      <BannerComponent title="Upload Worksheet" navigate={navigate}/>
      {
        userWorksheetData.data ? (
          <div className="upcomingWebinars-main">
            <div className="container">
              <div className='upload-worksheet-wrap'>
                <Form id="workSheetForm" onSubmit={handleSubmit} >
                  <FormControl className='drop-down-menu'>
                    <Form.Label>Worksheet</Form.Label>
                    <Select name="worksheetId" onChange={(e)=>{handleChange(e)}} value={values.worksheetId} displayEmpty inputProps={{ 'aria-label': 'Without label' }} >
                      <MenuItem value="">None</MenuItem>
                      {
                        worksheetData.map((elem,index)=>(
                          <MenuItem value={elem.worksheetId} key={index} >{elem.worksheetTitle}</MenuItem>
                        ))
                      }
                    </Select>
                    {
                      errors.worksheetId && touched.worksheetId && (
                        <Form.Text className="text-danger">{errors.worksheetId}</Form.Text>
                      )
                    }
                  </FormControl>
                  <FormControl className='upload-pdf-wrap'>
                    <div className="add-icn">
                      <Form.Group controlId="formFile">
                        <Form.Label className="w-100">Worksheet PDF<span>*</span></Form.Label>
                        <Form.Control type="file" name="worksheetFile" onChange={(e)=>{setFieldValue("worksheetFile",e.target.files[0])}} />
                      </Form.Group>
                      {
                        errors.worksheetFile && touched.worksheetFile && (
                          <Form.Text className="text-danger">{errors.worksheetFile}</Form.Text>
                        )
                      }
                    </div>
                  </FormControl>
                  <Button disabled={isDisable} variant="primary" type="submit" className="yellow-button">
                      Submit
                  </Button>
                </Form>
              </div>
              <div className="upcomingWebinars-main-wraper">
                <Table rowData={userWorksheetData.data} columns={columns} type="Worksheet" keyField="worksheetId"/>
              </div>
            </div>
          </div>
        ) : (
          <Spinner />
        )
      }
    </>
  )
}

export default UploadWorksheet