import React,{useEffect,useState} from 'react'
import BannerComponent from '../../component/BannerComponent';
import Accordian from "../../component/Accordian";
import { callApi, dateConverter } from "../../ApiCalls";
import Spinner from "../../component/Loader/Spinner"
import { toast } from "react-toastify";
import axios from 'axios';
import swal from "sweetalert";
import Redcross from "../../assets/images/red-cross.png";
import environment from '../../environment';
const IndividualAssignment = () => {

  const [assignmentData,setAssignmentData] = useState([])
  
  const deleteAssignment = (id,userId = null) => {
    swal({
        title: "Are you sure, You want to delete ?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
    })
    .then((willDelete) => {
        if (willDelete) {
            deleteRecord(id,userId)
            swal("Record has been deleted!", {
                icon: "success",
                timer: 2000,
            });
        } 
    });
}

  const deleteRecord = async(assignmentId,userId) => {
    var token = localStorage.getItem("token");
    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
    };
    var data = {
      assignment_id : assignmentId,
      assign_to : userId,
    }
    await axios.post(environment.REACT_APP_API_URL + "delete-child-student-assignment",data,{
      headers: headers,
    }).then((res) => {
      if(res.data.statusCode == 200){
          toast.success("Assignment has been deleted!");
          getData()
      }
    }).catch((err) => {
      console.log(err, "Error");
    });
  }
  async function getData(){
    var assignmentData = await callApi("get-individual-assignments")
    setAssignmentData(assignmentData) 
  }
  useEffect(()=>{
    async function getData(){
      var assignmentData = await callApi("get-individual-assignments")
      setAssignmentData(assignmentData) 
    }
    getData()
  },[])

  var columns = [
    {
      dataField: "assignedDate",
      text: "Date",
      formatter : (cell,row) => {
        return dateConverter(cell)
      }
    },
    {
      dataField: "lessonName",
      text: "Lesson",
      sort: true
    },
    {
      dataField: "dueDate",
      text: "Due Date",
      formatter : (cell,row) => {
        return cell == 0 ? "Until Completed":dateConverter(cell)
      }
    },
    {
      dataField: "delete",
      text: "Delete",
      formatter: (cell, row, rowIndex) => {
        return (
          <div className='action-btns' onClick={()=>deleteAssignment(row.id)}><img src={Redcross} /></div>
        );
      }
    }
];

  return (
    <>
      {
        assignmentData.data ? (
          <>
            <BannerComponent title="Individual Assignment"/>
            <div className='award-sec-wrap individual_assignment_tbl'>
              <div className='common-accordion-main'>
                <div className="container">
                  <strong>This page displays all active assignments that children currently have.</strong>
                  <div className='common-accordion-wrap'>
                    {
                      assignmentData.data && assignmentData.data.length > 0 && assignmentData.data.map((elem,index)=>(
                        <React.Fragment key={index}>
                          <Accordian columns={columns} rowData={elem} deleteRecord={deleteRecord} type="table" title={elem?.childName} content={"elem.queAnsContent"}/>
                        </React.Fragment>
                      ))
                    }
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : <Spinner/>
      }
    </>
  )
}

export default IndividualAssignment