import React from "react";
import { useEffect, useState } from "react";
import "../../component/Home/NewsAndBlog/NewsAndBlog.css";
import { NewsAndBlogCard } from "../../component/Home/NewsAndBlog/NewsAndBlogCard";
import BannerComponent from "../../component/BannerComponent";
import axios from "axios";
import Pagination from "../../component/Blog/Pagination";
import { callApi, postRequest } from "../../ApiCalls";
import Spinner from "../../component/Loader/Spinner"
import environment from '../../environment';

let PageSize = 4;

const Blog = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageData, setPageData] = useState([]);
  const [blogData, setBlogData] = useState([]);
  const [isLoder,setIsLoader] = useState(false)
  useEffect(() => {
    async function getData() {
     const blogData = await callApi("get-page/blog");
     setPageData(blogData); 
      var data = {
        page: 1,
        perPage: PageSize,
      };
      var response = await postRequest("get-all-blogs",data)
      setBlogData(response);
      setIsLoader(true)
    }
    getData();
  }, []);

  const handlePageClick = async (page) => {
    setCurrentPage(page);
    setIsLoader(false)
    var data = {
      page: page,
      perPage: PageSize,
    };
    var response = await postRequest("get-all-blogs",data)
    setBlogData(response);
    setIsLoader(true)
  };

  return (
    <>
      {
        isLoder && pageData.data ? (
          <>
            <BannerComponent bannerData={pageData.data.pageData} />
            <div className="blog-main">
              {
                typeof(blogData.data) != "undefined" ? (

                  <div className="container">
                    <div className="latest-blog-wrap">
                      <ul>
                        {blogData.data.postData.map((item, index) => (
                          <React.Fragment key={index}>
                            {item.status == 1 && <NewsAndBlogCard newsAndBlogData={item} />}
                          </React.Fragment>
                        ))}
                      </ul>
                    </div>
                    <Pagination
                      currentPage={currentPage}
                      totalCount={blogData.data.totalCount}
                      pageSize={PageSize}
                      onPageChange={(page) => {handlePageClick(page)}}
                    />
                  </div>
                ) : (
                  <center>No Blog Found</center>
                )
              }
            </div>
          </>
        ) : <Spinner/>
      }
    </>
  );
};

export default Blog;
