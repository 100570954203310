import React, { useState, useRef, useEffect, useContext} from "react";
import { Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Input from "../Input";
import Edit from "../../assets/images/edit.png";
import Add from "../../assets/images/add.png";
import { ModalComponent } from "../../component/ModalComponent";
import Avtar from "../../assets/images/avtar.png";
import { callApi } from "../../ApiCalls";
import { UserContext } from "../../App";

const AccountSetting = (props) => {

  const acDetails = props?.acDetails;

  const { state, dispatch } = useContext(UserContext);
  const navigate = useNavigate()

  const [show, setShow] = useState(false);
  const [detailType, setDetailType] = useState();
  const [label, setLabel] = useState();
  const [oldValue, setOldValue] = useState();
  const fileRef = useRef(null);
  const [file, setFile] = useState();
  const [isTeacher,setIsTeacher] = useState(false)
  const [imgSizeError,setImgSizeError] = useState("")
  const [imgTypeError,setImgTypeError] = useState("")

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const imageChange = async (e) => {
    const file = e.target.files[0];
    if (!file.name.match(/\.(jpg|jpeg|png)$/)) {
      setImgSizeError("")
      setImgTypeError('Only the following formats are accepted: .jpeg,.jpg and .png');
      return false;
    }
    if (file.size > 8e+5) {
      setImgTypeError("")
      setImgSizeError("Please upload a file smaller than 800 KB");
      return false;
    }
    setImgTypeError("")
    setImgSizeError("")
    const base64 = await convertToBase64(file);
    setFile(base64);
    var data = {
        avtar : base64,
        // avtarPrevImg = oldValue;
    }
    var response = await callApi("my-account/my-account-update", data);
    if (response.statusCode == 200) {
        props?.getAccountDetails(1);
        dispatch({type : "SET_AVATAR_IMAGE",payload : base64})
        navigate("/my-account");
    } else {
      props?.getAccountDetails(1);
      dispatch({type : "SET_AVATAR_IMAGE",payload : base64})
      navigate("/my-account");
    }
  };
  const handleShow = (type) => {
    setShow(true);
    setDetailType(type);
  };
  const handleClose = () => {
    setShow(false);
  };

  useEffect(()=>{
    var userRole = localStorage.getItem('userRole')
    if(userRole == 5)
      setIsTeacher(true)
    else 
      setIsTeacher(false)
  },[])
  return (
    <div className="account-details">
      <div className="choose-icon">
        <div className="choose-icon-wraper">
          {/* <img src={acDetails.avtar} />
                    <Link
                        onClick={() => {
                            handleShow("avtar");
                            setLabel("Avtar");
                            setOldValue(acDetails.avtar);
                        }}
                    >
                        <img src={Edit} />
                    </Link> */}
          <img src={file ? file : acDetails.avtar == "" ? Avtar : acDetails.avtar} />
          <input
            ref={fileRef}
            hidden
            name="avtar"
            type="file"
            accept=".jpeg, .png, .jpg"
            onChange={(e) => {
              imageChange(e);
            }}
          />
          <Link
            onClick={() => {
              fileRef.current.click();
            }}
          >
            {/* <img src={isUpdate ? Edit : Add} /> */}
            <img src={Edit} />
          </Link>
        </div>
        <Form.Label className="w-100">Avatar</Form.Label>
        <Form.Label className="text-danger">{imgTypeError != ""?imgTypeError:imgSizeError != ""?imgSizeError:""}</Form.Label>
      </div>
      <Form autoComplete="false" className="row">
        <Form.Group className="form-group full-fuied col-lg-3 col-md-6" controlId="formBasicstudent" >
          <Form.Label>First Name</Form.Label>
          <Input
            type="text"
            name="First Name"
            disabled={true}
            value={acDetails.firstName}
          />
          <Link
            onClick={() => {
              handleShow("first_name");
              setLabel("First Name");
              setOldValue(acDetails.firstName);
            }}
          >
            {" "}
            <img src={Edit} />
          </Link>
        </Form.Group>
        <Form.Group className="form-group full-fuied col-lg-3 col-md-6" controlId="formBasicstudent" >
          <Form.Label>Last Name</Form.Label>
          <Input
            type="text"
            name="Last Name"
            disabled={true}
            value={acDetails.lastName}
          />
          <Link
            onClick={() => {
              handleShow("last_name");
              setLabel("Last Name");
              setOldValue(acDetails.lastName);
            }}
          >
            {" "}
            <img src={Edit} />
          </Link>
        </Form.Group>
        {
          isTeacher && 
          <Form.Group className="form-group full-fuied col-lg-3 col-md-6" controlId="formBasicstudent" >
            <Form.Label>Class Code</Form.Label>
            <Input
              type="text"
              name="Class Code"
              disabled={true}
              value={acDetails.classCode}
            />
            <Link
              onClick={() => {
                handleShow("class_code");
                setLabel("Class Code");
                setOldValue(acDetails.classCode);
              }}
            >
              {" "}
              <img src={Edit} />
            </Link>
          </Form.Group>
        }
        <Form.Group className="form-group full-fuied col-lg-3 col-md-6" controlId="formBasicstudent" >
          <Form.Label>E-Mail</Form.Label>
          <Input
            type="mail"
            name="E-Mail"
            disabled={true}
            value={acDetails.email}
          />
        </Form.Group>
        <Form.Group className="form-group full-fuied col-lg-3 col-md-6" controlId="formBasicstudent" >
          <Form.Label>Password</Form.Label>
          <Input
            type="password"
            name="full_name"
            disabled={true}
            value="Hidden"
          />
          <Link
            onClick={() => {
              handleShow("password");
              setLabel("Password");
            }}
          >
            {" "}
            <img src={Edit} />
          </Link>
        </Form.Group>
      </Form>
      {show && (
        <ModalComponent
          show={show}
          handleClose={handleClose}
          type="EditDetail"
          className="editDetail"
          detailType={detailType}
          label={label}
          oldValue={oldValue}
          getAccountDetails={props?.getAccountDetails}
        />
      )}
    </div>
  );
};

export default AccountSetting;
