import React,{useEffect,useRef,useContext} from 'react'
import { callApi } from '../../ApiCalls';
import CheckMark from "../../assets/images/checkmark-icon.svg";
import { useNavigate } from "react-router-dom";
import { UserContext } from '../../App';

const RenewSuccess = () => {

  const isUnmountedRef = useRef(false)
  const navigate = useNavigate();
  const { state, dispatch } = useContext(UserContext);

  useEffect(()=> {
    if (isUnmountedRef.current == false){
      const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
      });
      async function savaPaymentData() {
        var data = {
          session_id : params.session_id,
          success : params.success,
          planId : params.planID,
          planMonth : params.planMonth
        }
        var response = await callApi("save-payment-data",data)
        dispatch({type:"IS_PAID",payload:1})
      }
      if(params.session_id != null && params.success != null )
        savaPaymentData()
      else 
        navigate("/")
    }
    return () => { isUnmountedRef.current = true }
  },[])

  return (
    <section className="renew-success-wrap">
      <div className="container">
        <div className='box'>
          <div className='img-wrap'>
            <img src={CheckMark} className="img-fluid" />
          </div>
          <p>This is a confirmation that we have received your payment. Thank you for choosing iSoar Math!</p>
        </div>
      </div>
    </section>
  )
}

export default RenewSuccess