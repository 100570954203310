import React,{useEffect,useContext,useState} from "react"
import BannerComponent from "../../component/BannerComponent";
import { UserContext } from '../../App'
import { callApi, dateConverter } from "../../ApiCalls";
import {Tabs,Tab,Box,Typography} from "@mui/material";
import Spinner from "../../component/Loader/Spinner";
import { ModalComponent } from "../../component/ModalComponent";
import Pagination from "../../component/Blog/Pagination";
import { useNavigate } from "react-router";
import Table from "../../component/Table/Table";

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
        >
        {value === index && (
            <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
            </Box>
        )}
        </div>
    );
} 
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
        "tab-value" : index,
    };
}
const AssignmentProgress = () => {

    const { state, dispatch } = useContext(UserContext);
    const navigatePage = useNavigate();

    const [value, setValue] = useState(0);
    const [classData,setClassData] = useState([])
    const [assignmentProgressData,setAssignmentProgressData] = useState([])
    const [activeTab,setActiveTab] = useState(0)
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [show,setShow] = useState(false)

    const navigate = window.location.pathname.split('/')[1];

    const handleClose = () => setShow(false)

    const handleChange = async(event, newValue) => {
        setValue(newValue);
        var classId = event.target.getAttribute("tab-value")
        setActiveTab(classId)
        var res = await callApi(`assignment-progress/${classId}`);
        setAssignmentProgressData(res.data)
        setPageSize(res?.meta?.per_page)
        setPageCount(res?.meta?.total)
    };

    const handlePageClick = async (page,type) => {
        setCurrentPage(page)
        var params = {
            page : page,
        }
        getData(params,type)
    }

    const getData = async(params,type) => {
        if(type == "teacher")
            var assignmentData = await callApi(`assignment-progress/${activeTab}`,null,params);
        else
            var assignmentData = await callApi("assignment-progress",null,params)
        setAssignmentProgressData(assignmentData.data)
    }

    useEffect(()=>{
        async function getStudentData() {
            var classData = await callApi("get-class");
            setClassData(classData)
            if(classData.data.length > 0){
                setActiveTab(classData.data[0].id)
                var res = await callApi(`assignment-progress/${classData.data[0].id}`);
                setAssignmentProgressData(res.data)
                setPageSize(res?.meta?.per_page)
                setPageCount(res?.meta?.total)
            }
        }
        async function getChildData() {
            var res = await callApi("assignment-progress");
            setAssignmentProgressData(res.data)
            setPageSize(res?.meta?.per_page)
            setPageCount(res?.meta?.total)
        }
        if(state.type == 5)
            getStudentData()
        else 
            getChildData()
    },[])

    var columns = [
        {
            dataField: "lessonName",
            text: "Lesson",
            sort: true
        },
        {
            dataField: "grade",
            text: "Level",
            sort: true
        },
        {
            dataField: "completedBy",
            text: "Completed By",
            sort: true
        },
        {
            dataField: "score",
            text: "Average Score",
            sort: true
        },
        {
            dataField: "dateAssigned",
            text: "Date Assigned",
            formatter : (cell,row) => {
                return dateConverter(cell)
            }
        },
        {
            dataField: "info",
            text: "More Info",
            formatter: (cell, row, rowIndex) => {
                return (
                    <div className={`assign-detail ${row.score == "-" && "disable-link"}`} 
                        onClick={()=>
                            state.type == 5 ? 
                                navigatePage({
                                    pathname:`/progress/assignment-progress/assignments/${row.lessonId}`,
                                    search: `?classId=${activeTab}`
                                })
                            :
                                navigatePage(`/progress/assignment-progress/assignments/${row.lessonId}`)
                        }>
                        More Info
                    </div>
                )
            }
        }
    ];
    
    return(
        <>
            <BannerComponent title="Assignment Progress" navigate={navigate}/>
            {
                assignmentProgressData ? (
                    <div className="upcomingWebinars-main">
                        <div className="container">
                            <div className="upcomingWebinars-main-wraper">
                                {state.type == 5 && classData.data && classData.data.length > 0 ? (
                                    <>                          
                                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className="tab-items">
                                            <Tabs value={value} onChange={handleChange}>
                                            {
                                                classData.data.map((elem,index)=>(
                                                    <Tab label={`${elem.className}`} key={index} {...a11yProps(elem.id)} />
                                                ))
                                            }
                                            </Tabs>
                                        </Box> 
                                        <div className="tab-content">
                                            <TabPanel value={value} index={value} >                                                                            
                                                <Table rowData={assignmentProgressData} columns={columns} type="Assignment progress"/>
                                                {
                                                    assignmentProgressData.length > 0 &&
                                                        <Pagination
                                                            currentPage={currentPage}
                                                            totalCount={pageCount}
                                                            pageSize={pageSize}
                                                            onPageChange={(page) => {handlePageClick(page,"teacher")}}
                                                        />
                                                }
                                            </TabPanel>
                                        </div>
                                    </>
                                    ) : (
                                        <>
                                            <Table rowData={assignmentProgressData} columns={columns} type="Assignment progress"/>
                                            {
                                                assignmentProgressData.length > 0 &&
                                                <Pagination
                                                    currentPage={currentPage}
                                                    totalCount={pageCount}
                                                    pageSize={pageSize}
                                                    onPageChange={(page) => {handlePageClick(page,"family")}}
                                                />
                                            }
                                        </>
                                    )
                                }
                            </div>
                        </div>
                        {
                            show && <ModalComponent show={show} handleClose={handleClose} type={"badge"}/>
                        }
                    </div>
                ) : (
                    <Spinner/>
                )
            }
        </>
    )
}


export default AssignmentProgress;