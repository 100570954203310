import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Input from '../Input'
import Button from "react-bootstrap/Button";
import { callApi } from '../../ApiCalls';
import * as Yup from "yup";
import { useFormik } from "formik";
import TooltipComponent from '../TooltipComponent';

const AddClass = (props) => {

    const isUpdate = props?.isUpdate;
    const userData = props?.userData;
    const [isDisable, setIsDisable] = useState(false);
    const userSchema1 = Yup.object({
        class_name: Yup.string().required("Class code is required"),
    });
    const initialValues = {
        class_name : isUpdate ? userData.className:"",
    };
    const { values, touched, errors, handleChange, handleSubmit } = useFormik({
        initialValues: initialValues,
        validationSchema: userSchema1,
        onSubmit: async (values, { resetForm }) => {
            const userID = parseInt(localStorage.getItem("userId"));
            var data = {
                class_name : values.class_name
            }
            if (isUpdate) {
                setIsDisable(true)
                var response = await callApi(`update-class/${userData.id}`, data);
                if (response.statusCode == 200) {
                    resetForm();
                    props?.addData();
                    props?.handleClose();
                    setIsDisable(false)
                } else {
                    setIsDisable(false)
                }
            } else {
                setIsDisable(true)
                var response = await callApi('store-class', data);
                if (response.statusCode == 200) {
                    resetForm();
                    props?.addData();
                    props?.handleClose();
                    setIsDisable(false)
                } else {
                    setIsDisable(false)
                  }
            }
        },
    });

    return (
        <div className={`account-details ${isDisable?"modal-disable":""}`}>
            <Form autoComplete="false" onSubmit={handleSubmit} className="row">
                <Form.Group className="form-group full-fuied col-md-12" controlId="formBasicstudent" >
                <div className="half-fuied">
                    <Form.Label>Class Name<span>*</span></Form.Label>
                    <Input
                        type="text"
                        name="class_name"
                        placeholder="Enter Your Classroom Name"
                        value={values.class_name}
                        autoComplete="false"
                        onChange={handleChange}
                    />
                    {/* <TooltipComponent title="Lorem Ipsum is simply dummy text of the printing and typesetting industry." type="text" /> */}
                    {errors.class_name && touched.class_name && (
                        <Form.Text className="text-danger">
                        {errors.class_name}
                        </Form.Text>
                    )}
                </div>
                </Form.Group >
                <div className='col-md-12 mt-2'>
                    <Button variant="primary" type="submit" className="yellow-button" >
                        Save
                    </Button>
                </div>
            </Form>
        </div>
    )
}

export default AddClass