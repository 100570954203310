import React,{useEffect,useState,useContext} from 'react'
import { callApi } from '../../ApiCalls'
import {UserContext} from '../../App'
import { Link,useNavigate } from 'react-router-dom';
import swal from "sweetalert";
import Spinner from '../Loader/Spinner';
import axios from "axios"
import environment from '../../environment';

function a11yProps(index) {
    return {
      "image-value" : index,
    };
}

const ChangeUser = (props) => {

    const [childData,setChildData] = useState([])
    const [loader,setLoader] = useState(false)
    const [isDisable, setIsDisable] = useState(false);
    const { state, dispatch } = useContext(UserContext);
    const navigate = useNavigate();

    const changeUserProfile = async(event) =>{
        swal({
            title: "Are you sure, You want to change user ?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
        .then(async(willChange) => {
            if (willChange) {
                setIsDisable(true)
                var token = localStorage.getItem("token");
                const headers = {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                };
                await axios.post(environment.REACT_APP_API_URL + 'logout',{},{ headers: headers }).then(async(res)=>{
                    if (res.data.statusCode == 200) {
                        var userId = parseInt(event.target.getAttribute("data-value"))
                        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                        var data = {
                            id : userId,
                            timezone : timeZone
                        }
                        await axios.post(environment.REACT_APP_API_URL + 'change-user',data).then((response)=>{
                            if(response.data.statusCode == 200) {
                                localStorage.clear()
                                localStorage.setItem("token", response.data.data.token);
                                localStorage.setItem("userId", response.data.data.user.userId);
                                localStorage.setItem("userRole", response.data.data.user.userRole);
                                localStorage.setItem("users", JSON.stringify(response.data.data.user));
                                localStorage.setItem("isExpired", 0);
                                localStorage.setItem("isPaid", response.data.data.user.planDetails.planType);

                                window.location.href = window.location.origin
                                navigate("/");
                                dispatch({type : "CHECK_SUBSCRIPTION",payload : 0})
                                dispatch({type: "USER",payload: response.data.data.user.userRole});
                                props?.handleClose();
                            }
                        }).catch((err)=>{
                            console.log(err,"Error")
                        })
                    }
                }).catch((err)=>{
                    console.log(err,"Error")
                })
            } 
        });
    }
    useEffect(()=>{
        async function getData () {
            const response = await callApi('get-parent-child-list');
            setChildData(response)
            setLoader(true)
        }
        getData();
    },[])
    return (
        <>
            {
                loader ? (
                    <div className={`changeuser-pop-main ${isDisable?"modal-disable":""}`}>
                        {
                            childData.data && (
                                <>
                                    {
                                        state.type != 3 && 
                                        <Link className='changes-user-pop'  onClick={changeUserProfile}>
                                            <img src={childData.data.avtar} data-value={childData.data.userId}/>
                                            <strong>{childData.data.firstName}</strong>
                                        </Link>
                                    }
                                    {
                                        childData.data.children.length > 0 ?
                                            childData.data.children.map((elem,index)=>(
                                                <React.Fragment key={index}>
                                                    <Link className='changes-user-pop'  onClick={changeUserProfile}>
                                                        <img src={elem.avtar} data-value={elem.childId} />
                                                        <strong>{elem.firstName}</strong>
                                                    </Link>
                                                </React.Fragment>
                                            ))
                                        : (
                                            <>
                                                {
                                                    state.type == 3 &&  <center>You don't have children</center>
                                                }
                                            </>
                                        )
                                    }
                                </>
                            )
                        }
                    </div>
                ) : (
                    <Spinner />
                )
            }
        </>
    )
}

export default ChangeUser