import React, { useEffect, useState, useContext } from "react";
import BannerComponent from "../component/BannerComponent";
import { useLocation } from "react-router-dom";
import Question from "../component/Question/Question";
import Spinner from "../component/Loader/Spinner";
import axios from "axios";
import { callApi } from "../ApiCalls";
import { UserContext } from "../App";
import QuestionLimit from "../assets/images/question-and-answer.svg";
import environment from '../environment';
var index = 0;

const Questions = () => {

  const { state, dispatch } = useContext(UserContext);

  const [queList, setQueList] = useState([]);
  const [currentpage, setcurrentpage] = useState(1);
  const [perPage, setperPage] = useState(1);
  const [queLength,setQueLength] = useState(0)
  const [loader,setLoader] = useState(false)
  const [userProgress,setUserProgres] = useState({})
  const [stateCode,setStatusCode] = useState()

  const location = useLocation();
  const indexOfLastTodo = currentpage * perPage;
  const indexOfFirstTodo = indexOfLastTodo - perPage;
  const currentTodos = queList.slice(indexOfFirstTodo, indexOfLastTodo);

  const submit = () => {
    setcurrentpage(currentpage + 1);
    index++;    
  };
  useEffect(() => {
    async function getData() {
      var slug = location.pathname.substring(location.pathname.lastIndexOf('/') + 1)

      if(state.type == null) {
          await axios.get(environment.REACT_APP_API_URL+`get-lessons/${slug}`).then((res)=>{
          setQueList(res.data.data);
          setStatusCode(res.data.statusCode)
          setQueLength(res.data.data.length)
          setLoader(true)
        }).catch((err)=>{
          console.log("err")
        })
      }
      else {
        var userId = localStorage.getItem("userId");
        await axios.get(environment.REACT_APP_API_URL+`get-lessons/${slug}/${userId}`).then((res)=>{
          setQueList(res.data.data);
          setStatusCode(res.data.statusCode)
          setQueLength(res.data.data.length)
          setLoader(true)
        }).catch((err)=>{
          console.log("err")
        })
      }
    }
    getData();
  }, []);

  return (
    <>
      <BannerComponent title="Question" />
      {
        queList && loader ? (
          <>
            <div className="task-main">
              <div className="container">
                {
                  queLength > 0 ? (
                    <>
                      <div className="task-wrap">
                        {currentTodos.map((elem, index) => (
                          <React.Fragment key={index}>
                            <Question question={elem} userProgress={userProgress} currentpage={currentpage} queLength={queLength} submit={submit} />
                          </React.Fragment>
                        ))}
                      </div>
                    </>
                  ) : (
                    <div className="daily-limit-wrap">
                      <div className='box'>
                        <div className='img-wrap'>
                            <img src={QuestionLimit} className="img-fluid" />
                        </div>
                      {
                        stateCode == 4002 ? 
                          <p>You Have Exceed Your Daily Limit</p>
                        :
                          <p>No Question Found</p>
                      }
                      </div>
                    </div>
                  )
                }
              </div>
            </div>
          </>
        ) : <Spinner />
      }
    </>
  );
};

export default Questions;
