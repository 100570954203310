import React, { useState, useEffect } from "react";
import BannerComponent from "../../component/BannerComponent";
import {Tabs,Tab,Box,Typography} from "@mui/material";
import { callApi, dateConverter } from "../../ApiCalls";
import Spinner from "../../component/Loader/Spinner";
import Pagination from "../../component/Blog/Pagination";
import Table from "../../component/Table/Table";
import { useNavigate } from "react-router";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
    "tab-value" : index,
  };
}
const OverviewAssignment = () => {

  const [value, setValue] = useState(0);
  const [classData,setClassData] = useState([])
  const [assignmentData, setAssignmentData] = useState([])
  const [activeTab,setActiveTab] = useState(0)
  const [currentPage, setCurrentPage] = useState(1);
  const [search,setSearch] = useState("");
  const [pageSize, setPageSize] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const navigate = useNavigate();

  const handleChange = async(event, newValue) => {
    setValue(newValue);
    var classId = event.target.getAttribute("tab-value")
    const response = await callApi(`get-assignment-overview/${classId}`);
    setAssignmentData(response)
    setPageSize(response?.meta?.per_page)
    setPageCount(response?.meta?.total)
    setActiveTab(classId)
  };

  const handlePageClick = async (page) => {
    setCurrentPage(page)
    var params = {
        page : page,
        searchKeyword : search
    }
    getData(params)
  }
  const searchData = async (data) => {
    setSearch(data)
    var params = {
        page : currentPage,
        searchKeyword : data
    }
    getData(params)
  }
  const getData = async(params) => {
    var assignmentData = await callApi(`get-assignment-overview/${activeTab}`,null,params)
    setAssignmentData(assignmentData)
  }

  useEffect(() => {
    async function getData() {
      const classData = await callApi("get-class");
      setClassData(classData)
      if(classData.data.length > 0){
        setActiveTab(classData.data[0].id)
        const response = await callApi(`get-assignment-overview/${classData.data[0].id}`);
        setAssignmentData(response)
        setPageSize(response?.meta?.per_page)
        setPageCount(response?.meta?.total)
      }
      console.log(classData.data,"classData.data")
    }
    getData();
  }, []);

  var columns = [
      // {
      //     dataField: "lessonImage",
      //     text: "Lesson Icon",
      //     formatter : (cell,row) => {
      //         return <img className="lesson-icon" src={cell} />
      //     },
      // },
      {
          dataField: "lessonName",
          text: "Lesson Name",
          sort: true
      },
      {
          dataField: "assignTo",
          text: "Assigned To"
      },
      {
          dataField: "completedBy",
          text: "Completed By"
      },
      {
          dataField: "assignedDate",
          text: "Date Assigned",
          formatter : (cell,row) => {
              return dateConverter(cell)
          }
      },
      {
          dataField: "dueDate",
          text: "Due Date",
          formatter : (cell,row) => {
              return cell == 0 ? "Until Completed":dateConverter(cell)
          }
      },
      {
          dataField: "info",
          text: "More Info",
          formatter: (cell, row, rowIndex) => {
              return (
                  <div className="assign-detail" onClick={()=>navigate(`/assignment-detail/${row.id}`)}>Assignment Detail</div>
              );
          }
      }
    ];

  return (
    <>
    <BannerComponent title="Overview Assignment"/>
      {
        classData.data ? (
          <>
            <div className='account-main'>
              <div className='account-tab'>
                <div className="container">
                  {
                    assignmentData.data ? (
                      <>
                        { 
                          (classData.data && classData.data.length > 0) && (
                            <>                          
                              <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className="tab-items">
                                <Tabs value={value} onChange={handleChange}>
                                  {
                                    classData.data.map((elem,index)=>(
                                      <Tab label={`${elem.className}`} key={index} {...a11yProps(elem.id)} />
                                    ))
                                  }
                                </Tabs>
                              </Box> 
                              <div className="tab-content">
                                <TabPanel value={value} index={value} >
                                <Table rowData={assignmentData.data} columns={columns} type="Assignment overview"/>
                                  {
                                    assignmentData.data.length > 0 && (
                                      <Pagination
                                      currentPage={currentPage}
                                      totalCount={pageCount}
                                      pageSize={pageSize}
                                      onPageChange={(page) => {handlePageClick(page)}}
                                      />
                                    ) 
                                  }    
                                </TabPanel>
                              </div>
                            </>
                          ) 
                        }
                      </>
                    ) : (
                      <>
                        <div className="tab-content">
                          <Table rowData={[]} columns={columns} type="Assignment overview"/>
                        </div>
                      </>
                    )
                  }
                </div>
              </div>
            </div>
          </>
        ) : <Spinner/>
      }
    </>
  );
};

export default OverviewAssignment;
