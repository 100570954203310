import React, {useEffect,useState} from "react";
import BannerComponent from "../../component/BannerComponent";
import MembershipInfo from "../../component/MembershipInfo"
import { callApi } from "../../ApiCalls";
import Spinner from "../../component/Loader/Spinner"

const Pricing = () => {

  const [planData,setPlanData] = useState([])

  useEffect(()=>{
    async function getData() {
      const planData = await callApi('get-membership-plan')
      setPlanData(planData)
    }
    getData()
  },[])

  return (
    <>
      {
        planData.data ? (
          <div className="contact-us">
            <BannerComponent title="Pricing" />
            <div className="common-animation">
              <div className="container"></div>
            </div>
            <div className="common-animation-inner">
              <div className="container">
                <div className="main-title"><h2>Membership Pricing Information</h2></div>
                <MembershipInfo planData={planData.data}/>
              </div>
            </div>
          </div>
        ) : <Spinner/>
      }
    </>
  );
};

export default Pricing;
