import React,{useEffect,useState,useContext} from "react"
import BannerComponent from "../../component/BannerComponent";
import { callApi, dateConverter } from '../../ApiCalls';
import {UserContext} from "../../App"
import {Tabs,Tab,Box,Typography} from "@mui/material";
import Spinner from "../../component/Loader/Spinner";
import Table from "../../component/Table/Table";
import { useNavigate } from "react-router";

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
        {value === index && (
            <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
            </Box>
        )}
        </div>
    );
}
  
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
        "tab-value" : index,
    };
}

const IndividualProgress = () => {
    
    const navigatePage = useNavigate();
    const { state, dispatch } = useContext(UserContext);
    const [individualProgress,setIndividualProress] = useState([])
    const [classData,setClassData] = useState([])
    const [activeTab,setActiveTab] = useState(0)
    const [value, setValue] = useState(0);
    const navigate = window.location.pathname.split('/')[1];

    
    const handleChange = async(event, newValue) => {
        setValue(newValue);
        var classId = event.target.getAttribute("tab-value")
        const response = await callApi(`get-individual-progress/${classId}`);
        setIndividualProress(response.data)
        setActiveTab(classId)
    };

    useEffect(()=>{
        async function getStudentData() {
            var classData = await callApi("get-class");
            setClassData(classData)
            if(classData.data.length > 0){
                setActiveTab(classData.data[0].id)
                const response = await callApi(`get-individual-progress/${classData.data[0].id}`);
                setIndividualProress(response.data)
            }
        }
        async function getChildData() {
            var individualPro = await callApi("get-individual-progress")
            setIndividualProress(individualPro.data)
        }
        if(state.type == 5)
            getStudentData()
        else 
            getChildData()
    },[])

     var columns = [
        {
            dataField: "name",
            text: "Name",
            sort: true
        },
        {
            dataField: "lessonComplete",
            text: "Lessons Completed",
            sort: true
        },
        {
            dataField: "mostRecentLogin",
            text: "Most Recent Login",
            formatter : (cell,row) => {
                return cell == null ? "-" : dateConverter(cell)
            }
        },
        {
            dataField: "averageScore",
            text: "Average Score",
            formatter : (cell,row) => {
                return (cell + "%")
            }
        },
        {
            dataField: "view",
            text: "View Lessons",
            formatter: (cell, row, rowIndex) => {
                return (
                    <div className="assign-detail" onClick={()=>navigatePage(`/progress/individual-progress/progress-report/${row.userId}`)}>Lessons</div>
                );
            }
        }
    ];

    return(
        <>
            <BannerComponent title="Individual Progress" navigate={navigate}/>
            {
                individualProgress ? (
                    <div className="upcomingWebinars-main">
                        <div className="container">
                            <div className="upcomingWebinars-main-wraper">
                                {state.type == 5 && individualProgress && classData.data && classData.data.length > 0 ? (
                                    <>                          
                                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className="tab-items">
                                            <Tabs value={value} onChange={handleChange}>
                                            {
                                                classData.data.map((elem,index)=>(
                                                    <Tab label={`${elem.className}`} key={index} {...a11yProps(elem.id)} />
                                                ))
                                            }
                                            </Tabs>
                                        </Box> 
                                        <div className="tab-content">
                                            <TabPanel value={value} index={value} >                                                                            
                                                <Table rowData={individualProgress} columns={columns} type="Progress"/>
                                            </TabPanel>
                                        </div>
                                    </>
                                    ) :
                                        <Table rowData={individualProgress} columns={columns} type="Progress"/>

                                }
                            </div>
                        </div>
                    </div>
                ) : <Spinner/>
            }
        </>
    )
}

export default IndividualProgress;






