import React,{useState,useContext} from 'react'
import { Link } from 'react-router-dom';
import Diverse from "../../assets/images/diverse.png";
import Plane from "../../assets/images/plane.png";
import { GradeCard } from "../../component/Home/HomeSection/GradeCard";
import { ModalComponent } from '../ModalComponent';
import { UserContext } from "../../App";

const HomeSection = (props) => {

    const gradeData = props?.gradeData

	const { state, dispatch } = useContext(UserContext);
    const [show, setShow] = useState(false);
    const [showAssignment, setShowAssignment] = useState(false)

    const handleClose = () => {
        setShow(false);
    };
    const handleModal = (type) => {
        if(type == "assignment")
            setShowAssignment(true)
        setShow(true);
    };
    return (
        <>
            <div className="inner-menu">
                <div className="container">
                    <ul className="hero-menu">
                        {
                            gradeData.map((elem, index) => (
                                <React.Fragment key={index}>
                                    <GradeCard elem={elem} />
                                </React.Fragment>
                            ))
                        }
                    </ul>
                </div>
            </div>
            <div className="assign-lessons">
                <div className="container">
                    <div className="assign-lessons-wraper">
                        <div className="assign-image">
                            <img src={Diverse} />
                        </div>
                        <div className="assign-contne">
                            <ul>
                                <li>
                                    <Link onClick={() => { handleModal("assignment"); }} className="pink-color">
                                        Assignments
                                    </Link>
                                </li>
                                <li>
                                    {
                                        state.type == 3 ? (
                                            <Link to="/my-children" className="dark-blur-color">
                                                My Children
                                            </Link>
                                        ) : (
                                            <Link to="/my-student" className="dark-blur-color">
                                                My Student
                                            </Link>
                                        )
                                    }
                                </li>
                                <li>
                                    <Link to="/progress" className="green-color">
                                        Progress
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/my-account" className="purple-color">
                                        Account Settings
                                    </Link>
                                </li>
                            </ul>
                            <img src={Plane} />
                        </div>
                    </div>
                </div>
            </div>
            {show && showAssignment && (
                <ModalComponent show={show} handleClose={handleClose} type={"Assignments"} className="assignment" title="Assignments" />
            )}
        </>
    )
}

export default HomeSection