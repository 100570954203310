import React, { useEffect, useState } from "react";
import BannerComponent from "../../component/BannerComponent";
import FAQAccordian from "../../component/Accordian";
import { StyledEngineProvider } from "@mui/material/styles";
import { callApi } from "../../ApiCalls";
import Spinner from "../../component/Loader/Spinner"

const FAQ = () => {
  const [resData, setResData] = useState([]);
  useEffect(() => {
    async function getData() {
     const faqData = await callApi("frequently-asked-questions")
     setResData(faqData);
    }
    getData(); 
  }, []);

  return (
    <>
      { 
        resData.data ?
          <StyledEngineProvider injectFirst>
            <div>
              <BannerComponent bannerData={resData.data.pageData}/>
              <div className="faq-main-section">
                <div className="stars"></div>
                <div className="container">
                  <div className="main-title">
                    <h2 style={{ textTransform: 'initial'}}>Questions and Answers</h2>
                  </div>
                  <div className="faq-wraper">
                      {resData.data && (
                        <>
                          {resData.data.queAndAnsContent.map((elem, index) => (
                            <React.Fragment key={index}>
                              <FAQAccordian title={elem.queAnsTitle} content={elem.queAnsContent} type="text"/>
                            </React.Fragment>
                          ))}
                        </>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </StyledEngineProvider>
        : <Spinner />
      }
    </>
  );
};

export default FAQ;
