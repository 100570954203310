import React, { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import TemplateFooter from "../../TemplateFooter";
import { Radio, RadioGroup, FormControlLabel } from "@mui/material";
import { useEffect } from "react";
import { postRequest } from "../../../ApiCalls";
import StudentImg from "../../../assets/images/student.svg";
import CheckImg from "../../../assets/images/check.svg";
import { UserContext } from "../../../App";
import { useContext } from "react";

const QuestionOptionAbcd = (props) => {
  const question = props?.question;

  const { state, dispatch } = useContext(UserContext);
  const [isAnswerWrong, setIsAnswerWrong] = useState(false);
  const [isHideFooter, setIsHideFooter] = useState(false);
  const [usersAnswer, setUserAnswer] = useState("");
  const [answer, setAnswer] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);

  const handleSubmit = async () => {
    const correctAnswer = question?.questionData?.correct_answer;
    setIsDisabled(false);
    if (answer == "") {
      toast.warning("Please, select answer");
      setIsDisabled(true);
      return 0;
    }
    if (answer === correctAnswer) {
      props?.handleSubmit(true, answer);
    } else {
      setIsAnswerWrong(true);
      setIsHideFooter(true);
      var data = {
        question_id: question.id,
        users_ans: answer.toString(),
      };
      // var res = await postRequest("show-correct-answer", data);
      setUserAnswer(answer.toString());
    }
    setAnswer("");
    setIsDisabled(true);
  };
  const nextQuestion = () => {
    setIsAnswerWrong(false);
    setIsHideFooter(false);
    props?.handleSubmit(false, usersAnswer);
    setUserAnswer("");
  };

  useEffect(() => {
    setIsHideFooter(props?.isHideFooter);
    setUserAnswer(props?.usersAnswer);
  }, []);
  return (
    <div className="question-image-option-abcd question-option-abcd">
      <RadioGroup
        className="custom-radio"
        name="animationSound"
        onChange={(e) => setAnswer(e.target.value)}
      >
        {question?.questionData?.options.map((elem, index) => (
          <div className="options-wrap pb-4">
            <React.Fragment key={index}>
              <h5 className="mb-2">{elem.option_name}</h5>
              <FormControlLabel
                value={elem.option}
                control={<Radio />}
                label={elem.option}
              />
            </React.Fragment>
          </div>
        ))}
      </RadioGroup>
      {!isHideFooter && (
        <div className="questions-footer">
          <TemplateFooter question={question} />
          <div className="question-btn">
            <Link
              className="yellow-button"
              onClick={() => isDisabled && handleSubmit()}
            >
              Submit
            </Link>
          </div>
        </div>
      )}
      {usersAnswer && (
        <div className='answer-info'>
          <div className='box'>
              <span className='student'>{state.type == 3 || state.type == 4 ? "Children" : "Student"} Answer <img src={StudentImg} className="img-fluid"/></span>
              <h5>{usersAnswer}</h5>
          </div>
          <div className='box'>
              <span className='correct'>Correct Answer <img src={CheckImg} className="img-fluid"/></span>
              <h5>{question?.questionData?.correct_answer}</h5>
          </div>
      </div>
      )}
      <div className='explanation-info'>
      {usersAnswer && isAnswerWrong && (
        <div className='details'>
          <span>Explanation</span>
          {question?.hintType == 0 ? (
            <p>{question.questionData.answer_hint}</p>
          ) : (
            <img src={question.questionData.answer_hint} className="img-fluid question-img" />
          )}
        </div>
      )}
      {isAnswerWrong && (
        <div className="question-btn">
          <Link className="yellow-button" onClick={nextQuestion}>
            Next
          </Link>
        </div>
      )}
      </div>
    </div>
  );
};

export default QuestionOptionAbcd;
