import React from "react";
import {ThreeDots} from "react-loader-spinner";
import "./spinner.css";
import loader from "../../assets/images/pre-loader.svg";

const Spinner = () => {

    return (
        <div className="spinner">
            <ThreeDots type="ThreeDots" color="#2BAD60" height="100" width="100" />
        </div>
        // <div className="pre-loader">
        //     <div className="loader-effect">
        //         <div id="animationWindow">
        //             <img src={loader} width={150} height={150}/>
        //         </div>
        //     </div>
        // </div>
    );
};

export default Spinner
