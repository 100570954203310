import React, { useState} from "react";
import InfoMain from "../assets/images/info-main.png";
import Board from "../assets/images/edit.png";
import { Link } from "react-router-dom";
import { ModalComponent } from "../component/ModalComponent";
import GblObject from "../GblData";

const TemplateFooter = (props) => {
  const question = props?.question;
  const [show, setShow] = useState(false);
  const [showBoard, setShowBoard] = useState(false)
  const [hint, setHint] = useState(false)
  const [showInfo,setShotInfo] = useState(false)
  
  const handleClose = () => {
    setShow(false);
    setShowBoard(false)
    setHint(false);
    setShotInfo(false)
  };
  const handleModal = (type) => {
    setShow(true);
    if(type === "sketchBoard")
      setShowBoard(true)
    else if(type === "hint")
      setHint(true);
    else 
      setShotInfo(true)
  };

  return (
    <>
      <div className="hint">
        {
          GblObject.hint != 2 && (
            <Link onClick={() => { handleModal("hint"); }} className="hint-button">
              <img src={InfoMain} />
              Hint
            </Link>
          )
        }
        <Link onClick={() => { handleModal("sketchBoard"); }}className="hint-button">
          <img src={Board} />
        </Link>
      </div> 
      { 
        show && showBoard ?
          <ModalComponent show={show} handleClose={handleClose} type={"sketchBoard"} className="sketchBoard" title="Sketch Board" />
        : show && hint ?
          <ModalComponent infoData={question.questionData.answer_hint} hint={question.hintType} show={show} handleClose={handleClose} type={"hint"} className="hint" title="Hint" />
        : ""
      }
    </>
  )
}

export default TemplateFooter