import React,{useState} from 'react'
import {Form,Button} from "react-bootstrap";
import { toast } from 'react-toastify';
import * as Yup from "yup";
import { useFormik } from "formik";
import TemplateFooter from '../../TemplateFooter';
import { postRequest } from '../../../ApiCalls';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import StudentImg from "../../../assets/images/student.svg";
import CheckImg from "../../../assets/images/check.svg";
import { UserContext } from '../../../App';
import { useContext } from 'react';

const StandardFillInput = (props) => {
    const question = props?.question

    const { state, dispatch } = useContext(UserContext);
    const [isDisabled,setIsDisabled] = useState(true)
    const [isAnswerWrong,setIsAnswerWrong] = useState(false)
    const [isHideFooter,setIsHideFooter] = useState(false);
    const [usersAnswer,setUserAnswer] = useState("")

    const userSchema = Yup.object().shape({
        answer: Yup.string().required("Answer field is required")
    });
    const initialValues = {
        answer: "",
    }
    function ciEquals(a, b) {
        return typeof a === 'string' && typeof b === 'string'
            ? a.localeCompare(b, undefined, { sensitivity: 'accent' }) === 0
            : a === b;
    }
    const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
        initialValues: initialValues,
        validationSchema: userSchema,
        validateOnChange:true,
        onSubmit: async (values, { resetForm }) => {
            const correctAnswer = question?.questionData?.correct_answer
            const answer = values.answer.trim()
            setIsDisabled(false)
            if(answer == "") {
                toast.warning("Please, write answer");
                setIsDisabled(true)
                return 0
            }
            var isCorrect = ciEquals(answer,correctAnswer)
            if (isCorrect == true) {
                props?.handleSubmit(true,answer)
            } else {
                   setIsAnswerWrong(true)
            setIsHideFooter(true)
            var data = {
                question_id : question.id,
                users_ans : answer
            }
            // var res = await postRequest("show-correct-answer",data)
            setUserAnswer(answer)
            // console.log(res,"DD")

            // props?.handleSubmit(false, answer)
            }
            setIsDisabled(true)
            resetForm()
        },
    });
    const nextQuestion = () => {
        setIsAnswerWrong(false)
        setIsHideFooter(false)
        setUserAnswer("")
        props?.handleSubmit(false, usersAnswer)
    }

    useEffect(()=>{
        setIsHideFooter(props?.isHideFooter)
        setUserAnswer(props?.usersAnswer)
    },[])
    return (
        <div className='standard-fill-input'>
            <div className='img-box'>
                <img src={question?.questionData?.select_question_image?.images.replace('original', 'thumb')} />
            </div>
            <Form autoComplete="false" onSubmit={handleSubmit}>
                <Form.Group controlId="formBasicstudent" >
                    <div className="input-field py-4">
                        <Form.Label className='mb-0'>Answer: </Form.Label>
                        <Form.Control
                            type="text"
                            name="answer"
                            onChange={handleChange}
                            value={values.answer}
                            required
                            autoComplete="off"
                        />
                    </div>
                    {/* {errors.answer && touched.answer && (
                        <Form.Text className="text-danger"> {errors.answer} </Form.Text>
                    )} */}
                </Form.Group>

                {
                    !isHideFooter &&
                        <div className='questions-footer'>
                            <TemplateFooter question={question}/>
                            <div className='question-btn'>
                                <Button className="yellow-button" type="submit">
                                    Submit
                                </Button>
                            </div>
                        </div>
                }
                {
                usersAnswer &&
                <div className='answer-info'>
                    <div className='box'>
                        <span className='student'>{state.type == 3 || state.type == 4 ? "Children" : "Student"} Answer <img src={StudentImg} className="img-fluid"/></span>
                        <h5>{usersAnswer}</h5>
                    </div>
                    <div className='box'>
                        <span className='correct'>Correct Answer <img src={CheckImg} className="img-fluid"/></span>
                        <h5>{question?.questionData?.correct_answer}</h5>
                    </div>
                </div>
            }
            <div className='explanation-info'>
            {
                    usersAnswer && isAnswerWrong &&
                        <div className='details'>
                            <span>Explanation</span>
                            {
                                question?.hintType == 0 ? 
                                    <p>{question.questionData.answer_hint}</p>
                                :
                                    <img src={question.questionData.answer_hint} className="img-fluid question-img" />
                            }                        
                            </div>
                }  
                {
                    isAnswerWrong && 
                    <div className='question-btn'>
                        <Link className="yellow-button" onClick={nextQuestion}>
                            Next
                        </Link>
                    </div>
            } 
            </div>
            </Form>
        </div>
    )
}

export default StandardFillInput