import React,{useEffect,useState} from 'react'
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { Link, useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import BannerComponent from '../component/BannerComponent';
import { callApi } from '../ApiCalls';
import Spinner from '../component/Loader/Spinner';

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
    ))(({ theme }) => ({
      [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common,
      },
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
      },
}));

const GradeLessonSearch = () => {

    const navigate = useNavigate();
    const [lessonData,setLessonData] = useState([])

    const handleClick = (slug) => {
        navigate(`/grade-lesson-search/${slug}`)
    }

    const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
    });
    useEffect(()=>{
        async function getData() {
            const response = await callApi(`search-lessons/${params.search}`)
            setLessonData(response)
        }
        getData()
    },[params.search])

    return (
        <>
            {
                lessonData.data ? (
                    <div className="class-tab-main">
                        <BannerComponent title="Lesson"/>
                        <div className="common-lessions-inner">
                            <div className="container">
                                <div className="common-lessions-inner-wrap">
                                    <div className="common-lessions-inner-repeat">
                                        <ul className='search-area'>
                                            {
                                                lessonData.data.length > 0 ? lessonData.data.map((elem, index) => (
                                                    <React.Fragment key={index}>
                                                        {
                                                            elem.status == 1 && 
                                                                <li >
                                                                    <>
                                                                        <div onClick={()=>handleClick(elem.slug)} key={index}>
                                                                            <Link to={`/grade-lesson-search/${elem.slug}`}>
                                                                                <div className='img-wrap'>
                                                                                    <img src={elem.gradeImage} alt="grade image" className='img-fluid'></img>
                                                                                </div>
                                                                                <div className='category-name'>
                                                                                    {/* <span className="display-grade">{"Grade "+elem.lessonsGrade+" : "}</span> */}
                                                                                    <span className="display-category">{ elem.categoryName}</span>
                                                                                    <span className="display-subCategory">{elem.name}</span>
                                                                                </div>
                                                                            </Link>
                                                                        </div>
                                                                    </>
                                                                </li>
                                                        }
                                                    </React.Fragment>
                                                )) :  (
                                                    <center>No lesson found</center>
                                                )
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : 
                <Spinner/>
            }
        </>
    )
}

export default GradeLessonSearch