import React from "react";
import Modal from "react-bootstrap/Modal";
import AddClass from "./ModalContent/AddClass";
import Assignment from "./ModalContent/Assignment";
import SignIn from "./ModalContent/SignIn";
import BlogInfo from "./ModalContent/BlogInfo";
import AddEditChildren from "./ModalContent/AddEditChildren";
import EditAccountDetail from "./ModalContent/EditAccountDetail";
import AddEditStudent from "./ModalContent/AddEditStudent";
import SketchBoard from "./ModalContent/SketchBoard";
import QuestionHint from "./ModalContent/QuestionHint";
import AssignAssignment from "./ModalContent/AssignAssignment";
import ChangeUser from "./ModalContent/ChangeUser";
import LessonInfo from "./ModalContent/LessonInfo";
import Certificate from "./ModalContent/Certificate";
import Badge from "./ModalContent/Badge";
import ViewCertificate from "./ModalContent/ViewCertificate";

export const ModalComponent = (props) => {

  const infoData = props?.infoData;
  const hint = props?.hint;
  const className = props?.className;
  const detailType = props?.detailType;
  const label = props?.label;
  const oldValue = props?.oldValue;
  const gradeData = props?.gradeData;
  const isUpdate = props?.isUpdate;
  const userData = props?.userData;
  const classData = props?.classData;
  const classId = props?.classId;
  return (
    <Modal
      show={props.show}
      onHide={props.handleClose}
      className={`common-pop ${className ? className : ""}`}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {props.type == "EditDetail" ? `Change ${label}` : props?.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.type === "Login" ? (
          <SignIn handleClose={props?.handleClose}/>
        ) : props.type === "Assignments" ? (
          <Assignment handleClose={props?.handleClose}/>
        ) : props.type === "EditDetail" ? (
          <EditAccountDetail detailType={detailType} label={label} oldValue={oldValue} getAccountDetails={props?.getAccountDetails} handleClose={props?.handleClose}/>
        ) : props.type == "addChildren" ? (
          <AddEditChildren gradeData={gradeData} addData={props.addData} userData={userData} isUpdate={isUpdate} handleClose={props?.handleClose}/>
        ) : props.type == "addClass" ? (
          <AddClass addData={props?.addData} userData={userData} isUpdate={isUpdate} handleClose={props?.handleClose}/>
        ) : props.type == "addStudent" ? (
          <AddEditStudent gradeData={gradeData} addData={props.addData} classId={classId} classData={classData} handleClose={props?.handleClose} userData={userData} isUpdate={isUpdate}/>
        ) : props.type == "sketchBoard" ? (
          <SketchBoard/>
        ) : props.type == "hint" ? (
          <QuestionHint infoData={infoData} hint={hint} />
        ) : props.type == "view" ? (
          <ViewCertificate infoData={infoData}/>
        ) : props.type == "assignment" ? (
          <AssignAssignment lessonId={gradeData} getData={props?.getData} handleClose={props?.handleClose}/>
        ) : props.type == "changeUser" ? (
          <ChangeUser handleClose={props?.handleClose}/>
        ): props.type == "description" ? (
          <LessonInfo infoData={infoData}/>
        ) : props.type == "certificate" ? (
          <Certificate handleClose={props?.handleClose} title={props?.title} infoData={infoData} userData={userData} />
        ) : props.type == "badge" ? (
          <Badge handleClose={props?.handleClose} userData={infoData} />
        ) : (
          <BlogInfo infoData={infoData}/>
        )}
      </Modal.Body>
    </Modal>
  );
};