import React from 'react'
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import "./Table.css"

const Table = (props) => {

    const rowData = props?.rowData;
    const columns = props?.columns;
    const type = props?.type;
    const keyField = props?.keyField;

    const { SearchBar } = Search;

    const ClearButton = (props) => {
    }

    const rowClasses = (row,rowIndex) => {
        // console.log(row,"row")
        // console.log(rowIndex,"rowIndex")
    }
    return (
        <>
            <ToolkitProvider
                bootstrap4
                keyField={keyField?keyField:"id"}
                data={rowData}
                columns={columns}
                search
            >
                {(props) => (
                    <div className='my-table-wraper'>
                        <div className='my-table'>
                            <strong>{rowData.length} {type}</strong>
                            <SearchBar
                                srText = ""
                                {...props.searchProps}
                            />
                        </div>
                        <ClearButton
                            {...props.searchProps}
                            // clearAllFilter={clearAllFilter}
                        /> 
                        <div className='table-responsive common-table'>
                            <BootstrapTable
                            {...props.baseProps}
                            noDataIndication="No record found"
                            // striped
                            rowClasses={rowClasses}
                            hover
                            condensed
                            />
                        </div>
                    </div>
                )}
            </ToolkitProvider>
        </>
    )
}

export default Table
