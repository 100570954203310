import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { callApi, dateConverter } from "../../ApiCalls";
import BannerComponent from "../../component/BannerComponent";
import Spinner from "../../component/Loader/Spinner";
import MangeTemplate from "../../component/Question/ManageTemplate";

const AssignmentProgressReport = () => {

  const location = useLocation();  

  const [allAnswer, setAllAnswer] = useState([]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    
    async function getData() {
      var assignmentId = location.pathname.substring(location.pathname.lastIndexOf('/') + 1)
        if(params.type == "all") 
          var res = await callApi(`assignment-all-answer/${assignmentId}`);
        else
          var res = await callApi(`assignment-wrong-answer/${assignmentId}`);
      setAllAnswer(res.data);
      setLoader(true);
    }
    getData();
  }, []);

  return (
    <>
      {allAnswer && loader ? (
        <>
          <BannerComponent title="Assignment Progress Report"/>
          <div className="progress-report-wrap">
            <div className="container">
              <ul>
                <li><span>User Name:</span> {allAnswer.studentName}</li>
                <li><span>Lesson Name:</span> {allAnswer.lessonName}</li>
                <li><span>Score:</span> {allAnswer.score}</li>
                <li><span>Lesson Completed:</span> {dateConverter(allAnswer.lessonCompleted)}</li>
              </ul>

              <div className="reports-display">
                {
                  allAnswer.questions.length > 0 ? allAnswer.questions.map((elem, index) => (
                    <React.Fragment key={index}>
                      <div className={`question-display ${elem.isCorrect == 1 ? "right-answer" : "wrong-answer"}`}>
                        <h4 className="assignment-question">{elem.question.questionData.question_text}</h4>
                        <MangeTemplate questionData={elem.question} usersAnswer={elem.usersAnswer} isHideFooter={true}/>
                      </div>
                    </React.Fragment>
                  )) : 
                    <center>No Data Found</center>
                }
              </div>
            </div>
          </div>
        </>
      ) : (
        <Spinner />
      )}
    </>
  );
};

export default AssignmentProgressReport;