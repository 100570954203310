import React,{useState,useEffect} from "react"
import BannerComponent from "../../component/BannerComponent";
import {Radio,RadioGroup,FormControlLabel,FormControl,FormLabel} from '@mui/material';
import {Form} from "react-bootstrap";
import { callApi } from "../../ApiCalls";
import Spinner from "../../component/Loader/Spinner";

const MySetting = () => {

    const [animationSound,setAnimationSound] = useState()
    const [animation,setAnimation] = useState()
    const [loader,setLoader] = useState(false)
    
    const handleSubmit = async(e) => {
        e.preventDefault();
        var data = {
            animation_sound : animationSound,
            animation : animation
        }
        callApi("save-setting",data)
    }
    useEffect(()=>{
        async function getData(){
            var accountDetail = await callApi('get-setting')
            setAnimationSound(accountDetail.data.animationSound)
            setAnimation(accountDetail.data.animation)
            setLoader(true)
        }
        getData()
    },[])

    return(
        <>
            {
                loader ? (
                    <>
                        <BannerComponent title="My Setting"/>
                        <div className="my-setting-form">
                            <div className="container">
                                <div className="my-setting-inner">
                                    <Form autoComplete="false" onSubmit={handleSubmit}>
                                        <FormControl className="frm-details">
                                            <FormLabel id="demo-radio-buttons-group-label">Animation Sound:</FormLabel>
                                            <RadioGroup
                                                className="custom-radio"
                                                value = {animationSound}
                                                onChange={(e)=>setAnimationSound(e.target.value)}
                                                name="animationSound"
                                            >
                                                <FormControlLabel value={0} control={<Radio />} label="On" />
                                                <FormControlLabel value={1} control={<Radio />} label="Off" />
                                            </RadioGroup>
                                        </FormControl>
                                        <FormControl className="frm-details animation-wrap">
                                            <FormLabel id="demo-radio-buttons-group-label">Animation:</FormLabel>
                                            <RadioGroup
                                                className="custom-radio"
                                                value={animation}
                                                onChange={(e)=>setAnimation(e.target.value)}
                                                name="animation"
                                            >
                                                <FormControlLabel value={0} control={<Radio />} label="None" />
                                                {/* <FormControlLabel value={1} control={<Radio />} label="Auto" />
                                                <FormControlLabel value={2} control={<Radio />} label="Emoji" /> */}
                                                <FormControlLabel value={3} control={<Radio />} label="Animation" />
                                            </RadioGroup>
                                        </FormControl>

                                        <div className="my-setting-buttons">
                                            {/* <button className="yellow-button btn btn-primary">Cancel</button> */}
                                            <button className="yellow-button btn btn-primary" type="submit">Save Settings</button>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </>
                ) : <Spinner/>
            }
        </>
    )
}

export default MySetting;
