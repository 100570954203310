import React,{ useState,useEffect,useRef,useContext } from 'react'
import BannerComponent from '../../component/BannerComponent'
import { Link } from "react-router-dom";
import {Row,Col,Form, Button} from 'react-bootstrap';
import * as Yup from "yup";
import { useFormik } from "formik";
import { callApi, dateConverter, postRequest } from '../../ApiCalls';
import Spinner from '../../component/Loader/Spinner';
import { UserContext } from '../../App';

const Renew = () => {
  const [price, setPrice] = useState(0);
  const [user,setUser] = useState(0)
  const [month,setMonth] = useState(0)
  const [numberOfClass,setNumberOfClass] = useState(0)
  const [planId,setPlanId] = useState(0)
  const [planMonth,setPlanMonth] = useState(0)

  const [isDisable, setIsDisable] = useState(false);
  const [userData,setUserData] = useState({})
  const [planData,setPlanData] = useState([])
  const [plan,setPlan] = useState()
  const isUnmountedRef = useRef(false)

  const { state, dispatch } = useContext(UserContext);

  const selectPrice = (e) => {
    var amount = e.target.options[e.target.selectedIndex].dataset.amount;
    var user = e.target.options[e.target.selectedIndex].dataset.user;
    var month = e.target.options[e.target.selectedIndex].dataset.month;
    var noOfclass = e.target.options[e.target.selectedIndex].dataset.class;
    var planid = e.target.options[e.target.selectedIndex].dataset.planid;
    var planmonth = e.target.options[e.target.selectedIndex].dataset.planmonth;
    setPrice(amount);
    setUser(user)
    setMonth(month)
    setNumberOfClass(noOfclass)
    setPlanId(planid)
    setPlanMonth(planmonth)
  };

  const userSchema = Yup.object({
    plan: Yup.string().required("Plan is required"),
	});
	const initialValues = {
    plan : ""
	};
	const { values, touched, errors, handleChange, handleSubmit } = useFormik({
		initialValues: initialValues,
		validationSchema: userSchema,
		onSubmit: async (values, { resetForm }) => {
      var data = {
        price_id : values.plan,
        planId : planId,
        planMonth : planMonth
      }
		  var response = await postRequest('create-product-checkout',data);
      if(response.statusCode == 200) {
        window.location.href = response.data.paymentDetails.url
      }
			// resetForm();
		},
	});

  useEffect(()=>{
    if (isUnmountedRef.current == false){
      async function getDate() {
        var userData = await callApi("my-account/get-account-details")
        setUserData(userData.data)
        var planData = await callApi("get-renew-plans")
        setPlanData(planData)
      }
      getDate()
    }
    return () => { isUnmountedRef.current = true }
  },[])

  return (
    <>
    {
      planData.data ? (
        <>
          <BannerComponent title="Renew"/>
          <section className='renew-wrap'>
            <div className='container'>
              <Row className='mb-5'>
                <Col lg={5} xs={12}>
                  <div className='form-wrap'>
                    <Form className="row">
                      <Form.Group className="form-group full-fuied col-lg-6 col-md-6 col-sm-12">
                          <Form.Label>Account Type</Form.Label>
                          <Form.Control type="text" value={userData.accountType} disabled />
                      </Form.Group>

                      <Form.Group className="form-group full-fuied col-lg-6 col-md-6 col-sm-12">
                          <Form.Label>Name</Form.Label>
                          <Form.Control type="text" value={userData.firstName+" "+userData.lastName} disabled />
                      </Form.Group>

                      <Form.Group className="form-group full-fuied col-lg-12">
                          <Form.Label>Email</Form.Label>
                          <Form.Control type="email" value={userData.email} disabled />
                      </Form.Group>

                      <Form.Group className="form-group full-fuied col-lg-6 col-md-6 col-sm-12">
                          <Form.Label>Account Created</Form.Label>
                          <Form.Control type="email" value={dateConverter(userData.accountCreatedAt)} disabled />
                      </Form.Group>

                      <Form.Group className="form-group full-fuied col-lg-6 col-md-6 col-sm-12">
                          <Form.Label>Account Expires</Form.Label>
                          <Form.Control type="email" value={dateConverter(userData.accountExpiration)} disabled />
                      </Form.Group>
                    </Form>
                  </div>
                </Col>
                <Col lg={7} xs={12}>
                  <div className='form-wrap'>
                    <Form onSubmit={handleSubmit}>
                      <Form.Group className="form-group full-fuied col-lg-12">
                          <Form.Label>Select Plan</Form.Label>
                          <Form.Select name="plan" onChange={(e) => {handleChange(e);selectPrice(e)}}>
                            <option value="">Select Plan</option>
                            {
                              planData.data.map((elem,index)=>(
                                <option value={elem.priceId} key={index} data-planmonth={elem.planMonth} data-planid={elem.membershipPlanId} data-class={elem.noOfClass} data-amount={elem.planPrice} data-user={elem.noOfChildStudent} data-month={elem.planMonth}>Up to {elem.noOfChildStudent} {state.type == 5 ? "student" : "children"} for {elem.planMonth} Month / (${elem.planPrice}) {elem.noOfClass !=null && "- "+elem.noOfClass+" Class"}</option>
                              ))
                            }
                          </Form.Select>
                          {errors.plan && touched.plan && (
                            <Form.Text className="text-danger">
                              {errors.plan}
                            </Form.Text>
                          )}
                      </Form.Group>   
                      
                      <Form.Group className="form-group half-fuied-main">
                        <div className="half-fuied total-cost">
                          <Form.Label>Total Cost</Form.Label>
                          <Form.Label className='form-control price' disabled>${price}</Form.Label>
                        </div>
                        {
                          state.type == 5 && (
                            <div className="half-fuied total-cost">
                              <Form.Label>No. Of Class</Form.Label>
                              <Form.Label className='form-control price' disabled>{numberOfClass}</Form.Label>
                            </div> 
                          )
                        }
                        <div className="half-fuied total-cost">
                          <Form.Label>No. Of {state.type == 5 ? "Student" : "Children"}</Form.Label>
                          <Form.Label className='form-control price' disabled>{user}</Form.Label>
                        </div> 
                        <div className="half-fuied total-cost">
                          <Form.Label>No. of Month</Form.Label>
                          <Form.Label className='form-control price' disabled>{month}</Form.Label>
                        </div>             
                      </Form.Group>   

                      <Form.Group className="form-group full-fuied checkbox">
                        <Form.Check
                          checked={isDisable ? true : false}
                          type="checkbox"
                          className="custom-check"
                          onClick={() => setIsDisable(isDisable ? false : true)}
                        />
                        <div className="chech-box-form" onClick={() => setIsDisable(isDisable ? false : true)}>
                          I am over 18 and I have read and agree to iSoar Math{" "}
                          <Link target="_blank" to="/privacy-policy">Privacy Policy</Link> and{" "}
                          <Link target="_blank" to="/terms-of-use">Terms.</Link>
                        </div>
                      </Form.Group>
                      <Button variant="primary" disabled={isDisable ? false : true} type="submit" className="yellow-button">Submit</Button>
                    </Form>
                  </div>
                </Col>
              </Row>
            </div>
          </section>
        </>
      ) : (
        <Spinner/>
      )
    }
    </>
  )
}

export default Renew