import React,{ useEffect,useContext } from 'react'
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles
} from "react-circular-progressbar";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import "./question.css"
import "react-circular-progressbar/dist/styles.css";
import GblObject from '../../GblData';
import { UserContext } from '../../App';

const Progress = (props) => {

  const queLength = props?.queLength;
  const currentpage = props?.currentpage;
  const correctAns = props?.correctAns;
  const progress = props?.progress;
  const lessonTime = props?.lessonTime;
  const score = props?.score;
  const videoAnimation = props?.videoAnimation;
  const userQuestionProgress = props?.userQuestionProgress;

  const { state, dispatch } = useContext(UserContext);

  const videoEnd = () => {
    props?.submit()
    var animation = document.getElementById('animation');
    animation.pause();
    animation.setAttribute('src', null);
  }

  useEffect(()=>{

    function animationSetting() {
      if(videoAnimation == "Right") {
        if(GblObject.videoAnimation.correct.length == GblObject.displayCorrectVideo)
          GblObject.displayCorrectVideo = 1;
        else 
          GblObject.displayCorrectVideo=GblObject.displayCorrectVideo+1
  
        var videoSrc = GblObject.videoAnimation.correct.filter((elem)=>{
          return elem.index == GblObject.displayCorrectVideo
        })
        
        var animation = document.getElementById('animation');
        animation.pause();
        animation.setAttribute('src', videoSrc[0].url);
        animation.loop = false;
        animation.onloadeddata = function() {
          animation.play();
        };
        if(GblObject.animationSound == 1)
          animation.volume = 0.0
      } else if(videoAnimation == "Wrong") {
        if(GblObject.videoAnimation.inCorrect.length == GblObject.displayInCorrectVideo)
          GblObject.displayInCorrectVideo = 1;
        else 
          GblObject.displayInCorrectVideo=GblObject.displayInCorrectVideo+1
  
        var videoSrc = GblObject.videoAnimation.inCorrect.filter((elem)=>{
          return elem.index == GblObject.displayInCorrectVideo
        })
        var animation = document.getElementById('animation');
        animation.pause();
        animation.setAttribute('src', videoSrc[0].url);
        animation.loop = false;
        animation.onloadeddata = function() {
          animation.play();
        };
        if(GblObject.animationSound == 1)
          animation.volume = 0.0
      }
    }
    if(state.type == 4 || state.type == 6) {
      if(GblObject.animation == 0 && GblObject.studentAnimation == 3) {
        animationSetting()
      }
    } else {
      animationSetting()
    }

  },[GblObject.totalAttempt])

  return (
    <div className="perform">
      {
        lessonTime != 0 ? (
        <div className="progress-bar common-in-progress">
          <div className='CountdownCircleTimer'>
          <span>Timer</span>
          <CountdownCircleTimer isPlaying duration={lessonTime} colors={['#3e98c7', '#F7B801', '#A30000', '#A30000']} >
            {
              ({ remainingTime }) => {
                let minutes = Math.floor(remainingTime / 60);
                let extraSeconds = remainingTime % 60;
                minutes = minutes < 10 ? "0" + minutes : minutes;
                extraSeconds = extraSeconds< 10 ? "0" + extraSeconds : extraSeconds;

                if(minutes == "00" && extraSeconds == "01"){
                  props?.timeExpired()
                }
                return minutes+":"+extraSeconds
              }
            }
          </CountdownCircleTimer>
          </div>
        </div>
        ) : (
          <div className="progress-bar common-in-progress">
            <span>Progress</span>
            <CircularProgressbar value={progress} text={`${userQuestionProgress}/${queLength}`} />
          </div>
        )
      }
      <div className="progress-bar common-in-progress">
        <span>Score</span>
        <CircularProgressbar value={score} text={`${correctAns}/${queLength}`} />
      </div>
      {
        <div className=" common-in-progress">
          {/* <video autoPlay onEnded={() => playIdle()} height="100%" id="animation"> */}
          <video onEnded={() => videoEnd()} height="100%" id="animation">
            <source type="video/mp4"/>
          </video>
        </div>
      }
    </div>
  )
}

export default React.memo(Progress);