import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import * as Yup from "yup";
import { useFormik } from "formik";
import BannerComponent from "../../component/BannerComponent";
import resetPassword from "../../../src/assets/images/reset-password.png";
import { callApi } from "../../ApiCalls";
import TooltipComponent from "../../component/TooltipComponent";
import { InputGroup } from "react-bootstrap";
import { IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const ResetPassword = () => {
    const query= new URLSearchParams(useLocation().search);
    const token = query.get("token") || "";
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const handleClickshowConfirmPassword = () => setShowConfirmPassword((show) => !show);
    const handleMouseDownConfirmPassword = (event) => {
      event.preventDefault();
    };
  const userSchema = Yup.object({
    email: Yup.string().email("Email must be a valid email").required("Email is required"),
    password: Yup
    .string()
    .min(8,"Password must be at least 8 characters").max(20,"Password must be at most 20 characters")
    .required('Password is required')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])/,
      "One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
    confirm_password: Yup.string()
      .required("Confirm Password is required")
      .oneOf(
        [Yup.ref("password"), null],
        "Password and confirm password must match"
      ),
  });
  const initialValues = {
    email: "",
    password: "",
    confirm_password: "",
    // token : token
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: userSchema,
      onSubmit: async (values, { resetForm }) => {
        const response = await callApi("reset-password", values);
        if (response.statusCode == 200) {
          resetForm();
        }
      },
    });
  return (
    <>
      <BannerComponent  title="Reset Password"/>
      <div className="sign-up-form-wraper">
        <div className="container">
          <div className="sign-in-form-wraper">
            <div className="sign-in-image">
              <img src={resetPassword} />
            </div>
            <div className="common-form">
              <h2>Reset Request</h2>
              <Form autoComplete="false" autoFocus={false} onSubmit={handleSubmit}>
                <Form.Group
                  className="form-group full-fuied"
                  controlId="formBasicstudent"
                >
                  <div className="half-fuied">
                  <Form.Label>Email<span>*</span></Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    placeholder="Enter Your Email Address"
                    onChange={handleChange}
                    value={values.email}
                  />
                  {/* <TooltipComponent title="Lorem Ipsum is simply dummy text of the printing and typesetting industry." type="text"/> */}
                  {errors.email && touched.email && (
                    <Form.Text className="text-danger">
                      {errors.email}
                    </Form.Text>
                  )}
                  </div>
                </Form.Group>
                <Form.Group
                  className="form-group full-fuied"
                  controlId="formBasicstudent"
                >
                  <div className="half-fuied input-password">
                  <Form.Label>Password<span>*</span></Form.Label>
                  <Form.Control
                    // type="password"
                    type={showPassword ? 'text' : 'password'}
                    name="password"
                    placeholder="Enter Password"
                    onChange={handleChange}
                    value={values.password}
                  />
                  <InputGroup.Text id="basic-addon2">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                  </InputGroup.Text>
                  {/* <TooltipComponent title="Lorem Ipsum is simply dummy text of the printing and typesetting industry." type="text"/> */}
                  {errors.password && touched.password && (
                    <Form.Text className="text-danger">
                      {errors.password}
                    </Form.Text>
                  )}
                  </div>
                </Form.Group>
                <Form.Group
                  className="form-group full-fuied"
                  controlId="formBasicstudent"
                >
                  <div className="half-fuied input-password">
                  <Form.Label>Confirm Password<span>*</span></Form.Label>
                  <Form.Control
                    // type="password"
                    type={showConfirmPassword ? 'text' : 'password'}
                    name="confirm_password"
                    placeholder="Enter Confirm Password"
                    onChange={handleChange}
                    value={values.confirm_password}
                  />
                  <InputGroup.Text id="basic-addon2">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickshowConfirmPassword}
                          onMouseDown={handleMouseDownConfirmPassword}
                          edge="end"
                        >
                          {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                  </InputGroup.Text>
                  {/* <TooltipComponent title="Lorem Ipsum is simply dummy text of the printing and typesetting industry." type="text"/> */}
                  {errors.confirm_password && touched.confirm_password && (
                    <Form.Text className="text-danger">
                      {errors.confirm_password}
                    </Form.Text>
                  )}
                  </div>
                </Form.Group>
                <Button
                  variant="primary"
                  type="submit"
                  className="yellow-button"
                >
                  Submit
                </Button>
                <p>
                  <b>Educators:</b> Enter your e-mail address, and instructions
                  to reset your password will be sent to you.
                </p>
                <p>
                  <b>Students:</b> Please ask your teacher for your password.
                  Student passwords cannot be reset via e-mail.
                </p>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
