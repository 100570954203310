import React, { useEffect, useState } from "react";
import BannerComponent from "../../component/BannerComponent";
import { TestimonialCard } from "../../component/Home/Testimonial/TestimonialCard";
import { Link } from  "react-router-dom"
import { callApi } from "../../ApiCalls";
import Spinner from "../../component/Loader/Spinner"

const Testimonials = () => {
  const [resData, setResData] = useState([]);
  useEffect(() => {
    async function getData() {
      const testimonialData = await callApi("testimonials")
      setResData(testimonialData)
   }
    getData();
  }, []);
  return (
    <>
      {
        resData.data ? (
          <div className="testimonials-main">
            <BannerComponent bannerData={resData.data.pageData} />
            <div className="icour-math">
              <strong>We're grateful for all of the positive feedback we've received from parents, teachers, school staff, and principals who use <Link>iSoar Math</Link>.</strong>
              <strong>We'd love to hear from you. Share your <Link>iSoar Math</Link> experiences with us!</strong>
            </div>
            {
              resData.data.testimonials.data.length > 0 && (
                <div className="testimonials-wraper">
                  <div className="container">
                    <div className="main-title">
                      <h2>Testimonials</h2>
                    </div>
                    <div className="testimonials-repeat">
                      {
                        resData.data.testimonials.data.map((elem, index) => (
                          <React.Fragment key={index}>
                            <TestimonialCard testimonialData={elem} pageName="testimonial"/>
                          </React.Fragment>
                        ))
                      }
                    </div>
                  </div>
                </div>
              )
            }
          </div>
        ) : <Spinner/>
      }
    </>
  );
};

export default Testimonials;
