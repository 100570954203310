import React, { useEffect, useState ,useContext} from "react";
import {Tabs,Tab,Box,Typography} from "@mui/material";
import BannerComponent from "../../component/BannerComponent";
import { callApi } from "../../ApiCalls";
import { UserContext } from "../../App";
import AccountInfo from "../../component/MyAccount/AccountInfo";
import GeneralSetting from "../../component/MyAccount/GeneralSetting";
import EmailSetting from "../../component/MyAccount/EmailSetting";
import MyStetistics from "../../component/MyAccount/MyStetistics";
import AccountSetting from "../../component/MyAccount/AccountSetting";
import MyClass from "../../component/MyAccount/MyClass";
import Profile from "../../component/MyAccount/Profile";
import Spinner from "../../component/Loader/Spinner"

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const MyAccount = () => {

  const { state, dispatch } = useContext(UserContext);

  const [value, setValue] = useState(0);
  const [acDetails, setAcDetails] = useState([]);
  const [generalSetting, setGeneralSetting] = useState([])
  const [emailSetting, setEmailSetting] = useState([])
  const [classData,setClassData] = useState([])
  const [statistics,setStatistics] = useState([])
  
  const handleChange = (event, newValue) => {
    if(newValue != 5)
      getData();
    setValue(newValue);
  };

  const getClassData = async() => {
    const response = await callApi("get-class");
    setClassData(response.data)
  }
  const getAccountDetails = async(index) => {
    var userId = parseInt(localStorage.getItem("userId"));
    const rdata = await callApi("my-account/get-account-details");
    setAcDetails(rdata)
    a11yProps(index)
  }
  async function getData() {
    var userId = parseInt(localStorage.getItem("userId"));
    const accData = await callApi("my-account/get-account-details");
    setAcDetails(accData)
    setGeneralSetting(accData.generalSetting)
    setEmailSetting(accData.emailSetting)
    setStatistics(accData.myStatestics)
  }
  useEffect(() => {
    async function getData() {
      var userId = parseInt(localStorage.getItem("userId"));
      const accData = await callApi("my-account/get-account-details");
      setAcDetails(accData)
      setGeneralSetting(accData.generalSetting)
      setEmailSetting(accData.emailSetting)
      setStatistics(accData.myStatestics)
    }
    getData();
  }, []);
  return (
    <>
      {
        acDetails.data ? (
          <div className="account-main">
            <BannerComponent title="My account" />
            <div className="account-tab">
              <div className="container">
                <Box sx={{ borderBottom: 1, borderColor: "divider" }} className="tab-items">
                  <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Account Information" {...a11yProps(0)} />
                    <Tab label="Your Account Settings" {...a11yProps(1)} />
                    <Tab label="General Lesson Settings" {...a11yProps(2)} />
                    <Tab label="E-mail Settings" {...a11yProps(3)} />
                    <Tab label="My Statistics" {...a11yProps(4)} />
                    { state.type == 5 && <Tab label="My Class Rosters" {...a11yProps(5)} /> }
                    {/* { state == 5 &&  <Tab label="Profile" {...a11yProps(6)} /> } */}
                  </Tabs>
                </Box>
                <div className="tab-content">
                  <TabPanel value={value} index={0}>
                    <AccountInfo acDetails={acDetails.data}/>
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <AccountSetting acDetails={acDetails.data} getAccountDetails={getAccountDetails}/>
                  </TabPanel>
                  <TabPanel value={value} index={2}>
                    <GeneralSetting generalSetting={generalSetting}/>
                  </TabPanel>
                  <TabPanel value={value} index={3}>
                    <EmailSetting emailSetting={emailSetting}/>
                  </TabPanel>
                  <TabPanel value={value} index={4}>
                    <MyStetistics myStatestics={statistics}/>
                  </TabPanel>
                  <TabPanel value={value} index={5}>
                    <MyClass classData={classData}/>
                  </TabPanel>
                  {/* <TabPanel value={value} index={6}>
                    <Profile/>
                  </TabPanel> */}
                </div>
              </div>
            </div>
          </div>
        ) : <Spinner/>
      }
    </>
  );
};

export default MyAccount;