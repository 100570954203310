import React,{ useState,useContext } from 'react'
import Form from "react-bootstrap/Form";
import Input from '../Input'
import Button from "react-bootstrap/Button";
import { callApi } from '../../ApiCalls';
import * as Yup from "yup";
import { useFormik } from "formik";
import Signin from "../../../src/assets/images/sign-in.png";
import { Link,useNavigate } from 'react-router-dom';
import { UserContext } from "../../App";
import { InputGroup } from 'react-bootstrap';
import { IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const SignIn = (props) => {

  const { state, dispatch } = useContext(UserContext);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const [isStudent, setIsStudent] = useState(false);
  const [isDisable, setIsDisable] = useState(false);

  const userSchema1 = Yup.object({
    user_role: Yup.string().required("User role is required"),
    class_code: Yup.string().max(10,"Class name must be maximum 10 digits").required("Class code is required"),
    user_name: Yup.string().required("User name is required").max(20,"User name must be at least 20 characters").matches(/^[A-Za-z0-9 ]+$/, "Only Alphabets, Number and Space are allowed for this field"),
    password: Yup.string().min(8,"Password must be at least 8 characters").max(20,"Password must be at most 20 characters").required("Password is required"),
  });
  const userSchema2 = Yup.object({
      user_role: Yup.string().required("User role is required"),
      email: Yup.string().email("Email must be a valid email").required("Email is required"),
      password: Yup.string().min(8,"Password must be at least 8 characters").max(20,"Password must be at most 20 characters").required("Password is required"),
  });
  
  const initialValues = {
    user_role : "",
    class_code : "",
    user_name : "",
    email : "",
    password : ""
  };
  
  const { values, touched, errors, handleChange, handleSubmit, setFieldValue } = useFormik({
      initialValues: initialValues,
      validationSchema: isStudent ? userSchema1 : userSchema2,
      onSubmit: async (values, { resetForm }) => {
        setIsDisable(true)
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        values['timezone'] = timezone
        var response = await callApi("login", values);
        if (response.statusCode == 200) {
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("userId", response.data.users.userId);
          localStorage.setItem("userRole", response.data.users.userRole);
          localStorage.setItem("users", JSON.stringify(response.data.users));
          localStorage.setItem("isPaid", response.data.users.planDetails.planType);
          
          if(response.data.users.userRole == 5 || response.data.users.userRole == 3) {
            localStorage.setItem("isExpired",response.data.users.planDetails.isExpired);
            
            if(response.data.users.planDetails.isExpired == 1) {
              dispatch({type : "CHECK_SUBSCRIPTION",payload : 1})
              navigate("/account-expire");
              resetForm();
              props.handleClose();
              return 0;
            } else {
              dispatch({type : "CHECK_SUBSCRIPTION",payload : 0})
            }
          }
          navigate("/");
          dispatch({type: "IS_PAID",payload: response.data.users.planDetails.planType})
          dispatch({type: "USER",payload: response.data.users.userRole});
          dispatch({type: "USER_ID",payload: response.data.users.userId});
          resetForm();
          props.handleClose();
        } else {
          resetForm();
          setIsDisable(false)
        }
      },
    });

    const typeSelector = (e) => {
        const { value } = e.target;
        setFieldValue("class_code","")
        setFieldValue("user_name","")
        setFieldValue("email","")
        setFieldValue("password","")
        if (value === "6") 
            setIsStudent(true);
        else 
            setIsStudent(false);
    };

  return (
    <div className={`sign-in-form-wraper ${isDisable?"modal-disable":""}`}>
      <div className="sign-in-image">
        <img src={Signin} />
      </div>
      <div className="common-form">
        <Form autoFocus={false} autoComplete="false" onSubmit={handleSubmit}>
          <Form.Group className="form-group full-fuied" controlId="formBasicstudent" >
            <Form.Label>I am a<span>*</span></Form.Label>
            <Form.Select
              name="user_role"
              onChange={(e) => {
                handleChange(e);
                typeSelector(e);
              }}
              value={values.user_role}
            >
              <option value="">Select Type</option>
              <option value="6">Student</option>
              <option value="5">Teacher</option>
              <option value="3">Family member</option>
            </Form.Select>
            {errors.user_role && touched.user_role && (
              <Form.Text className="text-danger">{errors.user_role}</Form.Text>
            )}
          </Form.Group>

          <Form.Group className={`form-group full-fuied ${!isStudent ? "d-none" : "" }`} controlId="formBasicCode">
            <Form.Label>Class Code<span>*</span></Form.Label>
            <Input
              type="text"
              name="class_code"
              value={values.class_code}
              onChange={(e)=>setFieldValue("class_code",e.target.value)}
              placeholder="Enter Your Class Code"
              // autoComplete="false"
            />
            {errors.class_code && touched.class_code && (
              <Form.Text className="text-muted">
                {errors.class_code}
              </Form.Text>
            )}
          </Form.Group>

          <Form.Group className="form-group half-fuied-main" controlId="formBasicEmail">
            <div className={`half-fuied ${!isStudent ? "d-none" : ""}`}>
              <Form.Label>User Name<span>*</span></Form.Label>
              <Input
                type="text"
                name="user_name"
                placeholder="Enter Your User Name"
                // onChange={handleChange}
                onChange={(e)=>setFieldValue("user_name",e.target.value)}
                value={values.user_name}
                // autoComplete="off"
              />
              {errors.user_name && touched.user_name && (
                <Form.Text className="text-muted">
                  {errors.user_name}
                </Form.Text>
              )}
            </div>
            <div className={`half-fuied ${isStudent ? "d-none" : ""}`}>
              <Form.Label>Email<span>*</span></Form.Label>
              <Input
                type="email"
                name="email"
                placeholder="Enter Your Email"
                onChange={(e)=>setFieldValue("email",e.target.value)}
                value={values.email}
                // autoComplete="off"
              />
              {errors.email && touched.email && (
                <Form.Text className="text-muted">
                  {errors.email}
                </Form.Text>
              )}
            </div>
            <div className="half-fuied input-password">
              <Form.Label>Password<span>*</span></Form.Label>
              <Input
                // type="password"
                type={showPassword ? 'text' : 'password'}
                name="password"
                placeholder="Enter Your Password"
                onChange={(e)=>setFieldValue("password",e.target.value)}
                value={values.password}
              />
              <InputGroup.Text id="basic-addon2">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
              </InputGroup.Text>
              {errors.password && touched.password && (
                <Form.Text className="text-muted">
                  {errors.password}
                </Form.Text>
              )}
            </div>
            <div className="reset-pass">
              <Link to="/forgot-password" onClick={props.handleClose}>
                Forgot Password
              </Link>
            </div>
          </Form.Group>

          <Button variant="primary" type="submit" className="yellow-button" >
            Login
          </Button>
          <p>
            Not a member yet?{" "}
            <Link to="/signup" onClick={props.handleClose}>
              Sign Up!
            </Link>{" "}
          </p>
          <em>
            Sign up for a free 15 day trial. No credit card necessary.
          </em>
        </Form>
      </div>
    </div>
  )
}

export default SignIn