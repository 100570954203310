import React, { useState, useRef, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Input from "../Input";
import Button from "react-bootstrap/Button";
import { callApi } from "../../ApiCalls";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import Edit from "../../assets/images/edit.png";
import Add from "../../assets/images/add.png";
import Avtar from "../../assets/images/avtar.png";
import TooltipComponent from "../TooltipComponent";

const AddEditChildren = (props) => {
  const gradeData = props?.gradeData;
  const isUpdate = props?.isUpdate;
  const userData = props?.userData;
  const fileRef = useRef(null);
  const [file, setFile] = useState();
  const [isDisable, setIsDisable] = useState(false);

  const FILE_SIZE = 160 * 1024;
  const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/png",
    "image/webp"
  ];
  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const imageChange = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertToBase64(file);
    setFile(base64);
  };

  const addChildren = Yup.object().shape({
    avtar: Yup.mixed()
      .required('Avatar is required Field')
      .test('type', 'File is not supported', (value) => {
        return value && SUPPORTED_FORMATS.includes(value.type);
      })
      .test('Fichier taille',
        'Please upload a file smaller than 800 KB', (value) => !value || (value && value.size <= 8e+5)),
    first_name: Yup.string().required("Full name is required").max(40,"Full name must be at least 40 characters").matches(/^[A-Za-z0-9 ]+$/, "Only Alphabets, Number and Space are allowed for this field"),
    grade: Yup.string().required("Grade is required"),
    ability: Yup.string().required("Math abilty is required"),
  });
  const editChildren = Yup.object().shape({
    avtar: Yup.mixed()
      .required('Avatar is required Field')
      .test('type', 'File is not supported', (value) => {
        return value && SUPPORTED_FORMATS.includes(value.type);
      })
      .test('Fichier taille',
        'Please upload a file smaller than 800 KB', (value) => !value || (value && value.size <= 8e+5)),
    first_name: Yup.string().required("Full name is required").max(40,"Full name must be at least 40 characters").matches(/^[A-Za-z0-9 ]+$/, "Only Alphabets, Number and Space are allowed for this field"),
    grade: Yup.string().required("Grade is required"),
    ability: Yup.string().required("Math abilty is required"),
  });
  const nofileChabgeEdit = Yup.object().shape({
    avtar: Yup.mixed().required('Avatar is required Field'),
    first_name: Yup.string().required("Full name is required").max(40,"Full name must be at least 40 characters").matches(/^[A-Za-z0-9 ]+$/, "Only Alphabets, Number and Space are allowed for this field"),
    grade: Yup.string().required("Grade is required"),
    ability: Yup.string().required("Math abilty is required"),
  });
  const [formValues,setFormvalues] = useState({});
  useEffect(()=>{
    const initialValues = {
      avtar: isUpdate ? userData.avtar : null,
      first_name: isUpdate ? userData.firstName : "",
      grade: isUpdate ? userData.grade : "",
      ability: isUpdate ? userData.ability : "",
    };
    setFormvalues(initialValues);
  },[props.userData]);  
  const { values, touched, errors,setFieldValue, handleChange, handleSubmit } = useFormik({
    initialValues: formValues,
    validationSchema: isUpdate ? (file ? editChildren : nofileChabgeEdit) : addChildren,
    enableReinitialize:true,
    onSubmit: async (values, { resetForm }) => {
      setIsDisable(true)
      const userID = isUpdate
        ? parseInt(userData.childId)
        : parseInt(localStorage.getItem("userId"));
      var data = {
        avtarFile: file ? file : userData.avtarFile,
        first_name: values.first_name,
        grade_level_id: parseInt(values.grade),
        ability_level_id: parseInt(values.ability),
      };
      if (isUpdate) {
        var response = await callApi(`update-child/${userID}`, data);
        if (response.statusCode == 200) {
          resetForm();
          props?.addData(data);
          props?.handleClose();
        }else {
          setIsDisable(false)
        }
      } else {
        var response = await callApi("add-child", data);
        if (response.statusCode == 200) {
          resetForm();
          props?.addData(data);
          props?.handleClose();
        } else {
          setIsDisable(false)
        }
      }
    },
  });
  return (
    <div className={`account-details footer-position ${isDisable?"modal-disable":""}`}>
      <Form autoComplete="false" onSubmit={handleSubmit} className="row">
        <Form.Group
          className="form-group full-fuied col-md-12 choose-image"
          controlId="formBasicstudent"
        >
          <div className="choose-icon">
            <div className="choose-icon-wraper">
              <img
                src={file ? file : values.avtar == null ? Avtar : values.avtar}
              />
              <input
                ref={fileRef}
                hidden
                name="avtar"
                type="file"
                accept=".jpeg, .png, .jpg"
                onChange={(e) => {
                  handleChange(e);
                  imageChange(e);
                  setFieldValue("avtar",e.target.files[0])
                }}
              />
              <Link
                onClick={() => {
                  fileRef.current.click();
                }}
              >
                <img src={isUpdate ? Edit : Add} />
              </Link>
            </div>
            <Form.Label className="w-100">
              Avatar<span>*</span>
            </Form.Label>
            {errors.avtar && touched.avtar && (
              <Form.Text className="text-danger">{errors.avtar}</Form.Text>
            )}
          </div>
        </Form.Group>
        <Form.Group
          className="form-group full-fuied col-md-4"
          controlId="formBasicstudent"
        >
          <div className="half-fuied">
            <Form.Label>
              Full Name<span>*</span>
            </Form.Label>
            <Input
              type="text"
              name="first_name"
              placeholder="Enter Full Name"
              value={values.first_name}
              onChange={handleChange}
              autoComplete="false"
            />
            {/* <TooltipComponent title="Lorem Ipsum is simply dummy text of the printing and typesetting industry." type="text" /> */}
            {errors.first_name && touched.first_name && (
              <Form.Text className="text-danger">{errors.first_name}</Form.Text>
            )}
          </div>
        </Form.Group>
        <Form.Group
          className="form-group full-fuied col-md-4"
          controlId="formBasicstudent"
        >
          <div className="half-fuied">
            <Form.Label>
              Grade Level<span>*</span>
            </Form.Label>
            <Form.Select
              name="grade"
              value={values.grade}
              onChange={handleChange}
            >
              <option value="">Select Grade Level</option>
              {gradeData.map((elem, index) => (
                <option value={elem.gradeId} key={index}>
                  {elem.gradeName}
                </option>
              ))}
            </Form.Select>
            {/* <TooltipComponent title="Lorem Ipsum is simply dummy text of the printing and typesetting industry." type="text" /> */}
            {errors.grade && touched.grade && (
              <Form.Text className="text-danger">{errors.grade}</Form.Text>
            )}
          </div>
        </Form.Group>
        <Form.Group
          className="form-group full-fuied col-md-4"
          controlId="formBasicstudent"
        >
          <div className="half-fuied">
            <Form.Label>
              Math Ability Level<span>*</span>
            </Form.Label>
            <Form.Select
              name="ability"
              onChange={handleChange}
              value={values.ability}
            >
              <option value="">Select Math Ability Level</option>
              {gradeData.map((elem, index) => (
                <option value={elem.gradeId} key={index}>
                  {elem.gradeName}
                </option>
              ))}
            </Form.Select>
            {/* <TooltipComponent title="Lorem Ipsum is simply dummy text of the printing and typesetting industry." type="text" /> */}
            {errors.ability && touched.ability && (
              <Form.Text className="text-danger">{errors.ability}</Form.Text>
            )}
          </div>
        </Form.Group>
        <div className="common-pop-footer">
          <Button
            variant="primary"
            type="submit"
            className="yellow-button"
            onClick={() => props?.handleClose()}
          >
            Cancel
          </Button>
          <Button variant="primary" type="submit" className="yellow-button">
            Submit
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AddEditChildren;
