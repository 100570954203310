import React,{useContext} from 'react'
import { Link } from 'react-router-dom'
import {UserContext} from "../../App"

const Assignment = (props) => {
    const { state, dispatch } = useContext(UserContext);
  return (
    <>
        <ul>
            <li>
                <Link to="/overview-assignment" onClick={props.handleClose} className="yellow-button">
                    Overview
                </Link>
            </li>
            <li>
                <Link to="/individual-assignment" onClick={props.handleClose} className="yellow-button">
                    Individual {state.type==3?"Children":"Student"}
                </Link>
            </li>
        </ul>
    </>
  )
}

export default Assignment