import React , {useContext} from 'react'
import { Navigate } from 'react-router-dom';
import { UserContext } from '../../App';

const RequiredAuth = ({children}) => {

    const { state, dispatch } = useContext(UserContext);

    if(!state.type){
        return <Navigate to="/" />
    }

    return (
        <>{children}</>
    )
}

export default RequiredAuth