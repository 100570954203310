import React from "react";
import {Link} from 'react-router-dom'
const AboutComponent = (props) => {
  const title =
    props.contentData.sectionContent.title.charAt(0).toUpperCase() +
    props.contentData.sectionContent.title.slice(1);

    const {subTitle} = props.contentData.sectionContent
    const {content} = props.contentData.sectionContent
    const {image} = props.contentData.sectionContent
    const {btnText} = props.contentData.sectionContent
    const {imagePosition} = props.contentData.sectionContent

  return (
    <div>
      <section className="common-section commons-section">
        <div className="container">
          <div className="main-title">
            <h2>{title}</h2>
          </div>
          <div className={`common-section-wrap ${imagePosition == "right" ? "image-right" : "image-left"}`}>
            <div className="common-section-content">
              { subTitle && <h4>{subTitle}</h4> }
              <span>{content}</span>
              {btnText && <Link to="/about" className="yellow-button">{btnText}</Link>}
            </div>
            <div className="common-section-image">
              <img src={image} />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutComponent;
