import React from "react"
import { Link } from "react-router-dom"
import infoicon from "../assets/images/i.png";

const MembershipInfo = (props) => {

  const planData = props?.planData
  const color = ["purple","pink","yellow"]
  var index = -1
  return(
    <>
      <ul className="per-month">
        {
          planData.map((elem,id)=>{
            if(id % 3 == 0)
              index = 0
            else 
              index = index + 1
            return (
              <li key={id}>
                <div className="per-month-card">
                  <div className="per-month-wrap">
                    <div className={`per-month-tickets ${color[index]}`}>
                      {
                        elem?.membershipPlanDetails.map((elem,index)=>(
                          <React.Fragment key={index}>
                            {
                              elem.planMonth == "1" && (
                                <span>Per Month<strong>$ {elem.planPrice ? elem.planPrice : "00.00"}</strong></span>
                              )
                            }
                            {/* {
                              elem.planMonth == "3" && (
                                <span>3 Month<strong>$ {elem.planPrice ? elem.planPrice : "00.00"}</strong></span>
                              )
                            }
                            {
                              elem.planMonth == "6" && (
                                <span>6 Month<strong>$ {elem.planPrice ? elem.planPrice : "00.00"}</strong></span>
                              )
                            }
                            {
                              elem.planMonth == "9" && (
                                <span>9 Month<strong>$ {elem.planPrice ? elem.planPrice : "00.00"}</strong></span>
                              )
                            }
                            {
                              elem.planMonth == "12" && (
                                <span>12 Month<strong>$ {elem.planPrice ? elem.planPrice : "00.00"}</strong></span>
                              )
                            } */}
                            {
                              elem.planDays != null && (
                                <span>Free Plan<strong>{elem.planDays} days</strong></span>
                              )
                            }
                          </React.Fragment>
                        ))
                      }
                    </div>
                    <div className="per-month-details">
                      <strong>{elem.planTitle}</strong>
                      <p>{elem.planDescription}</p>
                    </div>
                  </div>
                </div>
                <ul>
                  {
                    elem?.planFeature.map((elem,index)=>(
                      <li key={index}>{elem.featureTitle}
                        <img className="info" src={infoicon} title={elem.featureHint == "" || null ? "" : elem.featureHint} />
                      </li>
                    ))
                  }
                  <li className="per-month-button"><Link to={elem.userRole == 5 ? "/teacher-signup" : "/family-signup"}  className="yellow-button">Sign Up</Link></li>
                </ul>
              </li>
            )
          })
        }
      </ul>
    </>
  )
} 

export default MembershipInfo;