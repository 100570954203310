import React, { useState } from 'react'
import { ModalComponent } from '../../ModalComponent';

export const TestimonialCard = (props) => {
  const testimoData = props?.testimonialData
  const pageName = props?.pageName

  const [show,setShow] = useState(false);
  const [infoData,setInfoData] = useState([]);

  const handleClose = () => setShow(false);

  const showInfo = (data) =>{
    setShow(true)
    setInfoData(data)
  }
  return (
    <>
      <div className="testimonial-slider-repeat" onClick={()=>pageName && showInfo(testimoData)}>
          <div className="testimonial-wrap">
              <div className="avtar-details">
                  <img src={testimoData.profilePhoto} />
                  <div className="avtar-name">
                      <h5>{testimoData.clientName}</h5>
                      <span>{testimoData.subText}</span>
                  </div>
              </div>
              <p>{testimoData.subDescription}</p>
          </div>
      </div>
      {
        show && <ModalComponent show={show} handleClose={handleClose} type={"info"} infoData={infoData} className="testimonils-pop"/>
      }
    </>
  )
}
