import React, { useEffect, useState } from "react";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import TemplateFooter from "../../TemplateFooter";
import { Button } from "react-bootstrap";
import StudentImg from "../../../assets/images/student.svg";
import CheckImg from "../../../assets/images/check.svg";
import { useContext } from "react";
import { UserContext } from "../../../App";
import SoundIcon from "../../../assets/images/speaker-icon.svg";
import Tooltip from '@mui/material/Tooltip';

const MultiQuestionImageSoundTrueFalse = (props) => {
  const question = props?.question;

  const { state, dispatch } = useContext(UserContext);
  const [isAnswerWrong, setIsAnswerWrong] = useState(false);
  const [isHideFooter, setIsHideFooter] = useState(false);
  const [usersAnswer, setUserAnswer] = useState("");
  const [correctAnswer, setCorrectAnswer] = useState();
  const [answer, setAnswer] = useState([]);
  const [defaultValue, setDefaultValue] = useState(0);
  const [checkNull,setCheckNull] = useState([])
  const [inputData,setInputData] = useState([])

  const correctAns = question?.questionData?.correct_answer=="false" ?
      question?.questionData?.sub_question_image_and_sound.filter((elem) => {
        return elem
      })
    :
      question?.questionData?.sub_question_image_and_sound.filter((elem) => {
        if (question?.questionData?.correct_answer.indexOf(elem.index) !== -1) {
          return elem;
        }
      }
  );

  const play = (sound) =>{
    var audio = new Audio(sound);
    audio.play();
    }
  const handleChange = (e) => {

    setInputData((preVal) => {
      return {
        ...preVal,
        [e.target.name]: e.target.value,
      };
    });

    if (e.target.checked) {
      if (answer.includes(e.target.name)) {
        answer.splice(answer.indexOf(e.target.name), 1);
        return;
      }
      setCheckNull([...checkNull, e.target.name])
      if(e.target.value != 0)
        setAnswer([...answer, e.target.name]);
    } else {
      const index = answer.indexOf(e.target.name);
      answer.splice(index, 1);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    var userData = Object.values(inputData)
    var ansLength = question?.questionData?.sub_question_image_and_sound.length
    const correctAnswer = question?.questionData?.correct_answer;

    if (userData.length != ansLength) {
      toast.warning("Please, select answer");
      return 0;
    }
    if (JSON.stringify(answer.sort()) === JSON.stringify(correctAnswer) || answer.length == 0) {
      props?.handleSubmit(true, userData);
    } else {
      setIsAnswerWrong(true);
      setIsHideFooter(true);
      setUserAnswer(Object.values(inputData).toString());
    }
    document.getElementById("inputForm").reset();
    setAnswer([]);
  };
  const nextQuestion = () => {
    setIsAnswerWrong(false);
    setIsHideFooter(false);
    props?.handleSubmit(false, usersAnswer);
    setUserAnswer("");
  };

  useEffect(() => {
    setCorrectAnswer(correctAns);
    setIsHideFooter(props?.isHideFooter);
    setUserAnswer(props?.usersAnswer);
    setDefaultValue(0);
  }, [question]);

  return (
    <div className="multi-question-image-answer-text-true-false multi-question-image-sound-true-false">
      <form onSubmit={handleSubmit} id="inputForm">
        <div>
          <ul>
            {
              question?.questionData?.sub_question_image_and_sound.map((elem, index) => (
                <React.Fragment key={`${question?.id}_${index}`}>
                  <li>
                    <div className="head-name">
                        <Link onClick={() => play(elem.sound)} alt="Question Audio">
                          <Tooltip title="Play Audio" placement="top"><img src={SoundIcon} /></Tooltip>
                        </Link>
                    </div>
                    {elem?.images && (
                      <img src={elem.images.replace('original', 'thumb')} alt="Question Image" width="50px" height="auto"/>
                    )}
                    <RadioGroup
                      className="custom-radio"
                      aria-labelledby="demo-radio-buttons-group-label"
                      // defaultValue={defaultValue}
                      onChange={handleChange}
                      name={`${elem.index}`}
                    >
                      <FormControlLabel value={1} control={<Radio />} label="True" />
                      <FormControlLabel value={0} control={<Radio />} label="False" />
                    </RadioGroup>
                  </li>
                </React.Fragment>
              )
            )}
          </ul>
        </div>

        {!isHideFooter && (
          <div className="questions-footer">
            <TemplateFooter question={question} />
            <div className="question-btn">
              <Button className="yellow-button" type="submit">
                Submit
              </Button>
            </div>
          </div>
        )}
        {usersAnswer && (
          <div className='answer-info'>
            <div className='box'>
              <span className='student'>{state.type == 3 || state.type == 4 ? "Children" : "Student"} Answer <img src={StudentImg} className="img-fluid"/></span>
              <div className='img-sec'>
                {question?.questionData?.sub_question_image_and_sound.map((elem, index) => (
                  <React.Fragment key={index}>
                    <div className="img-content">
                      <img src={elem.images} />
                      {
                        Object.values(inputData)[index] == 1 ? <p className="true">True</p> : <p className="false">False</p>
                      }
                    </div>
                  </React.Fragment>
                ))}
              </div>
            </div>
            <div className='box'>
              <span className='correct'>Correct Answer <img src={CheckImg} className="img-fluid"/></span>
              <div className='img-sec'>
                {correctAnswer &&
                  correctAnswer.map((elem, index) => (
                    <React.Fragment key={index}>
                      <div className="img-content">
                        <img src={elem.images} key={index} />
                        {
                          question?.questionData?.correct_answer=="false" ? <p className="false">False</p> : <p className="true">True</p>
                        }
                      </div>
                    </React.Fragment>
                  ))
                }
              </div>
            </div>
          </div>
        )}
        <div className='explanation-info'>
          {
            usersAnswer && isAnswerWrong &&
              <div className='details'>
                <span>Explanation</span>
                  {
                    question?.hintType == 0 ? 
                      <p>{question.questionData.answer_hint}</p>
                    :
                      <img src={question.questionData.answer_hint} className="img-fluid question-img" />
                  }
              </div>
          }  
          {
            isAnswerWrong && 
              <div className='question-btn'>
                <Link className="yellow-button" onClick={nextQuestion}>
                  Next
                </Link>
              </div>
          } 
        </div>
      </form>
    </div>
  );
};

export default MultiQuestionImageSoundTrueFalse;
