import React, { useRef, useState, useContext } from "react";
import Form from "react-bootstrap/Form";
import Input from "../Input";
import Button from "react-bootstrap/Button";
import { callApi } from "../../ApiCalls";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import Edit from "../../assets/images/edit.png";
import TooltipComponent from "../TooltipComponent";
import { InputGroup } from "react-bootstrap";
import { IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { UserContext } from "../../App";

const EditAccountDetail = (props) => {

  const { state, dispatch } = useContext(UserContext);

  const detailType = props?.detailType;
  const label = props?.label;
  const oldValue = props?.oldValue;
  const [isDisable, setIsDisable] = useState(false);
  const fileRef = useRef(null);
  const [file, setFile] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const [showNewPassword, setShowNewPassword] = useState(false);
  const handleClickshowNewPassword = () => setShowNewPassword((show) => !show);
  const handleMouseDownNewPassword = (event) => {
    event.preventDefault();
  };
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleClickshowConfirmPassword = () => setShowConfirmPassword((show) => !show);
  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };
  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const imageChange = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertToBase64(file);
    setFile(base64);
  };

  const first_name = Yup.object({
    first_name: Yup.string().required("First name is required").max(40,"First name must be at least 40 characters").matches(/^[A-Za-z0-9 ]+$/, "Only Alphabets, Number and Space are allowed for this field"),
  });
  const last_name = Yup.object({
    last_name: Yup.string().required("Last name is required").max(40,"Last name must be at least 40 characters").matches(/^[A-Za-z0-9 ]+$/, "Only Alphabets, Number and Space are allowed for this field"),
  });
  const class_code = Yup.object({
    class_code: Yup.string().max(10,"Class name must be maximum 10 digits").required("Class Code is required"),
  });
  const email = Yup.object({
    email: Yup.string().email("Email must be a valid email").required("Email is required"),
  });
  const avtar = Yup.object({
    avtar: Yup.mixed().required("Avatar is required"),
  });

  const password = Yup.object({
    password: Yup.string().min(8,"Password must be at least 8 characters").max(20,"Password must be at most 20 characters").required("Password is required"),
    newPassword: Yup
    .string()
    .min(8,"New Password must be at least 8 characters")
    .max(20,"New Password must be at most 20 characters")
    .required('New Password is required')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])/,
      "One Uppercase, One Lowercase, One Number and One Special Character"
    ),
    confNewPassword: Yup.string()
    .min(8,"Confirm New Password must be at least 8 characters")
    .max(20,"Confirm New Password must be at most 20 characters")
      .required("Confirm New Password is required")
      .oneOf(
        [Yup.ref("newPassword"), null],
        "New Password and confirm password must match"
      ),
  });

  const initialValues = {
    first_name: detailType == "first_name" ? oldValue : "",
    last_name: detailType == "last_name" ? oldValue : "",
    class_code: detailType == "class_code" ? oldValue : "",
    avtar: detailType == "avtar" ? oldValue : "",
    password: "",
    newPassword: "",
    confNewPassword: "",
  };
  const { values, touched, errors, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema:
      detailType == "first_name"
        ? first_name
        : detailType == "last_name"
        ? last_name
        : detailType == "class_code"
        ? class_code
        : detailType == "avtar"
        ? avtar
        : password,
    onSubmit: async (values, { resetForm }) => {
      setIsDisable(true)
      const userID = parseInt(localStorage.getItem("userId"));
      if (detailType == "password") {
        var data = {
          current_password: values.password,
          new_password: values.newPassword,
          confirm_password: values.confNewPassword,
          userID: userID,
        };
        var response = await callApi("change-password", data);
        if (response.statusCode == 200) {
          resetForm();
          props?.handleClose();
        }else {
          setIsDisable(false)
        }
      } else {
        var data = {};
        if (detailType == "first_name") {
          data.first_name = values.first_name;
        } else if (detailType == "last_name") {
          data.last_name = values.last_name;
        } else if (detailType == "class_code") {
          data.class_code = values.class_code;
        } 
        else {
          data.avtar = file ? file : values.avtar;
          data.avtarPrevImg = oldValue;
        }
        var response = await callApi("my-account/my-account-update", data);
        if (response.statusCode == 200) {
          if (detailType == "first_name") {
            dispatch({type : "SET_AVATAR_IMAGE",payload : (Math.random() + 1).toString(36).substring(7)})
          }
          props?.getAccountDetails(1);
          resetForm();
          props.handleClose();
        } else {
          setIsDisable(false)
        }
      }
    },
  });
  return (
    <div className={`${isDisable?"modal-disable":""}`}>
    <Form autoComplete="false" autoFocus={false} onSubmit={handleSubmit}>
      {detailType == "avtar" && (
        <Form.Group className="form-group full-fuied" controlId="formBasicCode">
          <Form.Label>
            {label}
            <span>*</span>
          </Form.Label>
          <div className="choose-icon-wraper">
            <img src={file ? file : values[detailType]} />
            <input
              ref={fileRef}
              hidden
              type="file"
              accept=".jpeg, .png, .jpg"
              onChange={(e) => {
                handleChange(e);
                imageChange(e);
              }}
            />
            <Link
              onClick={() => {
                fileRef.current.click();
              }}
            >
              <img src={Edit} />
            </Link>
          </div>
          {errors[detailType] && touched[detailType] && (
            <Form.Text className="text-danger">{errors[detailType]}</Form.Text>
          )}
        </Form.Group>
      )}
      {detailType != "avtar" && (
        <Form.Group className="form-group full-fuied" controlId="formBasicCode">
          <div className="half-fuied input-password">
            <Form.Label>
              {label}
              <span>*</span>
            </Form.Label>
            <Input
              type={
                detailType == "email"
                  ? "email"
                  : detailType == "password"
                  ? showPassword ? 'text' : 'password'
                  : detailType == "avtar"
                  ? "file"
                  : "text"
              }
              onChange={handleChange}
              name={detailType}
              placeholder={"Enter " + label}
              value={values[detailType]}
            />
            {
              detailType == "password" && 
                <InputGroup.Text id="basic-addon2">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputGroup.Text>
            }
            {errors[detailType] && touched[detailType] && (
              <Form.Text className="text-danger">
                {errors[detailType]}
              </Form.Text>
            )}
          </div>
        </Form.Group>
      )}
      {detailType == "password" && (
        <>
          <Form.Group
            className="form-group full-fuied"
            controlId="formBasicCode"
          >
            <div className="half-fuied">
              <Form.Label>
                New {label}
                <span>*</span>
              </Form.Label>
              <Input
                // type="password"
                type={showNewPassword ? 'text' : 'password'}
                onChange={handleChange}
                name="newPassword"
                placeholder={"Enter New " + label}
                value={values.detailType}
              />
              <InputGroup.Text id="basic-addon2">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickshowNewPassword}
                    onMouseDown={handleMouseDownNewPassword}
                    edge="end"
                  >
                    {showNewPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
            </InputGroup.Text>
              {/* <TooltipComponent title="Lorem Ipsum is simply dummy text of the printing and typesetting industry." type="text" /> */}
              {errors.newPassword && touched.newPassword && (
                <Form.Text className="text-danger">
                  {errors.newPassword}
                </Form.Text>
              )}
            </div>
          </Form.Group>
          <Form.Group
            className="form-group full-fuied"
            controlId="formBasicCode"
          >
            <div className="half-fuied">
              <Form.Label>
                Confirm New {label}
                <span>*</span>
              </Form.Label>
              <Input
                // type="password"
                type={showConfirmPassword ? 'text' : 'password'}
                onChange={handleChange}
                name="confNewPassword"
                placeholder={"Enter Confirm New " + label}
                value={values.detailType}
              />
              <InputGroup.Text id="basic-addon2">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickshowConfirmPassword}
                      onMouseDown={handleMouseDownConfirmPassword}
                      edge="end"
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
              </InputGroup.Text>
              {/* <TooltipComponent title="Lorem Ipsum is simply dummy text of the printing and typesetting industry." type="text" /> */}
              {errors.confNewPassword && touched.confNewPassword && (
                <Form.Text className="text-danger">
                  {errors.confNewPassword}
                </Form.Text>
              )}
            </div>
          </Form.Group>
        </>
      )}

      <Button variant="primary" type="submit" className="yellow-button">
        Save Changes
      </Button>
    </Form>
    </div>
  );
};

export default EditAccountDetail;
