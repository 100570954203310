import React,{useEffect,useState} from "react"
import { callApi, dateConverter } from "../../ApiCalls";
import BannerComponent from "../../component/BannerComponent";
import Pagination from "../../component/Blog/Pagination";
import Spinner from "../../component/Loader/Spinner";
import { useNavigate } from "react-router";
import Table from "../../component/Table/Table"

const OverviewAssignment = () => {

    const [assignmentData,setAssignmentData] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const navigate = useNavigate();

    const handlePageClick = async (page) => {
        setCurrentPage(page)
        var params = {
            page : page,
        }
        getData(params)
    }

    const getData = async(params) => {
        var assignmentData = await callApi("get-assignment-overview/0",null,params)
        setAssignmentData(assignmentData)
    }

    useEffect(() => {
        async function getData() {
          var assignmentData = await callApi("get-assignment-overview/0")
          setAssignmentData(assignmentData)
          setPageSize(assignmentData?.meta?.per_page)
          setPageCount(assignmentData?.meta?.total)
        }
        getData();
    }, []);

    var columns = [
        // {
        //     dataField: "lessonImage",
        //     text: "Lesson Icon",
        //     formatter : (cell,row) => {
        //         return <img className="lesson-icon" src={cell} />
        //     },
        // },
        {
            dataField: "lessonName",
            text: "Lesson Name",
            sort: true
        },
        {
            dataField: "assignTo",
            text: "Assigned To"
        },
        {
            dataField: "completedBy",
            text: "Completed By"
        },
        {
            dataField: "assignedDate",
            text: "Date Assigned",
            formatter : (cell,row) => {
                return dateConverter(cell)
            }
        },
        {
            dataField: "dueDate",
            text: "Due Date",
            formatter : (cell,row) => {
                return cell == 0 ? "Until Completed":dateConverter(cell)
            }
        },
        {
            dataField: "info",
            text: "More Info",
            formatter: (cell, row, rowIndex) => {
                return (
                    <div className="assign-detail" onClick={()=>navigate(`/assignment-detail/${row.id}`)}>Assignment Detail</div>
                );
            }
        }
    ];

    return(
        <>
            {
                assignmentData.data ? (
                    <>
                        <BannerComponent title="Overview Assignment"/>
                        <div className='container'>
                            <div className="child-wraper">
                                <strong>This page shows all assignments created by the parent.</strong>
                                <Table rowData={assignmentData.data} columns={columns} type="Assignment overview"/>
                                {
                                    assignmentData.data.length > 0 && 
                                        <Pagination
                                            currentPage={currentPage}
                                            totalCount={pageCount}
                                            pageSize={pageSize}
                                            onPageChange={(page) => {handlePageClick(page)}}
                                        />
                                }
                            </div>
                        </div>
                    </>
                ) : <Spinner/>
            }
        </>
    )
}

export default OverviewAssignment;