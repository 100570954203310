import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { dateConverter } from '../../ApiCalls';

const BadgeCard = (props) => {
    const {path,createdAt} = props?.data;
    return (
        <Col lg={3} md={6} xs={12} >
            <div className='badge-img'>
                <div className='img-box'>
                    <img src={path} alt="Badge Image" className='img-fluid'/>
                </div>
                <h4>{dateConverter(createdAt)}</h4>
            </div>
        </Col> 
    )
}

export default BadgeCard