import React from 'react'

const ViewCertificate = (props) => {
  const infoData = props?.infoData;
  console.log("infoData--",infoData)
  return (
    <>
      {infoData?.hintType == 0 ? infoData : <div className='text-center'><img src={infoData}/></div>}
    </>
  )
}

export default ViewCertificate;