import React from "react";
import Slider from "react-slick";
import { TestimonialCard } from "./TestimonialCard";

export const TestimonialSlider = (props) => {
  const testimonialData = props.Testimonial;
  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: testimonialData.length == 1 ? 1 : testimonialData.length == 2 ? 2 : 3,
    slidesToScroll: 1,
    swipeToSlide: false,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Slider {...settings}>
      {testimonialData.map((elem, index) => (
        <React.Fragment key={index}>
         {elem.status == 1 &&<TestimonialCard testimonialData={elem} />}
        </React.Fragment>
      ))}
    </Slider>
  );
};
