import React, { useState, useEffect } from "react";
import BannerComponent from "../../component/BannerComponent";
import { Link, useNavigate } from "react-router-dom";
import CommonTable from "../../component/CommonTable"
import axios from "axios";
import { ModalComponent } from "../../component/ModalComponent";
import { callApi } from "../../ApiCalls";
import Spinner from "../../component/Loader/Spinner"
import { Button } from "react-bootstrap";
import Progress from "../../assets/images/progress.png";
import Redcross from "../../assets/images/red-cross.png";
import BlueEdit from "../../assets/images/blue-edit.png";
import Table from "../../component/Table/Table";
import swal from "sweetalert";
import environment from '../../environment';
const MyChildren = () => {

  const [resData, setResData] = useState([]);
  const [show, setShow] = useState(false);
  const [childData, setChildData] = useState([])
  const [gradeData,setGradeData] = useState([])
  const [isUpdate, setIsUpdate] = useState(false);
  const [userData, setUserData] = useState([])
  const [title,setTitle] = useState()
  const [mdoalType,setModalType] = useState("")
  const [infoData,setInfoData] = useState("")

  const navigate = useNavigate();

  const handleClose = () => {
    setShow(false)
    setIsUpdate(false)
  }
  const handleShow = (data=null,type) => {
    setShow(true)
    if(type == "badge"){
      // setUserData(data)
      setInfoData(data)
      setModalType("badge")
      setTitle("Badge")
    } else if(type == "addChild") {
      setModalType("addChildren")
      setTitle("Add Child")
    } else if(type == "editChild"){
      setTitle("Edit Child")
      setModalType("addChildren")
      setIsUpdate(true)   
      setUserData(data)   
    }
  }
  useEffect(()=>{
    if(gradeData)
    {
      var grade = gradeData && gradeData.filter((elem)=>{
        return elem.gradeName == userData.gradeLevelId
      })
      var ability = gradeData && gradeData.filter((elem)=>{
        return elem.gradeName == userData.abilityLevelId
      })
      if(grade.length > 0)
        userData['grade'] = parseInt(grade[0].gradeId)
      if(ability.length > 0)
        userData['ability'] = parseInt(ability[0].gradeId)
      userData['avtarIcon'] = userData.avtar
      setUserData({...userData})
    }
  },[gradeData])
  // const childEdit = (data) => {
  //   setTitle("Edit Child")
  //   setModalType("addChildren")
  //   setShow(true)
  //   setIsUpdate(true)
  //   var grade = gradeData && gradeData.filter((elem)=>{
  //     return elem.gradeName == data.gradeLevelId
  //   })
  //   var ability = gradeData && gradeData.filter((elem)=>{
  //     return elem.gradeName == data.abilityLevelId
  //   })
  //   data['grade'] = parseInt(grade[0].gradeId)
  //   data['ability'] = parseInt(ability[0].gradeId)
  //   data['avtarIcon'] = data.avtar
  //   setUserData(data)
  // }

  const deleteRecord = async (childId) => {
    swal({
      title: "Are you sure, You want to delete ?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then(async(willDelete) => {
        if (willDelete) {
          var token = localStorage.getItem("token");
          const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          };
          await axios.delete(environment.REACT_APP_API_URL + `destroy-child/${childId}`, {
              headers: headers,
            }).then((res) => {
              if(res.data.statusCode == 200){
                var data = childData.filter((child)=>{
                  return child.childId !== childId
                })
                setChildData(data)
                getChild()
              }
            }).catch((err) => {
              console.log(err, "Error");
            });
            swal("Record has been deleted!", {
                icon: "success",
                timer: 2000,
            });
        } 
    });
    
  };

  const addData = (data) => {
    getChild()
  }
  const getChild = async() => {
    var userId = parseInt(localStorage.getItem("userId"));
    const response = await callApi(`my-children/${userId}`);
    setResData(response);
    setChildData(response.data.childData.data)
  }
  async function getData() {
    var userId = parseInt(localStorage.getItem("userId"));
    const response = await callApi(`my-children/${userId}`);
    setResData(response);
    setChildData(response.data.childData.data)
    const res = await callApi("get-only-grades");
    setGradeData(res.data);
  }
  useEffect(() => {    
      getData();
  }, []);
  useEffect(() => {    
    if(show)
    {
      getData();
    }
  }, [show]);

  // const column = ["Name","Grade Level","Math Ability","Progress","Edit","Remove","Badge"]
  var columns = [
    {
      dataField: "firstName",
      text: "Name",
      sort: true
    },
    {
      dataField: "gradeLevelId",
      text: "Grade Level",
    },
    {
      dataField: "abilityLevelId",
      text: "Math Ability",
    },
    {
      dataField: "progress",
      text: "Progress",
      formatter: (cell, row, rowIndex) => {
        return (
          <div className='action-btns' onClick={()=>navigate("/progress")}><img src={Progress}/></div>
        );
      }
    },
    {
      dataField: "edit",
      text: "Edit",
      formatter: (cell, row, rowIndex) => {
        return (
          <div className='action-btns' onClick={()=>handleShow(row,"editChild")}><img src={BlueEdit}/></div>
        );
      }
    },
    {
      dataField: "delete",
      text: "Delete",
      formatter: (cell, row, rowIndex) => {
        return (
          <div className='action-btns' onClick={()=>deleteRecord(row.childId)}><img src={Redcross}/></div>
        );
      }
    },
    {
      dataField: "view",
      text: "Badge",
      formatter : (cell,row) => {
        return (
          <>
            <div className="assign-detail" onClick={()=>handleShow(row.childId,"badge")}>View</div>
          </>
        );
      }
    }
  ];
  return (
    <>
      {
        resData.data ? (
          <>
            <BannerComponent bannerData={resData.data.pageData} />
            <div className="mychildren">
              <div className="container">
                {/* <CommonTable column={column} rowData={childData} type="child" editData={childEdit} deleteRecord={deleteRecord} searchChild={searchChild}/> */}
                <Table rowData={childData} columns={columns} type="Children" keyField="childId"/>

                <div className="d-flex justify-content-between align-item-center flex-wrap add-child-button">
                  
                  {!(resData.data.childData.data.length == resData.data.capicity || resData.data.capicity < resData.data.childData.data.length) ?    
                  <Button
                    variant="primary"
                    // disabled={(resData.data.childData.data.length == resData.data.capicity || resData.data.capicity < resData.data.childData.data.length) ? true : false}
                    type="submit"
                    className="yellow-button"
                    onClick={()=>handleShow(null,"addChild")}>Add Child
                  </Button>
                  :""}
                </div>
              </div>
            </div>
            {/* {
              addChild && show && 
              <ModalComponent show={show} userData={userData} isUpdate={isUpdate} handleClose={handleClose} addData={addData} type="addChildren" className="addChildren" title={title} gradeData={gradeData}/>
            } */}
            {
              show && (
                <>
                  <ModalComponent show={show} isUpdate={isUpdate} handleClose={handleClose} addData={addData} type={mdoalType} infoData={infoData} userData={{...userData}} className="certificate" title={title} gradeData={[...gradeData]}/>
                </>
            )
        }
          </>
        ) : <Spinner/>
      }
    </>
  );
};

export default MyChildren;
