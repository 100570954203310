import React, { useEffect, useState, useContext} from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import axios from "axios";
import BannerComponent from '../../component/BannerComponent';
import Spinner from '../../component/Loader/Spinner';
import { callApi, dateConverter } from '../../ApiCalls';
import {UserContext} from "../../App"
import Table from '../../component/Table/Table';
import { ModalComponent } from '../../component/ModalComponent';
import environment from '../../environment';
const Assignments = () => {

    const { state : stateData, dispatch } = useContext(UserContext);
    const navigatePage = useNavigate();
    const location = useLocation();

    const [assignmentData,setAssignmentData] = useState([])
    const [isLoder,setIsLoader] = useState(false)
    const navigate = window.location.pathname.split('/')[1];
    const childNavigate = window.location.pathname.split('/')[2];
    const [title,setTitle] = useState("")
    const [infoData,setInfoData] = useState("")
    const [show,setShow] = useState(false)
    const [userData,setUserData] = useState()
    const [mdoalType,setModalType] = useState("")

    useEffect(() => {
        var assignmentId = location.pathname.substring(location.pathname.lastIndexOf('/') + 1)

        async function getChildData() {
            var response = await callApi(`detail-assignment-progress/${assignmentId}`)
            setAssignmentData(response.data);
            setIsLoader(true)
        }
        async function getStudentData() {
            const params = new Proxy(new URLSearchParams(window.location.search), {
                get: (searchParams, prop) => searchParams.get(prop),
            });

            console.log(params.classId,"classId")
            var token = localStorage.getItem("token");
            const headers = {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            };
            await axios.get(environment.REACT_APP_API_URL+`detail-assignment-progress/${assignmentId}`,{
                headers : headers,
                params: {
                    class_id: params.classId
                }
                }).then((res)=>{
                    setAssignmentData(res.data.data);
                    setIsLoader(true)
                }).catch((err)=>{
                    console.log(err,"Error")
                })
        }
            
        if(stateData.type == 5)
            getStudentData()
        else 
            getChildData()
    }, []);
        
    const handleModal = (data=null,id=null,path=null,type=null) => {
        if(type == "assign"){
            setInfoData(data)
            setUserData(id)
            setModalType("certificate")
            setTitle("Certificate")
        } else {
            setInfoData(path)
            setModalType("view")
            setTitle("Certificate")
        }
        setShow(true)
      }
    const handleClose = () => setShow(false)
    var columns = [
        {
            dataField: "name",
            text: "Name",
            sort: true
        },
        {
            dataField: "dateAssigned",
            text: "Date",
            formatter : (cell,row) => {
                return dateConverter(cell)
            }
        },
        {
            dataField: "score",
            text: "Score"
        },
        {
            dataField: "info",
            text: "More Info",
            formatter: (cell, row, rowIndex) => {
                return (
                    <div className={`assign-detail ${row.isCompleted!=1 && "disable-link"}`} onClick={()=>navigatePage(`/progress/recent-activity-progress/lesson-report/${row.assignmentId}`)}>More Info</div>
                );
            }
        },
        {
            dataField: "view",
            text: "Certificate",
            hidden: stateData.type == 5 ? false : true,
            formatter : (cell,row) => {
              return (
                <>
              
                    <div className={`assign-detail ${row.isCompleted!=1 && "disable-link"}`}>
                        {
                            row.isCompleted==1 ? 
                                <>
                                {
                                    row.certificate == null ? 
                                        <div onClick={()=> handleModal(row.assignmentId,row.assignTo,null,"assign")}>Assign Certificate</div>
                                    :
                                        <div onClick={()=> handleModal(null,null,row.certificate.path,"view")}>View</div>
                                }
                                </>
                            : 
                                <div>-</div>
                        }
                    </div>
                </>
              );
            }
          }
    ];


    return (
        <>
            {
                isLoder ? (
                    <>
                        <BannerComponent title={stateData.type == 3?"Family Assignments":"Class Assignments"} navigate={navigate} childNavigate={childNavigate} />
                        <section className='progress-report-wrap'>
                            <div className='container'>  
                                <ul className=''>
                                    <li><span>Lesson  Name: </span> {assignmentData.lessonName}</li>
                                    <li><span>Level: </span> {assignmentData.level}</li>
                                    <li><span>Assigned: </span> {assignmentData.assigned}</li>
                                    {/* <li><span>Question: </span> {assignmentData.assignedQuestion ? assignmentData.assignedQuestion : " - "}</li> */}
                                    <li><span>Date Assigned: </span> {dateConverter(assignmentData.dateAssigned)}</li>
                                    <li><span>Date Due: </span> {assignmentData.dueDate="Until Completed"?"Until Completed":dateConverter(assignmentData.dueDate)}</li>
                                </ul>                                
                                <Table rowData={assignmentData.students} columns={columns} type="Assignments"/>

                            </div>
                        </section>  
                    </>
                ) : (
                    <Spinner/>
                )
            }
            {
              show && 
                <ModalComponent show={show} handleClose={handleClose} type={mdoalType} infoData={infoData} userData={userData} className="certificate" title={title}/>
            }
        </>
    )
}

export default Assignments